// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.messages-toggle {
  display: none;
}

ion-segment-button {
  height: var(--subheader-height);
}

div.prelaod-menu-background-container {
  z-index: -999;
  background-image: var(--cms-menu-bg-image);
}

.icon-header {
  color: var(--cms-header-text-color);
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,+BAAA;AACF;;AACA;EACE,aAAA;EACA,0CAAA;AAEF;;AACA;EACE,mCAAA;AAEF","sourcesContent":[".messages-toggle {\n  display: none;\n}\n\nion-segment-button {\n  height: var(--subheader-height);\n}\ndiv.prelaod-menu-background-container {\n  z-index: -999;\n  background-image: var(--cms-menu-bg-image);\n}\n\n.icon-header{\n  color: var(--cms-header-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
