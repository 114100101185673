import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isMine'
})
export class IsMinePipe implements PipeTransform {

  transform(currentUserId: number, messageUserId): boolean {
    return currentUserId === messageUserId;
  }

}
