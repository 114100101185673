import { Injectable } from '@angular/core';
import { Environment } from 'src/environments/environment';
import { GlobalConstService } from './global-const.service';
import { HttpService } from './http.service';
import { BehaviorSubject, Observable, lastValueFrom, tap } from 'rxjs';
import { PromptCameraService } from './prompt-camera.service';
import { FileService } from './file.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PlatformService } from './platform.service';

@Injectable({ providedIn: 'root' })
export class SurveyService {
  public surveyInitConfig;

  public images$: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    private httpService: HttpService,
    private promptCameraService: PromptCameraService,
    private fileService: FileService,
    private httpClient: HttpClient,
    private platformService: PlatformService
  ) {}

  public getSurvey(idPage: number) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/form/${idPage}/elements`);
  }

  public sendSurvey(idPage: number, formResponses): Observable<any> {
    const formData = new FormData();

    formResponses.forEach((response, index) => {
      if (response.answers) {
        response.answers.forEach((answer) => {
          if (answer && answer.blob instanceof Blob) {
            const fieldName = `${response.id}`;
            formData.append(fieldName, answer.blob, answer.name);
          } else if (answer !== null && typeof answer === 'object' && answer.blob! instanceof Blob) {
            Object.keys(answer).forEach((key) => {
              const value = answer[key];
              const fieldName = `${response.id}.${key}`;

              if (value instanceof File) {
                formData.append(fieldName, value, value.name);
              } else {
                formData.append(fieldName, JSON.stringify(value));
              }
            });
          } else {
            const fieldName = `${response.id}`;
            formData.append(fieldName, answer !== null ? answer : '');
          }
        });
      } else {
        const fieldName = `${response.id}`;
        formData.append(fieldName, ''); // Adjust accordingly based on your requirements
      }
    });

    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/form/${idPage}/submit`, formData);
  }

  public async prepareUserResponse(values, surveyFormDataResponse) {
    const listOfResponses = [];

    for (const question of surveyFormDataResponse) {
      const response = await this.setUserResponse(values, question);
      listOfResponses.push({
        id: question.id,
        answers: response
      });
    }
    return listOfResponses;
  }

  private async setUserResponse(values, question) {
    let response: any = {};
    const keysResponse = Object.keys(values);
    const responseKey = keysResponse.find((res) => res === question.question);

    if (question.inputType === 'image') {
      const images = this.images$.value;
      let image = images.find((image) => image.questionId === question.id);
      if (question.mandatory === false && !image) {
        response = [
          {
            blob: null,
            name: `response_${question.id}`,
            image: null
          }
        ];
        return;
      }

      if (image) {

        try {
          let blob;
          if (this.platformService.isWeb()) {
            blob = await lastValueFrom(this.httpClient.get(image.image.webPath, { responseType: 'blob' }));
          }else{
            const base64: any = await this.fileService.convertFilePathToBase64(image.image.path || image.image.webPath);
            const base64Image = `data:image/${image.image.format};base64,${base64}`;
            blob = this.fileService.base64ToBlob(base64Image, `image/${image.image.format}`)
          }
          response = [
            {
              blob: blob,
              name: `response_${question.id}`,
              image: image.image
            }
          ];
        } catch (error) {
          console.error('Error fetching image blob: ', error);
        }
      } else {
        console.error('Image not found for question ID: ', question.id);
      }
      return response;
    } else {
      response = [values[responseKey]];
      return response;
    }
  }

  public async uploadCameraPictureWeb(event, form, question, fileImage) {
    if (event.target.files && event.target.files.length) {
      let [file] = event.target.files;
      file = await this.fileService.generateFile(file);

      if (form) {
        form.patchValue({ [question.question]: file });
      }

      this.uploadPicture(file, question.id); // Associate the image with the question ID
    }
    fileImage.nativeElement.value = '';
  }

  public async selectPicture(fileInput, question) {
    const result = await this.promptCameraService.ask();

    switch (result.action) {
      case this.promptCameraService.OPEN_INPUT_FILE:
        fileInput.nativeElement.click();
        break;
      case this.promptCameraService.REMOVE_IMAGE:
        break;
      default:
        if (result.image) {
          this.uploadPicture(result.image, question.id);
        }
        break;
    }
  }

  public async uploadPicture(image, questionId) {
    const currentImages = this.images$.value;
    const newImage = { questionId, image };
    const updatedImages = [...currentImages, newImage]; // Create a new array with the new item
    this.images$.next(updatedImages);
  }
}
