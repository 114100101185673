import { Injectable } from '@angular/core';
import { Environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class GlobalConstService {
  /**
   * regular expressions
   */
  public static PATTERN_EMAIL =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))[\s]?$/;
  public static PATTERN_SPECIAL_CHARACTERS = /[^\w\*]/gm;
  public static PATTERN_WHITE_CHARACTERS = /\s/gm;
  public static PATTERN_URL = /^((http|https):\/\/)/;
  /**
   * globalVars
   */
  public userRooms;
  public userDataFirebase = {
    email: String,
    id: Number,
    imgUrl: String,
    name: String,
    online: Boolean,
    roomsList: [],
    surname: String
  };
  public userData: any;
  public isSessionEnabled: boolean;
  public appPagesConfig: any;
  public static FORBIDDEN_STATUS = 401;
  public static SECTIONS_LIST = ['section-notifications', 'section-contacts', 'section-messages', 'section-documents', 'section-home'];
  public static TYPE_OF_VIEWS = {
    navigation: {
      0: 'page_view',
      1: 'section_view',
      2: 'component_view',
      3: 'post_view',
      4: 'contact_vie',
      5: 'event_view'
    },
    actions: {
      0: 'survey_respond',
      1: 'link_click',
      2: 'notification_click',
      3: 'document_click',
      4: 'media_click',
      5: 'event_click',
      6: 'contact_click',
      7: 'chat_link_click',
      8: 'blog_click',
      9: 'like',
      10: 'comment'
    }
  };

  public FEATURES = {
    REGISTER: false
  };

  public removeUserData() {
    this.userData = null;
    this.userDataFirebase = null;
    this.userRooms = null;
    this.appPagesConfig = null;
  }

  constructor() {}
}
