import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, concatMap, shareReplay, tap } from 'rxjs/operators';
import { Filters } from 'src/app/models/filters.model';
import { Link } from 'src/app/models/link.model';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { EventsService } from 'src/app/services/events.service';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { LinksService } from 'src/app/services/links.service';
import { SearcherService } from 'src/app/services/searcher.service';
import { Environment } from 'src/environments/environment';


@Component({
  selector: 'page-full-screen-links',
  templateUrl: 'full-screen-links.html'
})
export class FullScreenLinksPage implements OnInit {
  public itemType: string = 'link';
  public filters$: BehaviorSubject<Filters> = new BehaviorSubject({
    searchKey: '',
    start: 0,
    length: 20
  });
  public eventStatus$: BehaviorSubject<any> = new BehaviorSubject({});

  private link: Link;

  public pageId;
  public page$: Observable<any>;
  public forbidden: boolean = false;
  public viewMode: any;

  constructor(
    private route: ActivatedRoute,
    private searcherService: SearcherService,
    private linkProvider: LinksService,
    private analyticsService: AnalyticsService,
    private events: EventsService
  ) { }

  ngOnInit() {
    this.pageId = +this.route.snapshot.paramMap.get('id');
    this.page$ = this.filters$.pipe(
      concatMap((filters) => this.searcherService.allItems(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/links/${this.pageId}/elements`, filters, this.itemType)),
      tap((response) => this.events.pages$$.next(response.title)),
      tap((response) => this.viewMode = response.view.mode),
      tap(() => this.forbidden = false),
      catchError((err: any) => {
        if (err.status === 403) {
          this.forbidden = true;
        }

        throw err;
      }),
      shareReplay()
    );
  }

  public openLink($event) {
    this.link = $event.item;
    const handlerLinkType = this.linkProvider.linkType[this.link.targetType];
    this.registerEventInAnalytics();
    handlerLinkType(this.link);
  }

  registerEventInAnalytics() {
    const paramsToAnalytics = { actions: 1 };
    this.analyticsService.registerEvent(paramsToAnalytics, 'Pagina de enlaces', this.pageId);
  }

  public getAllItems(event) {
    console.log('event:', event.event);
    
    const filters = event.filters;
    const $event = event.event;
    this.filters$.next(filters);
  }
}
