import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { GlobalConstService } from './global-const.service';
import { Environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  constructor(private httpService: HttpService) {}

  public putProfileEditedData(profileFormData) {
    return this.httpService.myPut(`${Environment.API_BASE_URL_V2}/api/empatica/v1/user`, profileFormData);
  }

  public updatePassword(data){
    return this.httpService.myPut(`${Environment.API_BASE_URL_V2}/api/empatica/v1/user/password`, data);
  }

  public resetPassword(data){
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/public/reset-password`, data);
  }

  public updateProfilePicture(image) {
    const fd = new FormData();
    fd.append('image', image);

    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/user/image`, fd);
  }
}


//327548