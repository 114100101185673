import { Component, OnInit } from '@angular/core';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { Filters } from 'src/app/models/filters.model';
import { Router, ActivatedRoute } from '@angular/router';
import { concatMap, tap, shareReplay } from 'rxjs/operators';
import { SearcherService } from 'src/app/services/searcher.service';
import { BehaviorSubject } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { NavParams, ModalController } from '@ionic/angular';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'page-likes',
  templateUrl: 'likes.html',
  styleUrls: ['likes.scss']
})
export class LikesPage implements OnInit {
  public pageId: number;
  public id: number;

  public page$;

  public itemType: string = 'likes';
  private type;

  public filters$: BehaviorSubject<Filters> = new BehaviorSubject({
    searchKey: '',
    start: 0,
    orderBy: 'publishDate',
    orderDir: 'asc',
    length: 20
  });

  private $event;

  constructor(
    public router: Router,
    private navParams: NavParams,
    private searcherService: SearcherService,
    private analyticsService: AnalyticsService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.pageId = +this.navParams.get('idPage');
    this.id = +this.navParams.get('idArticle');
    this.type = this.navParams.get('type');

    let endpoint = '';

    if (this.type.toUpperCase() == 'BLOG') endpoint = `${Environment.API_BASE_URL_V2}/api/empatica/v1/components/blog/blog-post/${this.id}/likes`;
    else if (this.type.toUpperCase() == 'GALLERY')
      endpoint = `${Environment.API_BASE_URL_V2}/api/empatica/v1/components/gallery/media-item/${this.id}/likes`;
    else if (this.type.toUpperCase() == 'PODCAST')
      endpoint = `${Environment.API_BASE_URL_V2}/api/empatica/v1/components/podcast/episode/${this.id}/likes`;
    else if (this.type.toUpperCase() == 'WALL')
      endpoint = `${Environment.API_BASE_URL_V2}/api/empatica/v1/components/wall/wall-post/${this.id}/likes`;

    this.page$ = this.filters$.pipe(
      concatMap((filters) => this.searcherService.allItems(endpoint, filters, this.itemType)),
      tap(() => this.$event && this.$event.target.complete && this.$event.target.complete()),
      shareReplay()
    );
  }

  public getAllItems({ filters, event = null }) {
    if (event) this.$event = event;
    this.filters$.next(filters);
  }

  public goToProfile($event) {
    // Esto no lo quiere aun porque traería problemas con los usuarios privados
    return;
    const user = $event.item.user;
    this.router.navigate([`contacts/detail/${user.id}/agenda`]);
  }

  public closeModal() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }
}
