import { Component } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'page-not-network-connection',
  templateUrl: 'not-network-connection.html'
})
export class NotNetworkConnectionPage {
  constructor(private loading: LoadingService, public events: EventsService) {}

  async ionViewDidLeave() {
    await this.loading.hide();
  }
}
