import { Injectable } from '@angular/core';
import { Environment } from 'src/environments/environment';
import { Filters } from '../models/filters.model';
import { GlobalConstService } from './global-const.service';
import { HttpService } from './http.service';

@Injectable({ providedIn: 'root' })
export class ContactsService {
  public initConfigContacts;

  constructor(private httpService: HttpService) {}
  public async getInitConfigContactsComponent(): Promise<any> {
    return this.initConfigContacts;
  }

  // seems unused
  public getFullContacts(id) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/contacts`);
  }

  public getContacts(id, filters: Filters) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/contacts/${id}/elements`, filters);
  }

  public getDirectory() {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/users`);
  }

  public getUser(id) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/users/${id}`);
  }

  public getContact(id) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/contacts/contact/${id}`);
  }
}
