import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ToastService } from 'src/app/services/toast.service';
import { Clipboard } from '@capacitor/clipboard';
@Component({
  selector: 'user-field',
  templateUrl: './users-fields.component.html',
  styleUrls: ['./users-fields.component.scss']
})
export class UsersFieldsComponent implements OnInit {
  @Input() user;
  @Input() config;
  @Output() openLink: EventEmitter<any> = new EventEmitter<any>();

  constructor(private toast: ToastService) { }

  ngOnInit() { }

  openWebLink(web) {
    this.openLink.emit({ web });
  }

  copy(value: string) {
    Clipboard.write({ string: value })
      .then(() => this.toast.info('COPIED-TO-CLIPBOARD'))
  }
}
