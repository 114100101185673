import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { Platform, AlertController } from '@ionic/angular';
import * as moment from 'moment';
import { Observable, BehaviorSubject } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { LinksService } from 'src/app/services/links.service';
import { LoginService } from '../../../services/login.service';
import { AlertButton, AlertOptions } from '@ionic/core';
import { LanguageService } from '../../../services/language.service';
import { catchError, shareReplay, tap } from 'rxjs/operators';


@Component({
  selector: 'page-detail-calendar',
  templateUrl: 'detail-calendar.html',
  styleUrls: ['detail-calendar.scss']
})
export class DetailCalendarPage implements OnInit {
  private idPage: number;
  private id: number;

  public calendar$: Observable<any>;
  public canEdit = new BehaviorSubject(false);

  public forbidden: boolean = false;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private calendarService: CalendarService,
    private linksService: LinksService,
    private calendar: Calendar,
    private platform: Platform,
    private loginService: LoginService,
    private languageService: LanguageService,
    public alertCtrl: AlertController
  ) { }

  ngOnInit() {
    this.id = +this.route.snapshot.paramMap.get('id');
    this.idPage = +this.route.snapshot.paramMap.get('idPage');

    this.calendar$ = this.calendarService.getEvent(this.id).pipe(
      tap(() => this.forbidden = false),
      shareReplay(),
      catchError((err: any) => {
        if (err.status === 403) {
          this.forbidden = true;
        }

        throw err;
      })
    );
    this.calendar$.subscribe(async calendar => {
      const account = (await this.loginService.rescueSessionDataUser()).account;
      this.canEdit.next((calendar.permissions.edit && calendar.authorId == account.id) || calendar.permissions.super);
    })
    this.registerAnalyticsInFirebase();
  }

  public async createAlertInCalendar(calendar) {
    const options = this.getCalendarOptions(calendar);
    if (this.platform.is('desktop')) {
      this.createAlertInCalendarWeb(calendar);
      return;
    }

    this.createAlertInCalendarMobile(calendar);
  }

  private createAlertInCalendarWeb(calendar) {
    const icsMSG = this.createFile(calendar);
    window.location.assign('data:text/calendar;charset=utf8,' + escape(icsMSG));
  }

  createFile(calendar) {
    const eventDate = {
      start: !calendar.allDay
        ? moment.utc(calendar.startDatetime).local().toDate()
        : moment.utc(calendar.startDatetime).local().startOf('day').toDate(),
      end: !calendar.allDay
        ? moment.utc(calendar.endDatetime).local().toDate()
        : moment.utc(calendar.endDatetime).local().add(1, 'days').startOf('day').toDate()
    },
      summary = calendar.title,
      description = calendar.notes;
    return this.makeIcsFile(eventDate, summary, description);
  }

  makeIcsFile(date, summary, description = '') {
    return (
      'BEGIN:VCALENDAR\n' +
      'CALSCALE:GREGORIAN\n' +
      'METHOD:PUBLISH\n' +
      'PRODID:-//Test Cal//EN\n' +
      'VERSION:2.0\n' +
      'BEGIN:VEVENT\n' +
      'UID:test-1\n' +
      'DTSTART;VALUE=DATE:' +
      moment(date.start).format('YYYYMMDDTHHmmss') +
      '\n' +
      'DTEND;VALUE=DATE:' +
      moment(date.end).format('YYYYMMDDTHHmmss') +
      '\n' +
      'SUMMARY:' +
      summary +
      '\n' +
      'DESCRIPTION:' +
      description +
      '\n' +
      'END:VEVENT\n' +
      'END:VCALENDAR'
    );
  }

  async createAlertInCalendarMobile(calendar) {
    if (!this.checkPermissionsToWriteEventInCalendar()) {
      console.warn('Sin permisos para el calendar');
      return;
    }
    let calendarOptions = this.getCalendarOptions(calendar);

    this.registerAnalyticsActionInFirebase(calendarOptions);
    
    await this.calendar.createEventInteractivelyWithOptions(
      calendarOptions.title,
      calendarOptions.location,
      calendarOptions.notes,
      calendarOptions.startDate,
      calendarOptions.endDate
    );
    this.openCalendar(calendar);
  }

  private openCalendar(calendar) {
    this.calendar.openCalendar(this.getCalendarOptions(calendar).startDate);
  }

  private checkPermissionsToWriteEventInCalendar() {
    return this.calendar.hasWritePermission();
  }

  private getCalendarOptions(calendar) {
    const startDateMoment = moment.utc(calendar.startDatetime).local();
    const endDateMoment = moment.utc(calendar.endDatetime).local();
    return {
      title: calendar.title,
      location: calendar.location,
      notes: calendar.notes,
      startDate: !calendar.allDay ? startDateMoment.toDate() : startDateMoment.startOf('day').toDate(),
      endDate: !calendar.allDay ? endDateMoment.toDate() : endDateMoment.add(1, 'days').startOf('day').toDate()
    };
  }
  private registerAnalyticsInFirebase() {
    const paramsToAnalytics = { navigation: 2 };
    this.analyticsService.registerEvent(paramsToAnalytics, 'Detalle calendario', this.idPage);
  }

  private registerAnalyticsActionInFirebase(calendarOptions) {
    const paramsToAnalytics = { actions: 5 };
    this.analyticsService.registerEvent(paramsToAnalytics, calendarOptions.title, this.idPage);
  }

  public openLink(link) {
    if (!link.targetType || !link.linkTextToDisplay || !link.target) return;

    const handlerLinkType = this.linksService.linkType[link.targetType];
    this.registerEventInAnalytics(link);
    handlerLinkType(link);
  }

  registerEventInAnalytics(link) {
    const paramsToAnalytics = { actions: 1 };
    const name = link.linkTextToDisplay;
    const id = parseInt(link.id);
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
  }

  public async confirmDeleteItem() {
    const alertButtons: AlertButton[] = [
      {
        text: await this.languageService.translate('CANCEL'),
        role: 'cancel'
      },
      {
        text: await this.languageService.translate('DELETE'),
        handler: () => this.deleteItem()
      }
    ];

    const alertOptions: AlertOptions = {
      header: await this.languageService.translate('ARE-YOU-SURE-YOU-WANT-TO-DELETE-THIS-ITEM'),
      buttons: alertButtons
    };
    let alert = await this.alertCtrl.create(alertOptions);
    await alert.present();
  }

  public deleteItem() {
    this.calendarService.deleteItem(this.idPage, this.id).subscribe(() => {
      this.router.navigate([`/calendar/${this.idPage}/list`]);
    });
  }

  public goToEditItem() {
    this.router.navigate([`/calendar/${this.idPage}/edit/${this.id}`]);
  }
}
