import { Component, OnInit } from '@angular/core';

// Pages
import { GlobalConstService } from 'src/app/services/global-const.service';
import { Filters } from 'src/app/models/filters.model';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap, mergeMap, switchMap, tap, shareReplay, catchError, debounceTime } from 'rxjs/operators';
import { SearcherService } from 'src/app/services/searcher.service';
import { Environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'page-full-screen-directory',
  templateUrl: 'full-screen-directory.html'
})
export class FullScreenDirectoryPage {
  public endpoint$ = new BehaviorSubject(`${Environment.API_BASE_URL_V2}/api/empatica/v1/users`);
  public itemType: string = 'contacts';
  public filters$: BehaviorSubject<Filters> = new BehaviorSubject({
    searchKey: '',
    start: 0,
    length: 20
  });
  public buttonSelected: string = 'agenda';
  private $event;

  private pageId: number;
  public page$: Observable<any>;
  public forbidden: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private searcherService: SearcherService,
    private loading: LoadingService
  ) {}

  ionViewDidEnter() {
    this.pageId = +this.route.snapshot.paramMap.get('id');
    this.getItems();
  }

  public goToDetail($event) {
    const agenda = Boolean($event.item.username) ? '/agenda' : '';
    this.registerEventInAnalytics($event.item);
    this.router.navigate([`contacts/detail/${$event.item.id}${agenda}`]);
  }

  registerPageInAnalytics() {
    const paramsToAnalytics = { navigation: 1 };
    const name = 'Directory section';
    this.analyticsService.registerEvent(paramsToAnalytics, name);
  }

  registerEventInAnalytics(contact) {
    const paramsToAnalytics = { actions: 6 };
    const name = contact.name ? contact.name : 'Nombre de contacto';
    const id = contact.id;
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
  }

  public changeViewStatus(selected) {
    const segment = selected.detail.value;

    if (this.buttonSelected === segment) return;

    const endpoint =
      segment === 'agenda'
        ? `${Environment.API_BASE_URL_V2}/api/empatica/v1/users`
        : `${Environment.API_BASE_URL_V2}/api/empatica/v1/components/contacts`;

    this.endpoint$.next(endpoint);

    this.filters$.next({ ...this.filters$.value, start: 0, searchKey: '' });
    this.buttonSelected = segment;
  }

  getItems() {
    this.page$ = this.filters$.pipe(
      tap(() => this.loading.show()),
      concatMap((filters) => this.searcherService.allItems(this.endpoint$.value, filters, this.itemType)),
      tap(() => this.$event && this.$event.target.complete && this.$event.target.complete()),
      tap(() => (this.forbidden = false)),
      catchError((err: any) => {
        if (err.status === 403) {
          this.forbidden = true;
        }

        throw err;
      }),
      tap(async () => await this.loading.hide()),
      shareReplay()
    );

    this.registerPageInAnalytics();
  }

  public getAllItems({ filters, event = null }) {
    if (event) this.$event = event;
    this.filters$.next(filters);
  }
}
