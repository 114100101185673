import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements OnInit {

  @Input() alert: any;
  constructor(
    private modalCtrl: ModalController,
    private pushNotifications: NotificationService,
    private router: Router
  ) { }

  ngOnInit() { }

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  public action() {
    this.modalCtrl.dismiss();
    if (this.alert.target) {
      this.pushNotifications.openNotification(this.alert);
    } else {
      this.router.navigate(['/notifications']);
    }
  }

  public actionBlockApp() {
    if (this.alert.target) {
      this.pushNotifications.openNotification(this.alert);
    }
  }
}
