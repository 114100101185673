import { Component, OnInit, OnDestroy } from '@angular/core';

// Pages
import { AnalyticsService } from 'src/app/services/analytics.service';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'page-full-screen-chat-list',
  templateUrl: 'full-screen-chat-list.html',
  styleUrls: ['full-screen-chat-list.scss']
})
export class FullScreenChatListPage {
  constructor(private analyticsService: AnalyticsService, private messageProvider: MessagesService) {}

  ionViewDidEnter() {
    this.messageProvider.getListOfRooms();
    this.analyticsService.registerChatPage();
  }
}
