import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertController, IonInput, ModalController } from '@ionic/angular';
import { LanguageService } from 'src/app/services/language.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ProfileService } from 'src/app/services/profile.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'change-password-modal',
  templateUrl: 'change-password-modal.html',
  styleUrls: ['change-password-modal.scss']
})
export class ChangePasswordModalComponent {
  public pwdForm;
  public errorPwdNotEquals: boolean;
  public errorOldPwd: boolean;

  public showInputs = {
    showingCurrentPassword: false,
    showingPassword: false,
    showingConfirmPassword: false
  };

  constructor(
    public modalCtrl: ModalController,
    public loading: LoadingService,
    private alertCtrl: AlertController,
    private fb: UntypedFormBuilder,
    private languageService: LanguageService,
    private profileProvider: ProfileService,
    private toast: ToastService
  ) {
    this.pwdForm = fb.group(
      {
        currentPassword: ['', Validators.required],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required]
      },
      {
        validator: this.validatorNewPassword
      }
    );
    this.errorPwdNotEquals = false;
    this.errorOldPwd = false;
  }

  public closeAlertCancel() {
    this.modalCtrl.dismiss('canceled');
  }

  public submitForm() {
    if (this.pwdForm.invalid) {
      return;
    }
    this.presentAlert();
  }

  private validatorNewPassword(pwdForm: UntypedFormGroup) {
    const password = pwdForm.get('password');
    const confirmPassword = pwdForm.get('confirmPassword');
    return password.value !== confirmPassword.value ? { confirmPassword: true } : {};
  }

  private async presentAlert() {
    let alert = await this.alertCtrl.create({
      header: await this.languageService.translate('CONFIRM-PASSWORD-CHANGE'),
      message: await this.languageService.translate('ARE-YOU-SURE-TO-CHANGE-THE-PASSWORD'),
      buttons: [
        {
          text: await this.languageService.translate('CANCEL'),
          role: 'cancel',
          handler: () => this.cancelChangePassword()
        },
        {
          text: await this.languageService.translate('CONFIRM'),
          handler: () => this.sendForm()
        }
      ]
    });
    await alert.present();
  }

  private sendForm() {
    const formData = {
      currentPassword: this.pwdForm.get('currentPassword').value,
      password: this.pwdForm.get('password').value
    };
    this.profileProvider.updatePassword(formData).subscribe({
      next: async (res) => {
        this.modalCtrl.dismiss();
        this.toast.info('YOUR-PASSWORD-HAS-BEEN-SUCCESSFULLY-CHANGED');
      },
      error: async (err) => {
        this.modalCtrl.dismiss();
        let text: string = err.error.code;
        this.toast.danger(await this.languageService.translate(text.toUpperCase()));
      }
    });
  }

  private async cancelChangePassword() {
    await this.loading.hide();
  }

  public togglePassword(input: IonInput) {
    const inputToToggle: string = 'showing' + input;
    this.showInputs[inputToToggle] = !this.showInputs[inputToToggle];
    input.type = input.type === 'password' ? 'text' : 'password';
  }
}
