import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { GlobalConstService } from './global-const.service';
import { filter, map, take } from 'rxjs/operators';
import { PlatformService } from './platform.service';
import { SimulationService } from './simulation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  typeOfViews = GlobalConstService.TYPE_OF_VIEWS;

  private utm = { utm_source: null };
  private utmSubscription: Subscription;

  constructor(
    private http: HttpService,
    private platformService: PlatformService,
    private simulationService: SimulationService,
    private route: ActivatedRoute
  ) {
    this.utmSubscription = this.route.queryParams.pipe(filter(({ utm_source }) => typeof utm_source !== 'undefined')).subscribe((utm) => {
      for (const property in utm) {
        if (this.utm[property] === null && this.utm[property] !== utm[property]) this.utm[property] = utm[property];
      }
    });
  }

  utmIsDefined() {
    return this.utm.utm_source !== null;
  }

  getUtm() {
    return this.utm;
  }

  public async registerEvent(viewType: object, name: string, identifer?: number) {
    const typeView = this.typeOfViews[Object.keys(viewType)[0]][viewType[Object.keys(viewType)[0]]];
    this.logEvent({ event: typeView, data: { name: name, id: identifer } });
  }

  public async registerPageName(name: string) {
    this.logEvent({ event: 'screen', data: { name } });
  }

  public async registerPage(type: string, id, title) {
    this.logEvent({ event: 'PAGE_VIEW', data: { id: `${type.toUpperCase()}-${id}`, name: title } });
  }

  private logEvent(event) {
    if (this.simulationService.onSimulationMode()) {
      return;
    }

    this.http
      .myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/analyzable-event`, {
        ...event,
        platform: this.platformService.get(),
        device: this.platformService.getDevice()
      })
      .pipe(take(1))
      .subscribe();
  }

  registerMedia(id, title) {
    this.registerPage('MEDIA', id, `Multimedia: ${title}`);
  }

  registerNotificationPage() {
    this.logEvent({ event: 'PAGE_VIEW', data: { id: -1, name: 'Sección: Notificaciones' } });
  }

  registerMyProfilePage() {
    this.logEvent({ event: 'PAGE_VIEW', data: { id: -5, name: 'Sección: Mi perfil' } });
  }

  registerChatPage() {
    this.logEvent({ event: 'PAGE_VIEW', data: { id: -2, name: 'Sección: Lista de chats' } });
  }
}
