import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { take } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';
import { Filters } from '../models/filters.model';
import { InitConfig } from '../models/initConfig.model';
import { GlobalConstService } from './global-const.service';
import { HttpService } from './http.service';
import { PlatformService } from './platform.service';
import { LoadingService } from './loading.service';

@Injectable({ providedIn: 'root' })
export class LinksService {
  public initialConfigLink: InitConfig;

  public linkType = {
    url: (link) => this.openInternalLink(link.target),
    external_url: (link) => this.openExternalLink(link.target),
    embedded_url: (link) => this.openEmbeddedLink(link.target, link.textToDisplay),
    page: (link) => this.openPage(link),
    section: (link) => this.goToSection(link),
    global: () => this.goToNotifications()
  };

  private sectionType = {
    'section-notifications': (link) => this.router.navigate(['/notifications'], { state: { link } }),
    'section-contacts': (link) => this.router.navigate(['/directory'], { state: { link } }),
    'section-documents': (link) => this.router.navigate(['/documents'], { state: { link } }),
    'section-messages': () => this.router.navigate(['/chat/list']),
    'section-home': () => this.router.navigate(['/home']),
    'section-login': () => this.router.navigate(['/login/first']),
    '': () => this.router.navigate(['/home'])
  };

  private iabAndroidOptions(): InAppBrowserOptions | any {
    return {
      location: 'yes',
      closebuttoncaption: 'X',
      closebuttoncolor: '#ffffff',
      hideurlbar: 'yes',
      navigationbuttoncolor: '#ffffff',
      toolbarcolor: '#000000',
      fullscreen: 'no',
      toolbartranslucent: 'yes'
    };
  }

  private iabIosOptions(): InAppBrowserOptions | any {
    return {
      location: 'no',
      closebuttoncaption: 'X',
      hideurlbar: 'yes',
      closebuttoncolor: '#ffffff',
      navigationbuttoncolor: '#ffffff',
      toolbarcolor: '#000000',
      footercolor: 'no',
      fullscreen: 'no',
      toolbartranslucent: 'yes'
    };
  }

  constructor(
    private httpService: HttpService,
    private iab: InAppBrowser,
    private platformService: PlatformService,
    private router: Router,
    private callNumber: CallNumber,
    private loading: LoadingService
  ) {}

  public getLinks(id, filters: Filters) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/links/${id}/elements`, filters);
  }

  public initConversation(userId) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/chat/private/${userId}`, {});
  }

  public async openInternalLink(unformattedLink: string) {
    try {
      let options: InAppBrowserOptions;

      unformattedLink = this.createUrl(unformattedLink);

      const isURL = this.isURL(unformattedLink);

      if (this.platformService.isIos() && isURL) {
        options = this.iabIosOptions();
      } else if (this.platformService.isAndroid() && isURL) {
        options = this.iabAndroidOptions();
      } else {
        this.formatLink(unformattedLink, (url) => window.open(url, '_system'));
        return;
      }

      this.formatLink(unformattedLink, (url) => {
        const browser = this.iab.create(url, '_blank', options);

        browser.on('loaderror').subscribe(event => {
          console.log('err over here');
          
          if (event.message === 'net::ERR_UNKNOWN_URL_SCHEME') {
            browser.close();
            this.iab.create(event.url, '_system');
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }

  isURL(unformattedLink) {
    return (
      !unformattedLink.includes('mailto:') &&
      !unformattedLink.includes('tel:') &&
      !unformattedLink.includes('fax:') &&
      !unformattedLink.includes('sms:') &&
      !unformattedLink.includes('callto:')
    );
  }

  createUrl(unformattedLink) {
    const pattern = /^((http|https):\/\/)/;

    const isURL = this.isURL(unformattedLink);
    if (!pattern.test(unformattedLink) && isURL) {
      unformattedLink = `https://${unformattedLink}`;
    }

    return unformattedLink;
  }

  public async openExternalLink(unformattedLink: string) {
    unformattedLink = this.createUrl(unformattedLink);

    const isURL = this.isURL(unformattedLink);

    if (this.platformService.isMobile() && isURL) {
      this.formatLink(unformattedLink, (url) => this.iab.create(url, '_system'));
      return;
    }

    if (this.platformService.isAndroid() && !isURL) {
      this.formatLink(unformattedLink, (url) => {
        if (url.includes('tel:')) this.callNumber.callNumber(url.replace('tel:', ''), true);
        else window.open(url, '_system');
      });
      return;
    }

    if (this.platformService.isIos() && !isURL) {
      this.formatLink(unformattedLink, (url) => {
        if (url.includes('tel:')) this.callNumber.callNumber(url.replace('tel:', ''), true);
        else if (url.includes('mailto:')) this.iab.create(url, '_system', this.iabIosOptions());
        else window.open(url, '_system', 'hidden=yes,location=yes');
      });
      return;
    }

    this.formatLink(unformattedLink, (url) => window.open(url, '_blank'));
  }

  public openEmbeddedLink(unformattedLink: string, title: string) {
    this.formatLink(unformattedLink, (url) => this.router.navigate(['web-frame/view'], { queryParams: { url, title } }));
  }

  private formatLink(unformattedLink: string, callback: (value: any) => void) {
    this.formatLinkRequest(unformattedLink).subscribe((res) => callback(res));
  }

  formatLinkRequest(unformattedLink) {
    return this.httpService
      .myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/links/link/format`, { url: unformattedLink })
      .pipe(take(1));
  }

  private openPage(link) {
    // Para el caso de que un enlace pueda ir directamente al detalle de un elemento
    const ELEMENT_COMPONENT_TYPE_ROUTE_MAP = {
      blog: 'blog',
      event: 'calendar',
      contact: 'contacts',
      agenda: 'contacts',
      podcast: 'podcast'
    };
    // Para el caso de que un enlace pueda ir directamente al listado de un componente específico
    const COMPONENT_TYPE_ROUTE_MAP = {
      blog: 'blog',
      event: 'calendar',
      document: 'documents',
      contact: 'contacts',
      gallery: 'gallery',
      link: 'links',
      podcast: 'podcast',
      personalDocument: 'personal-documents',
      embedded: 'embeddings'
    };

    if (link.pageId && link.componentId && Object.keys(ELEMENT_COMPONENT_TYPE_ROUTE_MAP).includes(link.componentType) && link.elementId) {
      if (link.componentType === 'agenda') {
        this.router.navigate([
          `/${ELEMENT_COMPONENT_TYPE_ROUTE_MAP[link.componentType]}/${link.componentId}/detail/${link.elementId}/${link.componentType}`
        ]);
        return;
      }

      if (link.componentType === 'podcast') {
        this.router.navigate([`/${ELEMENT_COMPONENT_TYPE_ROUTE_MAP[link.componentType]}/episode`], {
          queryParams: { componentId: link.componentId, episodeId: link.elementId }
        });
        return;
      }

      this.router.navigate([`/${ELEMENT_COMPONENT_TYPE_ROUTE_MAP[link.componentType]}/${link.componentId}/detail/${link.elementId}`]);
      return;
    }

    if (link.pageId && link.componentId && Object.keys(COMPONENT_TYPE_ROUTE_MAP).includes(link.componentType)) {
      this.router.navigate([`/${COMPONENT_TYPE_ROUTE_MAP[link.componentType]}/${link.componentId}/list`]);
      return;
    }

    if (link.pageId) {
      this.router.navigate([`/generic/${link.pageId}`]);
      return;
    }

    if (link.target) {
      this.router.navigate([`/generic/${link.target}`]);
    }
  }

  private goToSection(link) {
    console.log('goToSection: ', link);

    const handlerSectionType = this.sectionType[link.target];
    handlerSectionType(link);
    this.loading.hide();
  }

  private goToNotifications() {
    this.router.navigate(['/notifications']);
  }
}
