// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host ion-item.item {
  padding-top: 8px;
  padding-bottom: 8px;
}
:host ion-item.item.space-between-elements {
  margin-top: -1px;
  border-bottom: 1px solid var(--ion-color-light);
  border-top: 1px solid var(--ion-color-light);
}
:host ion-item.item .icon-empatica-link-floating {
  position: absolute;
  top: 20px;
}
:host ion-item.item i[slot=start] {
  color: var(--cms-link-text-color);
  font-size: 1rem;
  margin-inline-end: 16px;
}
:host ion-item.item ion-label {
  --color: var(--cms-link-text-color);
}
:host ion-item.item.image-item {
  padding: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/links-item/links-item.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAAE;EAME,gBAAA;EACA,mBAAA;AAHJ;AAHI;EACE,gBAAA;EACA,+CAAA;EACA,4CAAA;AAKN;AAAI;EACE,kBAAA;EACA,SAAA;AAEN;AACI;EACE,iCAAA;EACA,eAAA;EACA,uBAAA;AACN;AAEI;EACE,mCAAA;AAAN;AAEI;EACE,YAAA;AAAN","sourcesContent":[":host {\n  display: block;\n  ion-item.item {\n    &.space-between-elements{\n      margin-top: -1px;\n      border-bottom: 1px solid var(--ion-color-light);\n      border-top: 1px solid var(--ion-color-light);\n    }\n    padding-top: 8px;\n    padding-bottom: 8px;\n\n    .icon-empatica-link-floating {\n      position: absolute;\n      top: 20px;\n    }\n\n    i[slot='start'] {\n      color: var(--cms-link-text-color);\n      font-size: 1rem;\n      margin-inline-end: 16px;\n    }\n\n    ion-label {\n      --color: var(--cms-link-text-color);\n    }\n    &.image-item {\n      padding: 0px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
