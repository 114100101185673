// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host .list-view {
  padding-top: 0px;
  padding-bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/personal-documents/personal-documents.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,gBAAA;EACA,mBAAA;AACJ","sourcesContent":[":host {\n  display: block;\n\n  .list-view {\n    padding-top: 0px;\n    padding-bottom: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
