import { Injectable } from '@angular/core';
import { FileOpener, FileOpenerOptions } from '@capacitor-community/file-opener';
import { DownloadFileOptions, DownloadFileResult, Filesystem, Directory } from '@capacitor/filesystem';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';
import { AnalyticsService } from './analytics.service';
import { GlobalConstService } from './global-const.service';
import { HttpService } from './http.service';
import { LanguageService } from './language.service';
import { LinksService } from './links.service';
import { LoadingService } from './loading.service';
import { PlatformService } from './platform.service';
import { ToastService } from './toast.service';
import { Device } from '@capacitor/device';

@Injectable({ providedIn: 'root' })
export class PersonalDocumentsService {
  public initConfigPersonalDocuments;

  constructor(
    private httpService: HttpService,
    private toast: ToastService,
    private file: File,
    private linkService: LinksService,
    private platformService: PlatformService,
    private analyticsService: AnalyticsService,
    private loading: LoadingService,
    private platform: Platform
  ) {}

  public getPersonalDocuments(id, filters) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/personal-documents/${id}/elements`, filters);
  }

  private regularExpressionWhiteCharacters = GlobalConstService.PATTERN_WHITE_CHARACTERS;
  docName: any;

  private getFilesystemAccess(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const status = await Filesystem.checkPermissions();
      const state = status.publicStorage;

      if (state === 'granted') {
        return resolve(true);
      } else if (state === 'denied') {
        // You make want to redirect to the main app settings.
      } else {
        Filesystem.requestPermissions();
      }
      return resolve(false);
    });
  }

  public getDocument(idPage?: number, idDoc?: number) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/personal-documents/document/${idDoc}`);
  }

  public async downloadDoc(doc, open = false) {
    try {
      const deviceInfo = await Device.getInfo();

      if (this.platformService.isWeb()) {
        this.registerEventInAnalytics(doc);
        window.location.assign(doc.uri ? doc.uri : doc.url);
        return;
      }

      if (this.platformService.isAndroid()) {
        console.log('android');
        const permissions = await this.getFilesystemAccess();
        if (!permissions) return;
      }

      this.registerEventInAnalytics(doc);

      let path = '';
      if (this.platformService.isIos()) path = this.file.tempDirectory;
      if (this.platformService.isAndroid()) path = this.file.externalRootDirectory + '/Download/';

      let docNameCleaned = doc.name
        ? doc.name
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/\.[^/.]+$/, '')
            .split(this.regularExpressionWhiteCharacters)
            .join('_')
        : `${moment().toString()}`;

      let extensionRaw = doc.extension || 'jpeg';
      let extension = extensionRaw.toLowerCase();

      const fileName = `${docNameCleaned}.${extension}`;

      const options: DownloadFileOptions = {
        url: doc.uri || doc.url,
        path: fileName,
        directory: Directory.Data
      };

      const downloadedFile: DownloadFileResult = await Filesystem.downloadFile(options);

      if (downloadedFile.path) {
        try {
          const fileMIMEType = this.setMIMETypeToOpenFile(extension);

          const fileOpenerOptions: FileOpenerOptions = {
            filePath: downloadedFile.path,
            openWithDefault: true,
            contentType: fileMIMEType
          };
          await FileOpener.open(fileOpenerOptions);
        } catch (error) {
          if (error.status === 8) {
            this.toast.info('NO-AVAILABLE-APPLICATION-CAN-OPEN-THE-FILE');
          }

          console.log('Error opening file', error);
        }
      }
    } catch (e) {
      this.toast.info('ERROR-DOWNLOADING-THE-FILE');
      console.log(e);
    }
  }

  private registerEventInAnalytics(document) {
    const paramsToAnalytics = { actions: 3 };
    const name = document.name;
    const id = document.id;
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
  }

  private setMIMETypeToOpenFile(extension) {
    if (extension === 'jpeg' || extension === 'jpg' || extension === 'png' || extension === 'gif') {
      return `image/${extension}`;
    }
    if (extension === 'xls' || extension === 'xlm') {
      return 'application/vnd.ms-excel';
    }
    if (extension === 'xlsx') {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    if (extension === 'doc') {
      return 'application/msword';
    }
    if (extension === 'docx') {
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    }
    if (extension === 'odp') {
      return 'application/vnd.oasis.opendocument.presentation';
    }
    if (extension === 'ods') {
      return 'application/vnd.oasis.opendocument.spreadsheet';
    }
    if (extension === 'ppt') {
      return 'application/vnd.ms-powerpoint';
    }
    if (extension === 'pptx') {
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    }
    if (extension === 'mp4') {
      return 'video/mp4';
    }

    return `application/${extension}`;
  }

  public async openExternal(url) {
    this.linkService.openInternalLink(url);
  }

  public async getPersonalDocumentAndDownload(idPage?: number, idDoc?: number, open = false) {
    await this.loading.show();
    this.getDocument(idPage, idDoc)
      .pipe(take(1))
      .subscribe({
        next: async (doc) => {
          await this.platform.ready();
          try {
            await this.downloadDoc(doc, open);
          } catch (error) {
            console.error('Error downloading doc', error);
          }
          await this.loading.hide();
        },
        error: async (err) => {
          console.error(err);
          await this.loading.hide();
        }
      });
  }
}
