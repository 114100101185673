import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, concatMap, shareReplay, tap } from 'rxjs/operators';
import { Filters } from 'src/app/models/filters.model';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PersonalDocumentsService } from 'src/app/services/personal-document.service';
import { SearcherService } from 'src/app/services/searcher.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'page-full-screen-personal-documents',
  templateUrl: 'full-screen-personal-documents.html'
})
export class FullScreenPersonalDocumentsPage implements OnInit {
  public itemType: string = 'personalDocument';
  public pageId: number;
  public page$: Observable<any>;
  public filters$: BehaviorSubject<Filters> = new BehaviorSubject({
    searchKey: '',
    start: 0,
    length: 20
  });
  public forbidden: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public docsProvider: PersonalDocumentsService,
    private loading: LoadingService,
    private platform: Platform,
    private analyticsService: AnalyticsService,
    private searcherService: SearcherService
  ) { }

  ngOnInit() {
    this.pageId = +this.route.snapshot.paramMap.get('id');
    this.page$ = this.filters$.pipe(
      concatMap((filters) => this.searcherService.allItems(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/personal-documents/${this.pageId}/elements`, filters, this.itemType)),
      tap(() => this.forbidden = false),
      catchError((err: any) => {
        if (err.status === 403) {
          this.forbidden = true;
        }

        throw err;
      }),
      shareReplay()
    );
    this.registerPageInAnalytics();
  }
  public getPersonalDoc($even, page) {
    const doc = $even.item;
    this.docsProvider.getPersonalDocumentAndDownload(page.id, doc.id, true);
  }

  registerPageInAnalytics() {
    const paramsToAnalytics = { navigation: 2 };
    const name = 'Lista documentos personales';
    const id = this.pageId;
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
  }

  public getAllItems({ filters }) {
    this.filters$.next(filters);
  }
  public refresh() {
    this.getAllItems({ filters: { ...this.filters$.value, start: 0 } });
  }
}
