import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MEDIA_STATUS } from '@awesome-cordova-plugins/media/ngx';
import { BehaviorSubject, combineLatest, from, interval, Observable, Subscription } from 'rxjs';
import { map, skip, switchMap, take, tap } from 'rxjs/operators';
import { Filters } from 'src/app/models/filters.model';
import { DocumentService } from 'src/app/services/document.service';
import { PlatformService } from 'src/app/services/platform.service';
import { PodcastService } from 'src/app/services/podcast.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-episode-player',
  templateUrl: './episode-player.page.html',
  styleUrls: ['./episode-player.page.scss'],
})
export class EpisodePlayerPage implements OnInit {
  public episodeId: number;
  public episode$: Observable<any>;
  public downloadStatus = new BehaviorSubject('paused');
  public status$$ = new BehaviorSubject('not-playing');
  duration$: Observable<any>;
  position$: Observable<unknown>;
  positionDisplay$: Observable<string>;
  durationDisplay$: Observable<string>;
  episode$$ = new BehaviorSubject(null);
  episodeSubscription: Subscription;
  statusUpdateSubscription: Subscription;
  progress$: Observable<number>;
  public componentId: number;
  dataPodcastSubcription: Subscription;


  constructor(
    private route: ActivatedRoute,
    private podcastService: PodcastService,
    private toast: ToastService,
    private documentService: DocumentService,
    private router: Router,
    private platformService: PlatformService,
  ) {

  }

  ngOnInit() {

  }

  ionViewWillLeave() {
    this.podcastService.stop();
    this.episodeSubscription.unsubscribe();
    this.statusUpdateSubscription.unsubscribe();
  }

  ionViewWillEnter() {

    console.log('episode player ionViewWillEnter');
    
    const filters: Filters = {
      searchKey: '',
      start: 0,
      length: 100
    };
    this.route.queryParams.subscribe(({ componentId, episodeId }) => {
      this.componentId = componentId;
      this.episodeId = episodeId;
      this.episodeSubscription = this.podcastService.getDataPodcast(this.componentId, filters)
        .pipe(switchMap(component => this.podcastService.getEpisode(this.episodeId).pipe(
          map(episode => ({
            ...episode,
            mediaObject: this.podcastService.create(episode),
            next$$: new BehaviorSubject(true),
            previous$$: new BehaviorSubject(true),
            component: component
          })),
          tap(episode => {
            this.statusUpdateSubscription = episode.mediaObject.onStatusUpdate.subscribe(status => {
              let currentStatus = 'not-playing';
              if (status === MEDIA_STATUS.STARTING) {
                currentStatus = 'loading';
              }
              if (status === MEDIA_STATUS.RUNNING) {
                currentStatus = 'playing';
              }

              if (status === MEDIA_STATUS.STOPPED) {
                this.podcastService.stop();
              }

              this.status$$.next(currentStatus);
            });

            this.duration$ = interval(1000).pipe(map(() => episode.mediaObject.getDuration()));
            this.position$ = interval(1000).pipe(switchMap(() => from(episode.mediaObject.getCurrentPosition())));
            this.positionDisplay$ = this.position$.pipe(map((currentPosition: number) => currentPosition < 0 ? '00:00' : this.display(currentPosition)));
            this.durationDisplay$ = this.duration$.pipe(map(duration => duration < 0 ? '--:--' : this.display(duration)));

            episode.previous$$.pipe(skip(1), take(1)).subscribe(episode => this.previous(episode));
            episode.next$$.pipe(skip(1), take(1)).subscribe(episode => this.next(episode));

            this.progress$ = combineLatest([this.position$, this.duration$])
              .pipe(map(([position, duration]: [position: number, duration: number]) => (position * 100) / duration));

          }),
        ))).subscribe(episode => { this.episode$$.next(episode); this.play(episode); });
    });
  }

  private display(seconds) {
    const format = val => `0${Math.floor(val)}`.slice(-2)
    const hours = seconds / 3600
    const minutes = (seconds % 3600) / 60
    return seconds > 3600 ? [hours, minutes, seconds % 60].map(format).join(':') : [minutes, seconds % 60].map(format).join(':');
  }

  public updateLikedItem(liked) {
    let newEpisode = this.episode$$.value;
    newEpisode.social.likes = liked.newItem.social.likes;
    this.episode$$.next(newEpisode);
  }

  public updateCommentedItem(count) {
    let newEpisode = this.episode$$.value;
    newEpisode.social.comments.count = count.count;
    this.episode$$.next(newEpisode)
  }


  async download(episode) {
    this.downloadStatus.next('downloading');
    const open = this.platformService.isIos() ? true : false;
    await this.documentService.downloadDoc(episode, open);
    this.downloadStatus.next('paused');
    if (this.platformService.isAndroid()) this.toast.info('La descarga se ha completado, revisa la carpeta de descargas');
  }

  play(episode) {
    this.podcastService.play(episode);
  }

  pause(episode) {
    this.podcastService.pause(episode);
  }

  stop(episode) {
    this.podcastService.destroyMusicControls();
  }

  rewind(episode) {
    this.podcastService.rewind(episode, 10);
  }

  forward(episode) {
    this.podcastService.forward(episode, 10);
  }

  seekTo($event, episode) {
    const duration = episode.mediaObject.getDuration();
    this.podcastService.seekTo(episode, (duration * $event) / 100);
  }

  next(episode) {
    this.podcastService.stop();
    this.episodeSubscription.unsubscribe();
    this.statusUpdateSubscription.unsubscribe();

    const episodes: number[] = episode.component.items.itemList.map(item => item.id);
    const index = episodes.findIndex(id => id === episode.id);
    let targetEpisodeId = episodes[index + 1];

    if (index === episodes.length - 1) {
      targetEpisodeId = episodes[0];
    }

    this.router.navigate([], { relativeTo: this.route, queryParams: { componentId: this.componentId, episodeId: targetEpisodeId } });
  }

  previous(episode) {
    this.podcastService.stop();
    this.episodeSubscription.unsubscribe();
    this.statusUpdateSubscription.unsubscribe();

    const episodes: number[] = episode.component.items.itemList.map(item => item.id);
    const index = episodes.findIndex(id => id === episode.id);
    let targetEpisodeId = episodes[index - 1];

    if (index === 0) {
      targetEpisodeId = episodes[episodes.length - 1];
    }

    this.router.navigate([], { relativeTo: this.route, queryParams: { componentId: this.componentId, episodeId: targetEpisodeId } });
  }
}
