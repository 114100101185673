// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: fixed;
  bottom: 0;
  background-color: var(--cms-tabs-bg-color);
  width: 100%;
}

.tabs {
  display: flex;
  height: var(--tabs-height);
  padding-bottom: calc(env(safe-area-inset-bottom) / 2);
}

.tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  cursor: pointer;
}
.tab ion-icon {
  color: var(--cms-tabs-icon-color);
  font-size: 24px;
  padding: 2px;
}
.tab p {
  color: var(--cms-tabs-text-color);
  margin: 0;
  padding: 0;
  font-size: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/tabs/tabs.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,SAAA;EAEA,0CAAA;EACA,WAAA;AAAF;;AAGA;EACE,aAAA;EACA,0BAAA;EACA,qDAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,qBAAA;EACA,uBAAA;EACA,eAAA;AAAF;AAEE;EACE,iCAAA;EACA,eAAA;EACA,YAAA;AAAJ;AAGE;EACE,iCAAA;EACA,SAAA;EACA,UAAA;EACA,0BAAA;AADJ","sourcesContent":[":host {\n  position: fixed;\n  bottom: 0;\n\n  background-color: var(--cms-tabs-bg-color);\n  width: 100%;\n}\n\n.tabs {\n  display: flex;\n  height: var(--tabs-height);\n  padding-bottom: calc(env(safe-area-inset-bottom) / 2);\n}\n\n.tab {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-items: center;\n  justify-content: center;\n  cursor: pointer;\n\n  ion-icon {\n    color: var(--cms-tabs-icon-color);\n    font-size: 24px;\n    padding: 2px;\n  }\n\n  p {\n    color: var(--cms-tabs-text-color);\n    margin: 0;\n    padding: 0;\n    font-size: 10px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
