import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { InAppBrowser, InAppBrowserEventType } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { NavController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { ClientService } from 'src/app/services/client.service';
import { EventsService } from 'src/app/services/events.service';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { HandlerErrorService } from 'src/app/services/handler-error.service';
import { InitialConfigService } from 'src/app/services/inital-config.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LoginService } from 'src/app/services/login.service';
import { NetworkService } from 'src/app/services/network.service';
import { ToastService } from 'src/app/services/toast.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'page-login-web',
  templateUrl: 'login-web.html',
  styleUrls: ['login-web.scss']
})
export class LoginWebPage implements OnInit {
  public loginType :string;
  public isErrorInMail: boolean;
  public formEmpty: boolean;
  public errorMessage = null;
  private loginErrors: any;
  private clientId;
  public showingPassword = false;
  public usernameIncorrect: { isUsernameIncorrect: boolean; textError: string } = { isUsernameIncorrect: false, textError: '' };
  public openedApp = false;
  public currentLang: string;
  public oauthLogin = 'OAUTH_LOGIN';
  public oneStepLogin = 'ONE_STEP_LOGIN';
  public threeStepLogin = 'THREE_STEPS_LOGIN';
  public oauthOneStepLogin = 'OAUTH_ONE_STEP_LOGIN';

  public routeSubscription: Subscription;
  public logo: string;
  public bgColor: string;
  public bgImage: string;
  public welcomeMessage: any;
  public clientLoaded: boolean = false;
  public termsMandatory = false;
  public register = Environment.REGISTER;

  public loginFormThreeSteps = new UntypedFormGroup({
    username: new UntypedFormControl({ value: '', disabled: false }, Validators.required)
  });

  public loginOauthAndOneStepLogin = new UntypedFormGroup({
    username: new UntypedFormControl({ value: '', disabled: false }, Validators.required)
  });

  public loginFormOneStep = new FormGroup({
    username: new FormControl({ value: null, disabled: false }, Validators.required),
    password: new FormControl({ value: null, disabled: false }, Validators.required)
  });

  public termsForm: FormGroup = new FormGroup({
    terms: new FormControl(false)
  });

  constructor(
    public globalConst: GlobalConstService,
    private router: Router,
    private initialConfig: InitialConfigService,
    private loginService: LoginService,
    private loading: LoadingService,
    private handError: HandlerErrorService,
    public network: NetworkService,
    private iab: InAppBrowser,
    private events: EventsService,
    private platform: Platform,
    private navCtrl: NavController,
    private languageService: LanguageService,
    private clientService: ClientService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    const availableLogins = Environment.AVAILABLE_LOGINS;
    this.updateTermsFormValidity();
    this.loading.hide();
    this.loginErrors = this.handError.loginErrors;
    this.currentLang = window.navigator.language.split('-')[0] === 'es' ? window.navigator.language.split('-')[0] : 'en';

    if (window.location.href.includes('?sid=')) {
      const token = window.location.href.split('sid=')[1];
      this.openSession(token);
    }

    if (Environment.CLIENT_ID !== null) {
      this.clientService
        .getClient()
        .pipe(take(1))
        .subscribe({
          next: (client) => {
            this.logo = client.style.intro.logo;
            this.bgColor = client.style.intro.bgColor;
            this.welcomeMessage = client.style.intro.welcomeMessage;
            this.clientId = client.id;
            this.clientService.saveAppTheme(client.style);
            this.clientService.setThemeApplication(client.style);
            this.clientLoaded = true;
          },
          error: (error) => {
            console.log('error with client: ', error);
            
            this.router.navigate(['not-found'])
          }
        });

      const styles = this.clientService.rescueAppStyleSheet();
      if (styles) {
        this.setStyles(styles);
      }

      if (availableLogins.includes(this.oneStepLogin)) {
        this.loginType = this.oneStepLogin;
      }

      if (availableLogins.includes(this.oauthLogin)) {
        this.loginType = this.oauthLogin;
        this.currentLang = this.languageService.langs$$.value;
      }

      if (availableLogins.includes(this.oauthLogin) && availableLogins.includes(this.oneStepLogin)) {
        this.loginType = this.oauthOneStepLogin;
        this.currentLang = this.languageService.langs$$.value;
      }

      return;
    }

    else{
      const subdomain = window.location.hostname.split('.')[0];

      if(subdomain === 'localhost'){
        this.loginType = this.threeStepLogin;
        this.clientLoaded = true;
        console.log(this.loginType);
        return
      }

      if (subdomain !== 'issi') {
        this.clientService
          .getClientBySubdomain(subdomain)
          .pipe(take(1))
          .subscribe({
            next: (client) => {
              console.log('client: ', client);
  
              this.loginType = this.oneStepLogin;
  
              this.logo = client.style.intro.logo;
              this.bgColor = client.style.intro.bgColor;
              this.welcomeMessage = client.style.intro.welcomeMessage;
  
              this.clientId = client.id;
              this.setStyles(client.style);
              this.clientService.saveAppTheme(client.style);
              this.clientService.setThemeApplication(client.style);
  
              this.clientLoaded = true;
            },
            error: (error) => this.router.navigate(['not-found'])
          });
        return;
      }
      
      if (availableLogins.includes(this.threeStepLogin)) {
        this.loginType = this.threeStepLogin;
        this.clientLoaded = true;
      }
    }
  }

  public onSubmitThreeSteps() {
    const usernameSubmit = this.loginFormThreeSteps.get('username').value;
    this.formEmpty = usernameSubmit ? false : true;

    if (!this.checkTermsFormValidity()) {
      return;
    }

    if (this.loginFormThreeSteps.valid) {
      this.sendLoginFormThreeSteps(usernameSubmit);
    } else {
      this.checkFormKeysSteps(this.loginFormThreeSteps);
    }
  }


  private async sendLoginFormThreeSteps(username: any) {
    await this.loading.show();
    this.loginService
      .loginStepOne(username)
      .pipe(finalize(() => this.loading.hide()))
      .subscribe({
        next: (response) => this.clientDataHandler(response, username),
        error: (error) => this.errorHandler(error)
      });
  }

  private async clientDataHandler(availableClients, username) {
    if (availableClients.length > 1) {
      this.goLoginStepTwo(availableClients, username);
      return;
    }

    const userData = availableClients[0];
    this.initialConfig.saveUserData({ ...userData, username: username });
    await this.getThemeApp();
    this.goLoginStepThree(username, userData.id);
  }

  public goLoginStepTwo(data, username) {
    this.router.navigate(['login/second/'], { state: { data, username } });
    this.loading.hide();
  }

  public onSubmitOneStep() {
    const { username, password } = this.loginFormOneStep.value;
    this.formEmpty = username && password ? false : true;

    if (!this.checkTermsFormValidity()) {
      return;
    }

    if (this.loginFormOneStep.valid) {
      this.sendLoginFormOneStep(username, password);
    } else {
      this.checkFormKeysSteps(this.loginFormOneStep);
    }
  }


  private async sendLoginFormOneStep(username: any, password: any) {
    console.log('sendLoginFormOneStep: ', username, password);

    await this.loading.show();
    this.loginService
      .loginStepThree({ username, password, clientId: this.clientId })
      .pipe(finalize(async () => await this.loading.hide()))
      .subscribe({
        next: async (userData) => {
          this.initialConfig.saveUserData({ ...userData, username });
          this.events.publish({ name: 'session', value: 'ready' });
          this.router.navigate(['home']);
        },
        error: (err) => {
          if (err.status === 404) this.errorMessage = 'El usuario no existe o está inhabilitado';
          else if (err.status === 500) this.errorMessage = 'Error en la conexión con el servidor';
          else if (err.status === 400) this.errorMessage = 'Error en la petición';
          else this.errorMessage = 'Error';
        }
      });
  }


 // COMBINED OAUTH AND ONE STEP LOGIN

 public onSubmitOauthAndOneSteps() {
  const usernameSubmit = this.loginOauthAndOneStepLogin.get('username').value;
  const clientId = Environment.CLIENT_ID;
  this.formEmpty = usernameSubmit ? false : true;

  if (!this.checkTermsFormValidity()) {
    return;
  }

  if (this.loginOauthAndOneStepLogin.valid) {
    this.sendLoginFormOauthAndOneSteps(usernameSubmit, clientId);
  } else {
    this.checkFormKeysSteps(this.loginOauthAndOneStepLogin);
  }
}

private async sendLoginFormOauthAndOneSteps(username: any, clientId) {
  await this.loading.show();
  this.loginService
    .getUserLoginType(username, clientId)
    .pipe(finalize(() => this.loading.hide()))
    .subscribe({
      next: (response) => this.loginTypeHandler(username, clientId, response),
      error: (error) => this.errorHandler(error)
    });
}

private async loginTypeHandler(username,clientId, userData) {
  if (userData === 'AZURE_AD') {
    this.openAzureOauth();
    return;
  }

  this.goLoginStepThree(username, clientId);
}

// END COMBINED OAUTH AND ONE STEP LOGIN


private checkFormKeysSteps(loginForm) {
  const keys = Object.keys(loginForm['controls']);
  keys.forEach((key) => loginForm['controls'][key].updateValueAndValidity());
}


  public updateTermsFormValidity() {
    this.termsMandatory = Environment.LOGIN_TERMS;
    if (this.termsMandatory) {
      this.termsForm.controls.terms.addValidators(Validators.required);
      this.termsForm.updateValueAndValidity();
    }

    return;
  }

  public checkTermsFormValidity() {
    if (this.termsMandatory) {
      console.log('Terms mandatory');

      if (this.termsForm.controls.terms.value === true) {
        console.log('Terms form valid');
        return true;
      }

      if (this.termsForm.controls.terms.value === false) {
        console.log('Terms form not valid');
        this.toast.info('Debe aceptar los términos y condiciones para poder iniciar sesión');
        return false;
      }
    }
    console.log('Terms not mandatory');

    return true;
  }

  private async errorHandler(error: any) {
    this.usernameIncorrect.isUsernameIncorrect = true;
    this.usernameIncorrect.textError = 'Usuario no encotrado';
    await this.loading.hide();
  }

  public gotToChangePassword() {
    this.router.navigate(['rescue'], {
      state: {
        userData: {
          username: Environment.CLIENT_ID === null ? this.loginFormThreeSteps.value.username : this.loginFormOneStep.value.username,
          password: Environment.CLIENT_ID === null ? this.loginFormThreeSteps.value.password : this.loginFormOneStep.value.password,
          clientId: Environment.CLIENT_ID
        }
      }
    });
  }


  // OAUTH LOGIN

  public openAzureOauth() {
    if (!this.checkTermsFormValidity()) {
      return;
    }

    const url = this.loginService.getOAuthURL(
      Environment.AZURE_OAUTH_TENANT,
      Environment.AZURE_OAUTH_CLIENT_ID,
      JSON.stringify({
        clientId: Environment.CLIENT_ID,
        webRedirection: true,
        redirectUrl: window.location.href
      })
    );
    console.log('Entra a', url);
    const ref = this.iab.create(url, '_self', { location: 'no', hidenavigationbuttons: 'yes' });

    const eventType: InAppBrowserEventType = 'loadstart';
    // attach listener to loadstart

    if (!ref.on(eventType)) {
      return;
    }

    ref.on(eventType).subscribe((event) => {
      const urlSuccessPage = 'redirect/finish';
      if (!event.url.includes(urlSuccessPage)) {
        return;
      }
      ref.close();
      const token = event.url.split('sid=')[1];
      this.openSession(token);
    });
  }

    // END OAUTH LOGIN

  private openSession(token: string) {
    this.loginService.saveUserSession({ account: { sessionToken: token } });

    this.loginService
      .updateSession()
      .pipe(take(1))
      .subscribe({
        next: async (response) => {
          this.initialConfig.saveUserData(response);
          this.loginService.saveUserSession(response);
          this.globalConst.userData = response;

          await this.getThemeApp();

          this.initialConfig.rootPageComponents = response.rootPageComponents;
          this.initialConfig.styleSheet = response.client.style;
          this.events.publish({ name: 'session', value: 'ready' });

          if (!this.platform.is('desktop')) {
            this.router.navigate(['home']);
          }
        },
        error: (err) => {
          this.loading.hide();
        }
      });
  }

  private getThemeApp() {
    let cssStyleSheet = this.initialConfig.styleSheet;
    if (!cssStyleSheet) {
      return;
    }
    this.clientService.saveAppTheme(cssStyleSheet);
    this.clientService.setThemeApplication(cssStyleSheet);
  }

  public goToResetPassword(userData) {
    this.router.navigate(['/login/reset'], { state: { userData } });
    return;
  }

  public cancelLogin() {
    this.navCtrl.back();
  }

  public togglePassword(input) {
    this.showingPassword = !this.showingPassword;
    input.type = input.type === 'password' ? 'text' : 'password';
  }

  setStyles(styles) {
    this.logo = styles.intro.logo;
    this.bgColor = styles.intro.bgColor;
    this.bgImage = styles.intro.bgImage;
    this.welcomeMessage = styles.intro.welcomeMessage;
  }

  public goLoginStepThree(userData?, clientId?) {
    if (userData) {
      console.log(userData, clientId);
      
      this.router.navigate(['login/third/'], { state: { username: userData, id: clientId} });
      this.loading.hide();
      return;
    }
    this.router.navigate(['login/third/']);
    this.loading.hide();
  }
}
