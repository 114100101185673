// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .if-logged {
  display: none;
}
:host .if-not-logged {
  display: inline-block;
}

:host-context(.logged) .if-logged {
  display: inline-block;
}
:host-context(.logged) .if-not-logged {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/home/home.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR;AAGI;EACI,qBAAA;AADR;;AAMI;EACI,qBAAA;AAHR;AAMI;EACI,aAAA;AAJR","sourcesContent":[":host{\n    .if-logged{\n        display: none;\n    }\n\n    .if-not-logged{\n        display: inline-block;\n    }\n}\n\n:host-context(.logged){\n    .if-logged{\n        display: inline-block;\n    }\n\n    .if-not-logged{\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
