import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

export interface EventInterface {
  name: string;
  value: any;
}

@Injectable({ providedIn: 'root' })
export class EventsService {
  constructor() {}

  public app = new BehaviorSubject(null);
  public anonApp = new BehaviorSubject(null);
  public session = new BehaviorSubject(null);
  public isInternetReady = new BehaviorSubject(null);

  public notifications = new BehaviorSubject(null);
  public nav = new BehaviorSubject(null);

  public pages$$ = new BehaviorSubject(null);

  subscribe(event: EventInterface) {
    return this[event.name].pipe(filter((v) => v === event.value)).asObservable();
  }

  publish(event: EventInterface) {
    this[event.name].next(event.value);
  }
}
