import { trigger, transition, stagger, animate, style, query } from '@angular/animations';

export const AnimationList = [
  trigger('listAnimation', [
    transition('* => *', [
      query(':leave', [stagger(100, [animate('0.5s', style({ opacity: 0 }))])], { optional: true }),
      query(
        ':enter',
        [
          style({ transform: 'scale(0.5)', opacity: 0 }),
          stagger(100, [animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ transform: 'scale(1)', opacity: 1 }))])
        ],
        { optional: true }
      )
    ])
  ])
];
