import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { VideoPlayerComponent } from '../components/gallery/video-player/video-player';
import { ImageModalComponent } from '../components/image-modal/image-modal';
import { Filters } from '../models/filters.model';
import { InitConfig } from '../models/initConfig.model';
import { GlobalConstService } from './global-const.service';
// Models
import { HttpService } from './http.service';
import { LoadingService } from './loading.service';
// import { YoutubePlayerWeb } from 'capacitor-youtube-player'; // Web version
import { CapacitorVideoPlayer } from 'capacitor-video-player';
import { Environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class GalleryService {
  public galleryInitialConfig: InitConfig;

  constructor(private httpService: HttpService, public loading: LoadingService, private modalCtrl: ModalController) {}
  public getGallery(id, filters: Filters) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/gallery/${id}/elements`, filters);
  }

  public newMedia({ componentId, name, likesAllowed, commentsAllowed, blob }) {
    const formData = new FormData();
    formData.append('file', blob, blob.type);
    formData.append('name', name);
    formData.append('likesAllowed', likesAllowed);
    formData.append('commentsAllowed', commentsAllowed);

    return this.httpService.multipartPost(
      `${Environment.API_BASE_URL_V2}/api/empatica/v1/components/gallery/${componentId}/media-item/create`,
      formData
    );
  }

  public deleteItem(componentId, itemId) {
    return this.httpService.myDelete(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/gallery/media-item/${itemId}`);
  }

  public checkExtension(image) {
    if (image.extension === 'mp4' || image.sourceType === 'youtube') {
      return false;
    }
    return true;
  }

  public async openYoutubeVideo(video) {
    let idVideo;
    console.log(video);
    
    await this.loading.show();

    if (video.url.includes('youtu.be/')) {
      idVideo = video.url.split('.be/')[1];
    } else {
      idVideo = video.url.substring(video.url.indexOf('=') + 1, video.url.length);
    }

    let orientation = video.orientation;

    if (idVideo.indexOf('&') !== -1) {
      idVideo = idVideo.substring(0, idVideo.indexOf('&'));
    }

    if (video.sourceMediaCode) idVideo = video.sourceMediaCode;
    const videoModal = await this.modalCtrl.create({
      component: VideoPlayerComponent,
      componentProps: { idVideo, type: 'youtube', orientation }
    });

    videoModal.present().then(async () => await this.loading.hide());
  }

  public async openNativeVideo(video) {
    const videoModal = await this.modalCtrl.create({ component: VideoPlayerComponent, componentProps: { idVideo: video.url, type: 'native' } });
    videoModal.present().then(async () => await this.loading.hide());
  }

  public async openVimeoVideo(video) {
    await this.loading.show();

    const idVideoPosition = video.url.indexOf('/video/') + 7;
    const idVideo = video.url.substring(idVideoPosition, video.url.length);

    const videoModal = await this.modalCtrl.create({ component: VideoPlayerComponent, componentProps: { idVideo, type: 'vimeo' } });
    videoModal.present().then(async () => await this.loading.hide());
  }

  private async openStreamingVideo(video) {
    const videoPlayer = CapacitorVideoPlayer;
    await this.loading.hide();
    await videoPlayer.initPlayer({ mode: 'fullscreen', url: video.url });
  }

  public like(idPage, idArticle) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/gallery/media-item/${idArticle}/like`, {});
  }

  public unlike(idPage, idArticle) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/gallery/media-item/${idArticle}/unlike`, {});
  }

  public getComments(idPage, idArticle, filters: Filters): any {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/gallery/media-item/${idArticle}/comments`, <any>filters);
  }

  public sendComment(idPage, idArticle, comment) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/gallery/media-item/${idArticle}/comments`, {
      comment: comment
    });
  }

  async showViewer(url) {
    const profileModal = await this.modalCtrl.create({
      component: ImageModalComponent,
      componentProps: { images: [{ url }] }
    });
    await profileModal.present();
  }

  public removeComment(itemId, commentId) {
    return this.httpService.myDelete(`${Environment.API_BASE_URL_V2}/api/empatica/v1/gallery/media-item/${itemId}/comments/${commentId}`);
  }
}
