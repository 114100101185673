import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AlertButton, AlertOptions } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, take } from 'rxjs';
import { DocumentService } from 'src/app/services/document.service';
import { EventsService } from 'src/app/services/events.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LoginService } from 'src/app/services/login.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'documents-item',
  templateUrl: 'documents-item-component.html',
  styleUrls: ['documents-item-component.scss']
})
export class DocumentsItemComponent implements AfterViewInit {
  @Input() doc;
  @Input() showButtons;
  @Output() reloadEmitter = new EventEmitter<string>();

  public canEdit = new BehaviorSubject(false);

  constructor(
    private loginService: LoginService,
    private languageService: LanguageService,
    public alertCtrl: AlertController,
    private docsProvider: DocumentService,
    private router: Router,
    private loading: LoadingService,
    private toast: ToastService,
    private events: EventsService,
    private translate: TranslateService,
  ) {
    
  }
  ngAfterViewInit(): void {
    this.isAuthorized();
  }

   public isAuthorized() {
    const account = this.loginService.rescueSessionDataUser().account;
    this.canEdit.next((this.doc.permissions.edit && this.doc.authorId == account.id) || this.doc.permissions.super);
    console.log('isAuthorized: ',this.canEdit.value);
    
  }

  public async deleteMessage() {
    const alert = await this.alertCtrl.create({
      cssClass: '',
      header: await this.translate.get('DOCUMENT-DELETE-ITEM').toPromise(),
      message: await this.translate.get('ARE-YOU-SURE-YOU-WANT-TO-DELETE-THIS-ITEM').toPromise(),
      buttons: [
        {
          text: await this.translate.get('CANCEL').toPromise(),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            
          }
        },
        {
          text: await this.translate.get('DELETE').toPromise(),
          cssClass: 'danger',
          handler: () => {
            this.docsProvider
              .deleteItem(this.doc.component_id,this.doc.id)
              .pipe(take(1))
              .subscribe(() => {
                this.reloadEmitter.emit('reload');
              });
          }
        }
      ]
    });

    await alert.present();
  }


  public goToEditItem() {
    this.router.navigate([`/documents/${this.doc.component_id}/edit/${this.doc.id}`]);
  }

  public getDoc() {
    this.docsProvider.getDocumentAndDownload(0, this.doc.id, true);
  }
}
