import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatTime',
  pure: false
})
@Injectable({ providedIn: 'root' })
export class FormatTimePipe implements PipeTransform {
  // Show moment.js dateFormat for time elapsed.
  transform(date: any, outputFormat: string, inputFormat?: string): any {
    outputFormat = outputFormat ? outputFormat : 'llll';
    if (inputFormat){
      return moment.utc(date, inputFormat).local().format(outputFormat);
    }
    return moment.utc(date).local().format(outputFormat);
  }
}
