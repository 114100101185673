import { Component, OnInit } from '@angular/core';
import { DetailCalendarPage } from '../detail-calendar/detail-calendar.page';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { Filters } from 'src/app/models/filters.model';
import { InitConfig } from 'src/app/models/initConfig.model';
import { NavController, NavParams } from '@ionic/angular';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearcherService } from 'src/app/services/searcher.service';
import { concatMap, tap, shareReplay, catchError } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'page-full-screen-calendar',
  templateUrl: 'full-screen-calendar.html'
})
export class FullScreenCalendarPage implements OnInit {
  public itemType: string = 'calendar';
  public filters$: BehaviorSubject<Filters> = new BehaviorSubject({
    searchKey: '',
    start: 0,
    length: 20
  });
  page$: Observable<any>;
  pageId: number;

  private $event;
  public forbidden: boolean = false;

  constructor(
    public router: Router,
    private analyticsService: AnalyticsService,
    private searcherService: SearcherService,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit() {
    this.pageId = +this.route.snapshot.paramMap.get('idPage');
    this.page$ = this.filters$.pipe(
      concatMap((filters) => this.searcherService.allItems(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/events/${this.pageId}/elements`, filters, this.itemType)),
      tap(() => this.$event && this.$event.target.complete && this.$event.target.complete()),
      tap(() => this.forbidden = false),
      catchError((err: any) => {
        if (err.status === 403) {
          this.forbidden = true;
        }

        throw err;
      }),
      shareReplay()
    );

    this.registerAnalyticsInFirebase();
  }

  ionViewWillEnter() {
    this.getAllItems({ filters: { ...this.filters$.value, start: 0 } });
  }

  goToDetail($event) {
    const article = $event.item;
    this.router.navigate([`calendar/${this.pageId}/detail/${article.id}`]);
  }

  private registerAnalyticsInFirebase() {
    const paramsToAnalytics = { navigation: 2 };
    this.analyticsService.registerEvent(paramsToAnalytics, 'Screen calendar', this.pageId);
  }

  public getAllItems({ filters, event = null }) {
    if (event) this.$event = event;
    this.filters$.next(filters);
  }

  public goToAddItem(id) {
    this.router.navigate([`calendar/${id}/add`]);
  }
}
