import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { LoginConfirmRescuePasswordPage } from '../login-confirm-rescue-password/login-confirm-rescue-password.page';

import { NavController, NavParams } from '@ionic/angular';
import { LoginService } from 'src/app/services/login.service';
import { InitialConfigService } from 'src/app/services/inital-config.service';
import { ToastService } from 'src/app/services/toast.service';
import { User } from 'src/app/models/user.model';
import { Router } from '@angular/router';
import { Environment } from 'src/environments/environment';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'page-login-rescue-password',
  templateUrl: 'login-rescue-password.html',
  styleUrls: ['login-rescue-password.scss']
})
export class LoginRescuePasswordPage implements OnInit {
  public sendRescuePassword: UntypedFormGroup;
  public formEmpty: boolean;
  public introStyleConfig: any;
  public logo: any;
  public userData: User;
  get loginPage() {
    return Environment.platform === 'web' ? 'login/web' : 'login/first';
  }

  constructor(
    public router: Router,
    private fb: UntypedFormBuilder,
    public initialConfig: InitialConfigService,
    public loginProvider: LoginService,
    private toast: ToastService,
    private languageService: LanguageService
  ) {
    this.userData = this.router.getCurrentNavigation().extras.state.userData;
  }

  ngOnInit() {
    this.introStyleConfig = this.initialConfig.styleSheet.intro;
    this.logo = this.introStyleConfig.logo;
    this.createForm();
  }

  createForm() {
    let username = '';
    if (this.userData && this.userData.username) {
      username = this.userData.username;
    }
    this.sendRescuePassword = this.fb.group({ username: [username, Validators.required] });
  }

  public onSubmit() {
  
    const userData = {
      username: this.sendRescuePassword.get('username').value,
      id: Environment.CLIENT_ID === null ? this.userData.id : Environment.CLIENT_ID
    }
    
    this.loginProvider.rescuePassword(userData).subscribe({
      next:() => this.confirmPassword(userData.username),
      error: async (error) => {
        const text: string = error.error.code
        this.toast.danger( await this.languageService.translate(text.toUpperCase()));
      }
    }
      
    );
  }

  private checkFormKeys() {
    let keys = Object.keys(this.sendRescuePassword.controls);
    keys.forEach((key) => this.sendRescuePassword.controls[key].updateValueAndValidity());
  }

  public goLoginStepOne() {
    this.router.navigate([this.loginPage]);
  }

  public confirmPassword(usernameSubmit) {
    this.router.navigate([`rescue/confirm`], { state: { username: usernameSubmit, userData: this.userData } });
  }
}
