import { Component } from '@angular/core';

//Pages
import { FullScreenCalendarPage } from '../../pages/calendar-pages/full-screen-calendar/full-screen-calendar.page';
import { InitConfig } from 'src/app/models/initConfig.model';
import { NavController } from '@ionic/angular';
import { CalendarService } from 'src/app/services/calendar.service';
import { CheckComponentsService } from 'src/app/services/check-components.service';
import { Filters } from 'src/app/models/filters.model';
import { Router } from '@angular/router';

@Component({
  selector: 'calendar',
  templateUrl: 'calendar.html'
})
export class CalendarComponent {
  public calendarInitialConfig: InitConfig;
  public nextArticles: Array<any> = [];
  public articlesToShow: Array<Event> = [];
  public showHeader: boolean;
  public calendarDataComponent;
  public isEmpty: boolean = true;
  public itemsCount: number;
  public itemsFiltered: number;
  public loading: boolean = true;
  private errorInComponentCalendar: { textError: string; haveError: boolean } = { textError: '', haveError: false };

  constructor(private router: Router, private calendarProvider: CalendarService, private checkComponent: CheckComponentsService) {
    this.calendarInitialConfig = this.calendarProvider.calendarInitConfig;
    this.showHeader = this.calendarInitialConfig.view.showHeader;
    this.getCalendar(this.calendarInitialConfig.id);
  }

  private getCalendar(id) {
    const filters: Filters = {
      searchKey: '',
      start: 0,
      orderBy: this.calendarInitialConfig.view.orderBy,
      orderDir: this.calendarInitialConfig.view.orderDir
    };

    this.calendarProvider.getCalendar(id, filters).subscribe({
      next: (response) => {
        this.calendarDataComponent = response;
        this.itemsCount = response.items.itemsCount;
        this.itemsFiltered = response.items.itemsFiltered;
        this.nextArticles = this.calendarProvider.orderEventsByMonth(this.calendarDataComponent.items.itemList);
        this.nextArticles = this.checkComponent.setItemsInComponent(this.nextArticles, this.itemsCount);
        this.isEmpty = this.checkComponent.checkEmptyComponent(this.nextArticles);
        this.setIdPageInComponent(id);
        this.loading = false;
      },
      error: (error) => {
        console.error('Error getting calendar', error);
        this.loading = false;
      }
    });
  }

  private setIdPageInComponent(idComponent: any) {
    this.calendarDataComponent.items.itemList.forEach((nextEvent) => {
      nextEvent.idPage = idComponent;
    });
  }

  public goToFullCalendar() {
    this.router.navigate([`/calendar/${this.calendarInitialConfig.id}/list`]);
  }

  public isListView() {
    return this.calendarInitialConfig.view.mode === 'item-list' && !this.errorInComponentCalendar.haveError;
  }

  public isButtonView() {
    return this.calendarInitialConfig.view.mode === 'button' && !this.errorInComponentCalendar.haveError;
  }

  public isVieMoreButtonVisible() {
    if (this.nextArticles) return this.itemsCount < this.itemsFiltered;
    return false;
  }
}
