import { Injectable } from '@angular/core';
import { PushNotification } from '@capacitor/push-notifications';
import { ToastController } from '@ionic/angular';
import { ToastOptions, ToastButton } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { ActiveToast, GlobalConfig, ToastrService } from 'ngx-toastr';
import { Environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ToastService {
  private toast;
  private DEFAULT_OPTIONS: ToastOptions = {
    message: '',
    cssClass: 'toast-style',
    position: Environment.toast.position,
    duration: Environment.toastDuration,
  };

  constructor(private toastCtrl: ToastController, private translate: TranslateService, private toastAngular: ToastrService) {}

  // Show a toast message given the message.
  public async info(message: string) {
    message = await this.translate.get(message).toPromise();
    this.toast = await this.toastCtrl.create({...this.DEFAULT_OPTIONS, message});

    this.toast.present();
  }

  public async danger(message: string) {
    message = await this.translate.get(message).toPromise();

    const color = 'danger';

    this.toast = await this.toastCtrl.create({...this.DEFAULT_OPTIONS, message, color});

    this.toast.present();
  }

  public hide(): void {
    if (this.toast) this.toast.dismiss();
  }

  public showPush(pushNotification: PushNotification, handler: (notification) => void) {
    const options = { timeOut: 4000, toastClass: 'ngx-toastr notification-toast-style', positionClass: 'toast-top-center' };
    const toast: ActiveToast<any> = this.toastAngular.show(pushNotification.body, pushNotification.title, options);

    toast.onTap.subscribe(() => {
      handler(pushNotification);
    });
  }
}
