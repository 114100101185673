import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { InitConfig } from '../models/initConfig.model';
import { Filters } from '../models/filters.model';
import { ToastService } from './toast.service';
import { Media, MediaObject, MEDIA_STATUS } from '@awesome-cordova-plugins/media/ngx';
import { MusicControls } from '@awesome-cordova-plugins/music-controls/ngx';

import { Environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class PodcastService {
  public podcastConfig: InitConfig;
  private playingEpisode = null;

  constructor(private httpService: HttpService, public toast: ToastService, private musicControls: MusicControls, private media: Media) {}

  createControls(episode) {
    this.musicControls.create({
      track: episode.title, // optional, default : ''
      artist: episode.authorName, // optional, default : ''
      cover: episode.image, // optional, default : nothing
      // cover can be a local path (use fullpath 'file:///storage/emulated/...', or only 'my_image.jpg' if my_image.jpg is in the www folder of your app)
      //           or a remote url ('http://...', 'https://...', 'ftp://...')
      isPlaying: true, // optional, default : true
      dismissable: false, // optional, default : false

      // hide previous/next/close buttons:
      hasPrev: true, // show previous button, optional, default: true
      hasNext: true, // show next button, optional, default: true
      hasClose: true, // show close button, optional, default: false

      // iOS only, optional
      // album: 'Absolution',     // optional, default: ''
      // duration: 60, // optional, default: 0
      // elapsed: 10, // optional, default: 0
      hasSkipForward: false, // show skip forward button, optional, default: false
      hasSkipBackward: false, // show skip backward button, optional, default: false
      // skipForwardInterval: 15, // display number for skip forward, optional, default: 0
      // skipBackwardInterval: 15, // display number for skip backward, optional, default: 0
      hasScrubbing: false, // enable scrubbing from control center and lockscreen progress bar, optional

      // Android only, optional
      // text displayed in the status bar when the notification (and the ticker) are updated, optional
      ticker: episode.title,
      // All icons default to their built-in android equivalents
      playIcon: 'media_play',
      pauseIcon: 'media_pause',
      prevIcon: 'media_prev',
      nextIcon: 'media_next',
      closeIcon: 'media_close',
      notificationIcon: 'notification'
    });

    this.musicControls.subscribe().subscribe((action) => {
      const message = JSON.parse(action).message;
      switch (message) {
        case 'music-controls-next':
          this.playingEpisode.next$$.next(this.playingEpisode);
          break;
        case 'music-controls-previous':
          this.playingEpisode.previous$$.next(this.playingEpisode);
          break;
        case 'music-controls-pause':
          this.pause(this.playingEpisode);
          break;
        case 'music-controls-play':
          this.play(this.playingEpisode);
          break;
        case 'music-controls-destroy':
          this.destroyMusicControls();
          break;

        // External controls (iOS only)
        case 'music-controls-toggle-play-pause':
          // Do something
          break;
        case 'music-controls-seek-to':
          const seekToInSeconds = JSON.parse(action).position;
          this.musicControls.updateElapsed({
            elapsed: seekToInSeconds,
            isPlaying: true
          });
          this.seekTo(this.playingEpisode, seekToInSeconds);
          // Do something
          break;
        case 'music-controls-skip-forward':
          // Do something
          break;
        case 'music-controls-skip-backward':
          // Do something
          break;

        // Headset events (Android only)
        // All media button events are listed below
        case 'music-controls-media-button':
          // Do something
          break;
        case 'music-controls-headset-unplugged':
          // Do something
          break;
        case 'music-controls-headset-plugged':
          // Do something
          break;
        default:
          break;
      }
    });

    this.musicControls.listen(); // activates the observable above
  }

  async pause(episode) {
    episode.mediaObject.pause();
    this.musicControls.updateDismissable(true);
    this.musicControls.updateIsPlaying(false);
  }

  create(episode) {
    return this.media.create(episode.url);
  }

  play(episode) {
    if (this.playingEpisode !== null && this.playingEpisode.mediaObject !== episode.mediaObject) {
      this.playingEpisode.mediaObject.pause();
    }

    episode.mediaObject.play();

    this.playingEpisode = episode;

    this.createControls(episode);
  }

  stop() {
    if (this.playingEpisode !== null) {
      this.playingEpisode.mediaObject.stop();
      this.playingEpisode.mediaObject.release();
    }
    this.destroyMusicControls();
  }

  destroyMusicControls() {
    this.musicControls.updateIsPlaying(false);
    this.musicControls.updateDismissable(true);
    this.musicControls.destroy();
  }

  async rewind(episode, seconds: number) {
    const milliseconds = seconds * 1000;

    const position = await episode.mediaObject.getCurrentPosition();

    if (seconds > position) {
      episode.mediaObject.seekTo(0);
      return;
    }

    episode.mediaObject.seekTo(position * 1000 - milliseconds);
  }

  async forward(episode, seconds: number) {
    const milliseconds = seconds * 1000;

    const position = await episode.mediaObject.getCurrentPosition();
    const duration = await episode.mediaObject.getDuration();

    if (position + seconds > duration) {
      episode.mediaObject.seekTo(duration * 1000);
      return;
    }

    episode.mediaObject.seekTo(position * 1000 + milliseconds);
  }

  async seekTo(episode, seconds: number) {
    const milliseconds = seconds * 1000;

    episode.mediaObject.seekTo(milliseconds);
  }

  public getDataPodcast(id, filters: Filters) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/podcast/${id}/elements`, filters);
  }

  public getEpisode(id) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/podcast/episode/${id}`);
  }

  public like(idPage, idArticle) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/podcast/episode/${idArticle}/like`, {});
  }

  public unlike(idPage, idArticle) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/podcast/episode/${idArticle}/unlike`, {});
  }

  public getComments(idPage, idArticle, filters: Filters): any {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/podcast/episode/${idArticle}/comments`, filters);
  }

  public sendComment(idPage, idArticle, comment) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/podcast/episode/${idArticle}/comments`, {
      comment: comment
    });
  }

  public removeComment(itemId, commentId) {
    return this.httpService.myDelete(`${Environment.API_BASE_URL_V2}/api/empatica/v1/podcast/episode/${itemId}/comments/${commentId}`);
  }
}
