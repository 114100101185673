import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { introStyleConfig } from 'src/app/models/introStyleModel.model';
import { ClientService } from 'src/app/services/client.service';
import { EventsService } from 'src/app/services/events.service';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { HandlerErrorService } from 'src/app/services/handler-error.service';
import { InitialConfigService } from 'src/app/services/inital-config.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LoginService } from 'src/app/services/login.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'page-login-step-three',
  templateUrl: 'login-step-three.html',
  styleUrls: ['login-step-three.scss']
})
export class LoginStepThreePage {
  public introStyleConfig: introStyleConfig;
  public userClientData;
  public logo: string;
  public bgColor: string;
  public bgImage: string;
  public welcomeMessage: any;
  public userData: any;
  public username: string;
  public clientId;
  public loginErrors;
  public isPWDEmpty: boolean = false;
  public hasPWDError: boolean = false;
  public textError: string;
  private defaultIntroStyleConfig = {
    logo: '',
    bgColor: '',
    bgImage: '',
    welcomeMessage: ''
  };

  public loginForm = new UntypedFormGroup({
    userPassword: new UntypedFormControl({ value: '', disabled: false }, Validators.required)
  });

  public showingPassword = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public gConst: GlobalConstService,
    public initialConfigService: InitialConfigService,
    public events: EventsService,
    public loginProvider: LoginService,
    private loading: LoadingService,
    private handError: HandlerErrorService,
    private translate: TranslateService,
    private platform: Platform,
    public clientService: ClientService,
  ) {
    this.username = this.router.getCurrentNavigation().extras.state.username;
    this.clientId = this.router.getCurrentNavigation().extras.state.id;
  }

  async ionViewDidEnter() {

    if(this.username){
      this.userData = { username: this.username, id: this.clientId};
    } else{
      this.userData = this.initialConfigService.userInitialConfigData;
    }
    
    this.loginErrors = this.handError.loginErrors;
    this.introStyleConfig = this.initialConfigService.styleSheet ? this.initialConfigService.styleSheet.intro : this.defaultIntroStyleConfig;
      this.logo = this.introStyleConfig.logo;
      this.bgColor = this.introStyleConfig.bgColor;
      this.bgImage = this.introStyleConfig.bgImage;
      this.welcomeMessage = this.introStyleConfig.welcomeMessage;
  }


  public goHome() {
    const userPassword = this.loginForm.value.userPassword;
    this.isPWDEmpty = Boolean(!userPassword);
    if (this.isPWDEmpty) {
      return;
    }

    this.login(userPassword);
  }

  private async login(password) {

    console.log(this.userData);
    

    await this.loading.show();
    this.loginProvider
      .loginStepThree({ username: this.userData.username, password, clientId: this.userData.id })
      .pipe(finalize(async () => await this.loading.hide()))
      .subscribe({
        next: (response) => {
          if (response.resetPasswordRequested === 'reset-password') {
            this.goToResetPassword(response);
            return;
          }

          this.initialConfigService.rootPageComponents = response.rootPages;
          this.initialConfigService.styleSheet = response.client.style;
          this.events.publish({ name: 'session', value: 'ready' });

          if (!this.platform.is('desktop')) {
            this.router.navigate(['home']);
          }
        },
        error: (err) => {
          this.errorHandlerLogin(err);
        }
      });
  }

  public goToResetPassword(userData) {
    this.router.navigate(['/login/reset'], { state: { userData } });
    return;
  }

  public gotToChangePassword() {
    this.router.navigate(['rescue'], { state: { userData: this.userData } });
  }

  public goBack() {
    this.router.navigate(['login/first']);
  }

  private errorHandlerLogin(error: any) {
    this.translate.get('LOGIN-ERROR-PASSWORD').subscribe((res: string) => {
      this.textError = res;
      console.error(error);
      this.hasPWDError = true;
      this.loading.hide();
    });
  }

  public cleanErrors() {
    this.isPWDEmpty = false;
    this.hasPWDError = false;
  }

  public togglePassword(input) {
    this.showingPassword = !this.showingPassword;
    input.type = input.type === 'password' ? 'text' : 'password';
  }
}

