import { Component, OnInit } from '@angular/core';

import { InitialConfigService } from 'src/app/services/inital-config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'page-login-confirm-rescue-password',
  templateUrl: 'login-confirm-rescue-password.html'
})
export class LoginConfirmRescuePasswordPage implements OnInit {
  public username: any;
  public param: { value: any };
  logo: any;
  introStyleConfig: any;
  userData: any;

  constructor(public router: Router, public initialConfig: InitialConfigService) {
    this.username = this.router.getCurrentNavigation().extras.state.username;
    this.userData = this.router.getCurrentNavigation().extras.state.userData;
  }

  ngOnInit() {
    this.param = { value: this.username };
    this.introStyleConfig = this.initialConfig.styleSheet.intro;
    this.logo = this.introStyleConfig.logo;
  }

  public backLoginStepOne() {
    this.router.navigate([`/login/first`], { state: { userData: this.userData } });
  }
}
