import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { LinksService } from 'src/app/services/links.service';
import { LoginService } from 'src/app/services/login.service';
import { TabsService } from 'src/app/services/tabs.service';
import { EventsService } from '../../services/events.service';
import { Capacitor, Plugins } from '@capacitor/core';
const { Keyboard } = Plugins;

@Component({
  selector: 'tabs',
  templateUrl: 'tabs.html',
  styleUrls: ['tabs.scss']
})
export class TabsComponent {
  tabs$;
  showLabels;
  public showTabs = true;
  constructor(
    tabsService: TabsService,
    private linkProvider: LinksService,
    private router: Router,
    public loginService: LoginService,
    private eventsService: EventsService,
    private ngZone: NgZone
  ) {
    this.eventsService.subscribe({ name: 'session', value: 'ready' }).subscribe(() => {
      this.tabs$ = tabsService.get();
      this.showLabels = loginService.rescueAppTheme().tabs.showLabels;
    });

    if (Capacitor.platform !== 'web') {
      Keyboard.addListener('keyboardWillShow', () => {
        this.ngZone.run(() => {
          this.showTabs = false;
        });
      });

      Keyboard.addListener('keyboardWillHide', () => {
        this.ngZone.run(() => {
          this.showTabs = true;
        });
      });
    }
  }

  public open(link) {
    const handlerLinkType = this.linkProvider.linkType[link.targetType];
    handlerLinkType(link);
  }
}
