// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  background: rgba(0, 0, 0, 0.9);
}
:host ion-header {
  background-color: transparent !important;
}
:host ion-header ion-toolbar {
  --background: transparent !important;
}
:host ion-header ion-toolbar ion-button {
  --color: #fff !important;
}
:host ion-header ion-toolbar ion-title {
  color: #fff !important;
}
:host ion-header ion-toolbar .button {
  color: #fff !important;
  background-color: transparent !important;
  position: relative;
  padding: 0 5px;
}
:host ion-content {
  display: contents;
  --background: transparent;
  background-color: transparent !important;
}
.plt-desktop :host ion-content {
  --background: var(--cms-web-bg-color);
}
:host iframe, :host video {
  display: block;
  margin: calc(45px + env(safe-area-inset-top)) auto 0;
  height: calc(100vh - (45px + env(safe-area-inset-top)));
}`, "",{"version":3,"sources":["webpack://./src/app/components/gallery/video-player/video-player.scss"],"names":[],"mappings":"AAAA;EAEI,8BAAA;AAAJ;AAEI;EACE,wCAAA;AAAN;AACM;EAIE,oCAAA;AAFR;AADQ;EACE,wBAAA;AAGV;AACQ;EACE,sBAAA;AACV;AAEQ;EACE,sBAAA;EACA,wCAAA;EACA,kBAAA;EACA,cAAA;AAAV;AAKI;EACI,iBAAA;EACA,yBAAA;EACJ,wCAAA;AAHJ;AAIQ;EACI,qCAAA;AAFZ;AAMI;EACI,cAAA;EACA,oDAAA;EACA,uDAAA;AAJR","sourcesContent":[":host {\n\n    background: rgba(0, 0, 0, 0.9);\n\n    ion-header {\n      background-color: transparent !important;\n      ion-toolbar {\n        ion-button {\n          --color: #fff !important;\n        }\n        --background: transparent !important;\n  \n        ion-title {\n          color: #fff !important;\n        }\n  \n        .button {\n          color: #fff !important;\n          background-color: transparent !important;\n          position: relative;\n          padding: 0 5px;\n        }\n      }\n    }\n\n    ion-content{\n        display: contents;\n        --background: transparent;\n    background-color: transparent !important;\n        .plt-desktop &  {\n            --background: var(--cms-web-bg-color);\n        }\n\n    }\n    iframe, video{\n        display: block;\n        margin: calc(45px + env(safe-area-inset-top)) auto 0;\n        height: calc(100vh - calc(45px + env(safe-area-inset-top)));\n\n      }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
