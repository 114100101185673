import { Injectable } from '@angular/core';
import { Environment } from 'src/environments/environment';
import { GlobalConstService } from './global-const.service';
import { HttpService } from './http.service';

@Injectable({ providedIn: 'root' })
export class AgendaService {
  public initConfigAgendas;

  constructor(private httpService: HttpService) {}

  public getUsers(id, filters) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/agenda/${id}/elements`, filters);
  }

  public async getInitConfigAgendasComponent(): Promise<any> {
    return this.initConfigAgendas;
  }
}
