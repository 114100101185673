import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  constructor(private platformService: PlatformService){

  }
  async getAllDeviceInfo() {
    return await App.getInfo();
  }

  public async getVersionAndBuild() {
    if(this.platformService.isWeb()){
      return {
        appVersion: "0.0.0",
        appBuild: "00000"
      }
    }
    const device = await this.getAllDeviceInfo();

    return {
      appVersion: device.version || "0.0.0",
      appBuild: device.build || "00000"
    }
  }
}
