import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';
import { AlertModalComponent } from '../components/alert-modal/alert-modal.component';
import { DeviceService } from './device.service';
import { HttpService } from './http.service';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(
    private httpService: HttpService,
    private deviceService: DeviceService,
    private platformService: PlatformService,
    private modalController: ModalController
  ) {}

  public getAlerts() {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/user/current/alerts`);
  }

  public async showAlerts() {
    const alertsAlreadyViewNoRecursive = JSON.parse(localStorage.getItem('alertsAlreadyViewNoRecursive')) ?? [];
    const versionAndBuild = await this.deviceService.getVersionAndBuild();
    const operators = {
      '==': (a, b) => a == b,
      '>': (a, b) => a > b,
      '>=': (a, b) => a >= b,
      '<': (a, b) => a < b,
      '<=': (a, b) => a <= b
    };
    this.getAlerts()
      .pipe(
        map((alerts) =>
          alerts.filter(
            (alert) =>
              (alert.recursive || !alertsAlreadyViewNoRecursive.includes(alert.id)) &&
              this.platformService.get() === alert.platform &&
              operators[alert.inequalitySign](Number(versionAndBuild.appBuild), Number(alert.buildVersion))
          )
        )
      )
      .subscribe((alerts) => {
        alerts.forEach(async (alert) => {
          if (!alert.blockApp && !alert.recursive) {
            alertsAlreadyViewNoRecursive.push(alert.id);
          }

          const modal = await this.modalController.create({
            component: AlertModalComponent,
            cssClass: 'alertModal auto-height',
            componentProps: {
              alert: alert
            },
            backdropDismiss: false
          });
          await modal.present();
        });

        localStorage.setItem('alertsAlreadyViewNoRecursive', JSON.stringify(alertsAlreadyViewNoRecursive));
      });
  }
}
