import { Component, OnDestroy } from '@angular/core';

// Pages
import { InitConfig } from 'src/app/models/initConfig.model';
import { CheckComponentsService } from 'src/app/services/check-components.service';
import { NetworkService } from 'src/app/services/network.service';
import { Filters } from 'src/app/models/filters.model';
import { Router } from '@angular/router';
import { PodcastService } from 'src/app/services/podcast.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'podcast',
  templateUrl: 'podcast.component.html',
  styleUrls: ['podcast.component.scss']
})
export class PodcastComponent implements OnDestroy {
  public initialConfigPodcast: InitConfig;
  public podcastListEpisodes: Array<any> = [];
  public isEmpty: boolean = false;
  public showHeader: boolean;
  public showViewMoreBtn: boolean = false;

  public episodes: Array<any> = [];
  public currentIndex: number = 0;
  public isPlaying: boolean = false;
  private errorInComponentBlog: { haveError: boolean; textError: string } = {
    haveError: false,
    textError: ''
  };
  private dataPodcastSub: Subscription;

  constructor(
    private router: Router,
    private podcastService: PodcastService,
    private checkComponent: CheckComponentsService,
    public network: NetworkService
  ) {
    this.initialConfigPodcast = this.podcastService.podcastConfig;

    this.showHeader = this.initialConfigPodcast.view.showHeader;
    this.getDataPodcast();
  }

  ngOnDestroy() {
    this.dataPodcastSub.unsubscribe();
  }

  private getDataPodcast() {
    const filters: Filters = {
      searchKey: '',
      start: 0,
      length: this.initialConfigPodcast.view.maxNumItems
    };

    this.dataPodcastSub = this.podcastService.getDataPodcast(this.initialConfigPodcast.id, filters).subscribe({
      next: (response: InitConfig) => {
        this.podcastListEpisodes = this.checkComponent.setItemsInComponent(response.items.itemList, response.items.itemsCount);
        this.showViewMoreBtn = response.items.itemsCount > response.items.itemsFiltered;
        this.initialConfigPodcast.items = response.items;
        this.isEmpty = this.checkComponent.checkEmptyComponent(this.podcastListEpisodes);

        this.createPlayList();
      },
      error: (error) => {
        console.error('Error getting data blog', error);
      }
    });
  }

  private refresh(data?) {
    this.getDataPodcast();
  }

  public goToFullPodcast() {
    this.router.navigate([`/podcast/${this.initialConfigPodcast.id}/list`]);
  }

  public isButtonView() {
    return this.initialConfigPodcast.view.mode === 'button' && !this.errorInComponentBlog.haveError;
  }

  private createPlayList() {
    this.episodes = this.podcastListEpisodes.map((episode) => {
      const e = {
        ...episode,
        mediaObject: null,
        status$$: new BehaviorSubject('not-playing')
      };

      e.status$ = e.status$$.asObservable().pipe(shareReplay());

      return e;
    });
  }

  trackByFn(index: number, element: any) {
    return element ? element.id : null;
  }
}
