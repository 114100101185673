import { Injectable, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';

import { LinksService } from './links.service';
import { HttpService } from './http.service';
import { BlogService } from './blog.service';
import { GalleryService } from './gallery.service';
import { GlobalConstService } from './global-const.service';
import { DocumentService } from './document.service';
import { SurveyService } from './survey.service';
import { AgendaService } from './agenda.service';
import { ContactsService } from './contacts.service';
import { CalendarService } from './calendar.service';
import { CalendarComponent } from '../components/calendar/calendar';
import { DocumentsComponent } from '../components/documents/documents-component';
import { GalleryComponent } from '../components/gallery/gallery-component';
import { BlogComponent } from '../components/blog/blog';
import { SurveyComponent } from '../components/survey/survey';
import { LinksComponent } from '../components/links/links';
import { ContactsComponent } from '../components/contacts/contacts-component';
import { AgendaComponent } from '../components/agenda/agenda';
import { PersonalDocumentsService } from './personal-document.service';
import { PersonalDocumentsComponent } from '../components/personal-documents/personal-documents.component';
import { EmbeddedComponent } from '../components/embdedded/embedded.component';
import { EmbeddedService } from './embedded.service';
import { PodcastService } from './podcast.service';
import { PodcastComponent } from '../components/podcast/podcast.component';
import { WallComponent } from '../components/wall/wall';
import { WallService } from './wall.service';
import { Environment } from 'src/environments/environment';
import { BehaviorSubject, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InitialConfigService {
  [x: string]: any;

  public styleSheet;
  public userInitialConfigData;
  public rootPageComponents: any = null;

  public homePageLoaded = false;

  public opened: BehaviorSubject<boolean> = new BehaviorSubject(false)

  endPointStepTWO: any;

  constructor(
    private httpService: HttpService,
    private blogProvider: BlogService,
    private wallService: WallService,
    private galleryProvider: GalleryService,
    private documentsProvider: DocumentService,
    private calendarProvider: CalendarService,
    private linkProvider: LinksService,
    private contactsProvider: ContactsService,
    private surveyProvider: SurveyService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private agendaProvider: AgendaService,
    private personalDocumentsService: PersonalDocumentsService,
    private embeddedService: EmbeddedService,
    private podcastService: PodcastService
  ) {}

  public getHomeConfig(pageId) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/pages/${pageId}`);
  }

  public getPageComponents(pageId) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/pages/${pageId}`);
  }

  public saveUserData(userData) {
    this.userInitialConfigData = userData;
    this.rootPageComponents = userData.rootPages;
    // Está en .style cuando se hace el login en dos/tres pasos y en client.style cuando se hace sólo en uno
    this.styleSheet = userData.style ? userData.style : userData.client.style;
  }

  public clearData() {

    if(!this.opened.value){
      this.styleSheet = null;
    }

    this.homePageLoaded = false
    this.rootPageComponents = null;
    this.userInitialConfigData = null;

  }

  public getComponents(components: any[], viewContainerRef: ViewContainerRef) {
    if (!components || components.length === 0) return;

    components.forEach((component) => {
      const componentComponent = this.prepareViewComponents(component);
      if (componentComponent === undefined) {
        return;
      }
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(<any>componentComponent);
      viewContainerRef.createComponent(componentFactory);
    });
  }

  public prepareViewComponents(component) {
    if (component.type === 'calendar') {
      this.calendarProvider.calendarInitConfig = component;
      return CalendarComponent;
    }
    if (component.type === 'documents') {
      this.documentsProvider.docsInitConfig = component;
      return DocumentsComponent;
    }
    if (component.type === 'gallery') {
      this.galleryProvider.galleryInitialConfig = component;
      return GalleryComponent;
    }
    if (component.type === 'blog') {
      this.blogProvider.blogConfig = component;
      return BlogComponent;
    }
    if (component.type === 'survey') {
      this.surveyProvider.surveyInitConfig = component;
      return SurveyComponent;
    }
    if (component.type === 'links') {
      this.linkProvider.initialConfigLink = component;
      return LinksComponent;
    }
    if (component.type === 'contacts') {
      this.contactsProvider.initConfigContacts = component;
      return ContactsComponent;
    }
    if (component.type === 'agendas') {
      this.agendaProvider.initConfigAgendas = component;
      return AgendaComponent;
    }

    if (component.type === 'personalDocuments') {
      this.personalDocumentsService.initConfigPersonalDocuments = component;
      return PersonalDocumentsComponent;
    }

    if (component.type === 'embedded') {
      this.embeddedService.initConfigEmbedded = component;
      return EmbeddedComponent;
    }

    if (component.type === 'podcast') {
      this.podcastService.podcastConfig = component;
      return PodcastComponent;
    }

    if (component.type === 'wall') {
      this.wallService.wallConfig = component;
      return WallComponent;
    }
  }

}
