import { Injectable } from '@angular/core';
import { CameraService } from './camera.service';
import { PlatformService } from './platform.service';
import { ToastService } from './toast.service';
import { Camera, CameraResultType, CameraSource, GalleryImageOptions, ImageOptions, Photo } from '@capacitor/camera';

@Injectable({
  providedIn: 'root'
})
export class CameraCapacitorService {
  constructor(
    private toast: ToastService,
    private platformService: PlatformService,
    private cameraService: CameraService
  ) {}

  async takePhoto() {

    console.log('takePhoto');
    
    if (await this.platformService.isBelowAndroid13()) {
      console.log('takePhoto android below 13');
      
      const permissions = await this.cameraService.getPermission();
      if (false === permissions) {
        this.toast.info('INSUFFICIENT-PERMISSIONS');
        return;
      }
    } 
    
    console.log('takePhoto passed');
    
    const cameraOptions: ImageOptions = {
      allowEditing: false,
      source: CameraSource.Camera,
      resultType: CameraResultType.Uri,
      saveToGallery: true
    };

    let image: Photo = await Camera.getPhoto(cameraOptions);

    console.log('takePhoto  image: ', image);
    
    return image;

  }

  async selectPicture() {

    console.log('selectPicture');
    

    // Android below 13
    if (await this.platformService.isBelowAndroid13()) {
      console.log('selectPicture android below 13');
      
      const permissions = await this.cameraService.getPermission();
      if (false === permissions) {
        this.toast.info('INSUFFICIENT-PERMISSIONS');
        return;
      }
    }

    const cameraOptions: GalleryImageOptions = {
      quality: 50,
      limit: 1
    };

    let image = await Camera.pickImages(cameraOptions);

    console.log('selectPicture image: ', image);
    return image;
  }
}
