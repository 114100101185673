// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  background-color: var(--cms-button-bg-color);
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: 50%;
}
:host span.initials {
  color: var(--cms-button-text-color);
  width: 100%;
  text-align: center;
}
:host span.initials.groups {
  display: flex;
  justify-content: center;
}
:host span.initials.groups ion-icon {
  font-size: 23px;
}
:host ion-img {
  overflow: hidden;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/app/components/avatar/avatar.component.scss"],"names":[],"mappings":"AAAA;EACE,4CAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;AACF;AAAE;EACE,mCAAA;EACA,WAAA;EACA,kBAAA;AAEJ;AADI;EACE,aAAA;EACA,uBAAA;AAGN;AAFM;EACE,eAAA;AAIR;AAAE;EACE,gBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,iBAAA;AAEJ","sourcesContent":[":host {\n  background-color: var(--cms-button-bg-color);\n  display: flex;\n  align-items: center;\n  height: 100%;\n  border-radius: 50%;\n  span.initials {\n    color: var(--cms-button-text-color);\n    width: 100%;\n    text-align: center;\n    &.groups {\n      display: flex;\n      justify-content: center;\n      ion-icon {\n        font-size: 23px;\n      }\n    }\n  }\n  ion-img {\n    overflow: hidden;\n    border-radius: 50%;\n    height: 100%;\n    width: 100%;\n    object-fit: cover;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
