import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, AlertController } from '@ionic/angular';
import { interval, Observable, Subscription, timer } from 'rxjs';
import { map, pluck, switchMap, take, tap } from 'rxjs/operators';
// Pages
import { Filters } from 'src/app/models/filters.model';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { EventsService } from 'src/app/services/events.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoadingService } from 'src/app/services/loading.service';
import { MessagesService } from 'src/app/services/messages.service';
import { Group } from '../../models/group.model';
import { AlertButton, AlertOptions } from '@ionic/core';
import moment from 'moment';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit, OnDestroy {
  private filters: Filters = {
    searchKey: '',
    start: 0,
    orderBy: 'name',
    orderDir: 'asc',
    length: 20
  };
  public rooms$: Observable<any>;
  public loading$: Observable<any>;
  private namePage: string = 'Messages';
  refreshChatListSubscription: Subscription;

  public chatImages = {};

  constructor(
    private messageProvider: MessagesService,
    private loading: LoadingService,
    public events: EventsService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private alertCtrl: AlertController,
    private languageService: LanguageService
  ) {}

  ngOnDestroy() {
    this.refreshChatListSubscription.unsubscribe();
  }

  async ngOnInit() {
    this.refreshChatListSubscription = interval(1000 * 60).subscribe({
      next: () => {
        this.messageProvider.getRooms(this.filters);
      }
    });

    this.rooms$ = this.messageProvider.roomsList$.pipe(
      pluck('roomsList'),
      tap((chats) =>
        chats.forEach((chat) => {
          if (this.chatImages[chat.id] === undefined) {
            this.chatImages[chat.id] = chat.imgUrl;
          }
        })
      ),
      map((chats) =>
        chats.sort((a, b) => {
          if (a.lastMessageDate && b.lastMessageDate) {
            const momentALastMessageDate = moment(a.lastMessageDate);
            const momentBLastMessageDate = moment(b.lastMessageDate);

            if (momentALastMessageDate.unix() > momentBLastMessageDate.unix()) {
              return -1;
            }

            if (momentALastMessageDate.unix() < momentBLastMessageDate.unix()) {
              return 1;
            }

            if (momentALastMessageDate.unix() === momentBLastMessageDate.unix()) {
              return 0;
            }
          }

          if (a.lastMessageDate && !b.lastMessageDate) {
            return -1;
          }

          if (!a.lastMessageDate && b.lastMessageDate) {
            return 1;
          }

          return 0;
        })
      )
    );
    this.loading$ = this.messageProvider.roomsList$.pipe(pluck('loading'));
    this.registerAnalyticsInFirebase();
  }

  public searchRoom($event) {
    if ($event.target) {
      this.filters.searchKey = $event.target.value;
    }
    this.messageProvider.getListOfRooms($event, this.filters);
  }

  public async joinRoom(group: Group) {
    if (this.isBot(group)) {
      await this.loading.hide();
      this.router.navigate([`chat/chatbot/${group.token}`]);
      return;
    }
    await this.loading.show();
    await this.goGroupChat(group);
  }

  public isBot(group: Group) {
    return group.token && group.token.toUpperCase().includes('CHATBOT');
  }

  public async goGroupChat(group): Promise<void> {
    const type = group.type === 'group' ? 'group' : 'single';
    await this.loading.hide();
    this.router.navigate([`chat/${type}/${group.token}`]);
  }

  public refresh($event) {
    this.messageProvider.getListOfRooms($event);
  }

  private registerAnalyticsInFirebase() {
    const paramsToAnalytics = { navigation: 5 };
    this.analyticsService.registerEvent(paramsToAnalytics, this.namePage);
  }

  trackByIdRoom(index: number, room: any): string {
    return room.token;
  }

  async confirmPin(room) {
    const alertButtons: AlertButton[] = [
      { text: await this.languageService.translate('CANCEL'), role: 'cancel' },
      { text: await this.languageService.translate('PIN'), handler: () => this.pin(room) }
    ];

    const alertOptions: AlertOptions = {
      header: await this.languageService.translate('ARE-YOU-SURE-YOU-WANT-TO-PIN-THIS-CHAT'),
      buttons: alertButtons
    };
    const alert = await this.alertCtrl.create(alertOptions);

    await alert.present();
  }

  async confirmArchive(room) {
    const alertButtons: AlertButton[] = [
      { text: await this.languageService.translate('CANCEL'), role: 'cancel' },
      { text: await this.languageService.translate('ARCHIVE'), handler: () => this.archive(room) }
    ];

    const alertOptions: AlertOptions = {
      header: await this.languageService.translate('ARE-YOU-SURE-YOU-WANT-TO-ARCHIVE-THIS-CHAT'),
      buttons: alertButtons
    };
    const alert = await this.alertCtrl.create(alertOptions);

    await alert.present();
  }

  async confirmUnpin(room) {
    const alertButtons: AlertButton[] = [
      { text: await this.languageService.translate('CANCEL'), role: 'cancel' },
      { text: await this.languageService.translate('UNPIN'), handler: () => this.unpin(room) }
    ];

    const alertOptions: AlertOptions = {
      header: await this.languageService.translate('ARE-YOU-SURE-YOU-WANT-TO-UNPIN-THIS-CHAT'),
      buttons: alertButtons
    };
    const alert = await this.alertCtrl.create(alertOptions);

    await alert.present();
  }

  pin(room) {
    this.messageProvider
      .pin(room)
      .pipe(take(1))
      .subscribe(() => this.messageProvider.getListOfRooms());
  }

  archive(room) {
    this.messageProvider
      .archive(room)
      .pipe(take(1))
      .subscribe(() => this.messageProvider.getListOfRooms());
  }

  unpin(room) {
    this.messageProvider
      .unpin(room)
      .pipe(take(1))
      .subscribe(() => this.messageProvider.getListOfRooms());
  }
}
