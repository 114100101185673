import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { error } from 'console';
import { Observable, Subject, timer } from 'rxjs';
import { delay, finalize, map, shareReplay, take, takeUntil, tap } from 'rxjs/operators';
//Fun
import { HostComponentDirective } from 'src/app/directives/host-component.directive';
import { EventsService } from 'src/app/services/events.service';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { InitialConfigService } from 'src/app/services/inital-config.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LoginService } from 'src/app/services/login.service';
import { MessagesService } from 'src/app/services/messages.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'page-home',
  templateUrl: 'home.html',
  styleUrls: ['home.scss']
})
export class HomePage{
  @ViewChild(HostComponentDirective) hostComponent;

  public pageParams: any;
  public cssStyleSheet = this.initialConfigProvider.styleSheet;
  public titleConfig = {
    logo: '',
    text: ''
  };
  private idPage: number;
  private namePage: string;
  public unreadNotifications$: Observable<number>;
  public opened: boolean;
  public isLogged: any;

  constructor(
    public router: Router,
    public initialConfigProvider: InitialConfigService,
    private loading: LoadingService,
    public gConst: GlobalConstService,
    public events: EventsService,
    private notifyProvider: NotificationService,
    public messageProvider: MessagesService,
    public loginService: LoginService
  ) { 
    const userData = this.loginService.rescueSessionDataUser();    
    if(!this.initialConfigProvider.homePageLoaded && userData){
      console.log('!this.initialConfigProvider.homePageLoaded');
      
      this.loading.show();
    }
  }


  async ionViewWillEnter() {
    this.events
      .subscribe({ name: 'app', value: 'ready' })
      .pipe(
        take(1)
      )
      .subscribe({
        next: async (appReady) => {
        this.pageParams = await this.initialConfigProvider.rootPageComponents.components;
        this.idPage = await this.initialConfigProvider.rootPageComponents.id;
        this.namePage = await this.initialConfigProvider.rootPageComponents.namePage;
        this.cssStyleSheet =  await this.initialConfigProvider.styleSheet;
        this.getNotifications();
        this.setHomeTitle();
        this.initApp();
      },
      error: async (err) =>{

      },
      complete: async ()=>{
        console.log('complete, should hide loading');
        await this.loading.hide()
      }
    });
  }

  get loginPage() {
    return Environment.platform === 'web' ? 'login/web' : 'login/first';
  }

  public goToNotify() {
    this.router.navigate(['notifications']);
  }

  public goToLogin() {
    this.router.navigate([this.loginPage]);
  }

  public refresh($event) {
    this.initialConfigProvider.homePageLoaded = false;
    this.refreshHomePage($event);
  }

  private refreshHomePage($event) {
    this.initialConfigProvider.getHomeConfig(this.idPage).subscribe((homeConfig) => {
      this.pageParams = homeConfig.components;
      this.idPage = homeConfig.id;
      this.namePage = homeConfig.name;
      this.initApp();
      this.getNotifications();
      $event.target.complete();
    });
  }

  public setHomeTitle() {
    if (!this.cssStyleSheet) {
      return;
    }

    const headerData = this.cssStyleSheet.header;

    if (headerData.logo) {
      this.titleConfig.text = null;

      this.titleConfig.logo = headerData.logo;
      return;
    } else if (headerData.homeTitle) {
      this.titleConfig.logo = null;
      this.titleConfig.text = headerData.homeTitle;
      return;
    }
  }

  private initApp() {
    if (!this.pageParams) return;

    if (!this.initialConfigProvider.homePageLoaded) {
      this.setContentComponents();
      this.initialConfigProvider.homePageLoaded = true;
    }
  }

  private setContentComponents() {
    const viewContainerRef: ViewContainerRef = this.hostComponent.viewRef;
    viewContainerRef.clear();
    this.initialConfigProvider.getComponents(this.pageParams, viewContainerRef);
  }

  private getNotifications() {

      this.unreadNotifications$ = this.notifyProvider.getNotifications()
      .pipe(
        map((res) => res.items.itemsNotViewedCount),
        shareReplay()
      );

  }
  
}