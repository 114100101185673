import { Component, ViewChild, OnInit,  } from '@angular/core';
import { NavParams, ModalController, IonicSlides } from '@ionic/angular';
import { DocumentService } from 'src/app/services/document.service';

import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'image-modal',
  templateUrl: 'image-modal.html',
  styleUrls: ['image-modal.scss']
})
export class ImageModalComponent implements OnInit {
  @ViewChild('mainslider') mainSlider;
  swiperModules = [IonicSlides];

  public mainViewer = {
    slidesPerView: 1,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
      bulletClass: 'swiper-pagination-bullet',
      dynamicBullets: true
    }
  };

  images;
  public indexActiveSlide$: BehaviorSubject<any> = new BehaviorSubject(0);


  constructor(
    private navParams: NavParams,
    private docsProvider: DocumentService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.images = this.navParams.get('images');
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  public downloadImage() {
    const images = this.images;
    let index = this.indexActiveSlide$.value;
    index = index ? index : 0;
    const image = images[index];
    image.uri = image.url;
    this.docsProvider.downloadDoc(image, true);
  }

   slideChanged(e: any) {

      const index = e.detail[0].activeIndex;
      this.indexActiveSlide$.next(index);
   }
 
}
