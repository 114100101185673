import { Component, Input } from '@angular/core';


@Component({
  selector: 'contacts-item',
  templateUrl: 'contacts-item.html',
  styleUrls: ['contacts-item.scss']
})
export class ContactsItemComponent {
  @Input() contact;
  constructor() { }
}
