import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UserNotAnonymousGuard } from './guards/user-not-anonymous.guard';

const home = '';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },

  // Home
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule) },
  { path: 'home-public', loadChildren: () => import('./pages/home-public/home-public.module').then((m) => m.HomePublicPageModule) },

  // Blogs
  {
    path: 'blog/:idPage/add',
    loadChildren: () => import('./pages/blog-pages/blog-add-item/blog-add-item.module').then((m) => m.BlogAddItemPageModule)
  },
  {
    path: 'blog/:idPage/edit/:id',
    loadChildren: () => import('./pages/blog-pages/blog-add-item/blog-add-item.module').then((m) => m.BlogAddItemPageModule)
  },
  {
    path: 'blog/:idPage/edit/',
    loadChildren: () => import('./pages/blog-pages/blog-add-item/blog-add-item.module').then((m) => m.BlogAddItemPageModule)
  },
  {
    path: 'blog/:idPage/detail/:id',
    loadChildren: () => import('./pages/blog-pages/detail-blog/detail-blog.module').then((m) => m.DetailBlogPageModule)
  },
  {
    path: 'blog/:id/list',
    loadChildren: () => import('./pages/blog-pages/full-screen-blog/full-screen-blog.module').then((m) => m.FullScreenBlogPageModule)
  },

  // Calendar
  {
    path: 'calendar/:idPage/detail/:id',
    loadChildren: () => import('./pages/calendar-pages/detail-calendar/detail-calendar.module').then((m) => m.DetailCalendarPageModule)
  },
  {
    path: 'calendar/:idPage/list',
    loadChildren: () => import('./pages/calendar-pages/full-screen-calendar/full-screen-calendar.module').then((m) => m.FullScreenCalendarPageModule)
  },
  {
    path: 'calendar/:idPage/add',
    loadChildren: () => import('./pages/calendar-pages/event-add/event-add.module').then((m) => m.EventAddPageModule)
  },
  {
    path: 'calendar/:idPage/edit/:id',
    loadChildren: () => import('./pages/calendar-pages/event-add/event-add.module').then((m) => m.EventAddPageModule)
  },

  // Chat
  {
    path: 'chat/list',
    loadChildren: () => import('./pages/chat-pages/full-screen-chat-list/full-screen-chat-list.module').then((m) => m.FullScreenChatListPageModule)
  },
  {
    path: 'chat/single/:id',
    loadChildren: () => import('./pages/chat-pages/full-screen-chat/full-screen-chat.module').then((m) => m.FullScreenChatPageModule)
  },
  {
    path: 'chat/group/:id',
    loadChildren: () => import('./pages/chat-pages/full-screen-chat/full-screen-chat.module').then((m) => m.FullScreenChatPageModule)
  },
  {
    path: 'chat/chatbot/:id',
    loadChildren: () => import('./pages/chat-pages/full-screen-chat/full-screen-chat.module').then((m) => m.FullScreenChatPageModule)
  },
  // Contacts and agenda
  {
    path: 'contacts/detail/:id',
    loadChildren: () => import('./pages/contacts-pages/detail-contacts/detail-contacts.module').then((m) => m.DetailContactsPageModule)
  },
  {
    path: 'contacts/detail/:id/:agenda',
    loadChildren: () => import('./pages/contacts-pages/detail-contacts/detail-contacts.module').then((m) => m.DetailContactsPageModule)
  },
  {
    path: 'contacts/:id/list',
    loadChildren: () =>
      import('./pages/contacts-pages/full-screen-contacts-component/full-screen-contacts.module').then((m) => m.FullScreenContactsPageModule)
  },
  {
    path: 'contacts/:id/list/:agenda',
    loadChildren: () =>
      import('./pages/contacts-pages/full-screen-contacts-component/full-screen-contacts.module').then((m) => m.FullScreenContactsPageModule)
  },

  // Directory
  {
    path: 'directory',
    loadChildren: () =>
      import('./pages/contacts-pages/full-screen-directory/full-screen-directory.module').then((m) => m.FullScreenDirectoryPageModule)
  },

  // Documents
  {
    path: 'documents',
    loadChildren: () => import('./pages/documents-pages/documents-section/documents-section.module').then((m) => m.DocumentsSectionPageModule)
  },
  {
    path: 'documents/:idComponent/add',
    loadChildren: () => import('./pages/documents-pages/document-add/document-add.module').then((m) => m.DocumentAddPageModule)
  },
  {
    path: 'documents/:idComponent/edit/:id',
    loadChildren: () => import('./pages/documents-pages/document-add/document-add.module').then((m) => m.DocumentAddPageModule)
  },
  {
    path: 'documents/:id/list',
    loadChildren: () =>
      import('./pages/documents-pages/full-screen-documents/full-screen-documents.module').then((m) => m.FullScreenDocumentsPageModule)
  },
  {
    path: 'personal-documents/:id/list',
    loadChildren: () =>
      import('./pages/documents-pages/full-screen-personal-documents/full-screen-personal-documents.module').then(
        (m) => m.FullScreenPersonalocumentsPageModule
      )
  },

  // Wall

  {
    path: 'wall/:id/list',
    loadChildren: () => import('./pages/wall-pages/full-screen-wall/full-screen-wall.module').then((m) => m.FullScreenWallPageModule)
  },

  // Embeddings

  {
    path: 'embeddings/:id/list',
    loadChildren: () =>
      import('./pages/embeddings-pages/full-screen-embeddings/full-screen-embeddings.module').then((m) => m.FullScreenEmbeddingsPageModule)
  },

  // Podcast

  {
    path: 'podcast/:id/list',
    loadChildren: () => import('./pages/podcast-pages/full-screen-podcast/full-screen-podcast.module').then((m) => m.FullScreenPodcastPageModule)
  },

  {
    path: 'podcast/episode',
    loadChildren: () => import('./pages/episode-player/episode-player.module').then((m) => m.EpisodePlayerPageModule)
  },

  {
    path: 'podcast/episode-player',
    loadChildren: () => import('./pages/episode-player-web/episode-player-web.module').then((m) => m.EpisodePlayerWebPageModule)
  },

  // Gallery
  {
    path: 'gallery/:idPage/add',
    loadChildren: () => import('./pages/gallery-pages/gallery-add-item/gallery-add-item.module').then((m) => m.GalleryAddItemPageModule)
  },
  {
    path: 'gallery/:id/edit',
    loadChildren: () => import('./pages/gallery-pages/gallery-add-item/gallery-add-item.module').then((m) => m.GalleryAddItemPageModule)
  },
  {
    path: 'gallery/:id/list',
    loadChildren: () => import('./pages/gallery-pages/full-screen-gallery/full-screen-gallery.module').then((m) => m.FullScreenGalleryPageModule)
  },

  // Generic container
  { path: 'generic/:id', loadChildren: () => import('./pages/generic-container/generic-container.module').then((m) => m.GenericContainerPageModule) },

  // Links
  {
    path: 'links/:id/list',
    loadChildren: () => import('./pages/links-pages/full-screen-links/full-screen-links.module').then((m) => m.FullScreenLinksPageModule)
  },

  // Login
  {
    path: 'login/first',
    loadChildren: () => import('./pages/login-pages/login-step-one/login-step-one.module').then((m) => m.LoginStepOnePageModule)
  },
  {
    path: 'login/second',
    loadChildren: () => import('./pages/login-pages/login-step-two/login-step-two.module').then((m) => m.LoginStepTwoPageModule)
  },
  {
    path: 'login/third',
    loadChildren: () => import('./pages/login-pages/login-step-three/login-step-three.module').then((m) => m.LoginStepThreePageModule)
  },
  {
    path: 'login/web',
    loadChildren: () => import('./pages/login-pages/login-web/login-web.module').then((m) => m.LoginWebPageModule)
  },

  { path: 'register', loadChildren: () => import('./pages/register/register.module').then((m) => m.RegisterPageModule) },

  // Rescue password
  {
    path: 'rescue',
    loadChildren: () => import('./pages/login-pages/login-rescue-password/login-rescue-password.module').then((m) => m.LoginRescuePasswordPageModule)
  },
  {
    path: 'rescue/confirm',
    loadChildren: () =>
      import('./pages/login-pages/login-confirm-rescue-password/login-confirm-rescue-password.module').then(
        (m) => m.LoginConfirmRescuePasswordPageModule
      )
  },
  //Reset Password
  {
    path: 'login/reset',
    loadChildren: () => import('./pages/login-pages/login-reset-password/login-reset-password.module').then((m) => m.LoginResetPasswordPageModule)
  },

  // No network
  {
    path: 'no-network',
    loadChildren: () => import('./pages/not-network-connection/not-network-connection.module').then((m) => m.NotNetworkConnectionPageModule)
  },

  {
    path: 'not-found',
    loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundPageModule)
  },

  // Notify
  { path: 'notifications', loadChildren: () => import('./pages/notify/notify.module').then((m) => m.NotifyPageModule) },

  // Profile
  { path: 'profile', loadChildren: () => import('./pages/profile-pages/profile/profile.module').then((m) => m.ProfilePageModule) },
  { path: 'profile/edit', loadChildren: () => import('./pages/profile-pages/edit-profile/edit-profile.module').then((m) => m.EditProfilePageModule) },

  // Survey
  {
    path: 'survey/:id/list',
    loadChildren: () => import('./pages/survey-pages/full-screen-survey/full-screen-survey.module').then((m) => m.FullScreenSurveyPageModule)
  },
  {
    path: 'web-frame/view',
    loadChildren: () => import('./pages/web-frame/web-frame.module').then((m) => m.WebFramePageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
