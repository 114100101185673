import { Component } from '@angular/core';

@Component({
  selector: 'empty-view',
  templateUrl: 'empty.html',
  styleUrls: ['empty.scss']
})
export class EmptyComponent {
  constructor() {}
}
