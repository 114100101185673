// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host ion-item.item {
  border-bottom: 1px solid var(--ion-color-light);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 8px;
  --inner-padding-end: 5px;
}
:host ion-item.item [slot=start] {
  background-color: var(--cms-component-header-icon-color);
  color: #fff;
  width: 35px;
  height: 35px;
  font-size: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  margin: 0px 16px 0px 0;
}
:host ion-item.item [slot=end],
:host ion-item.item [item-end] {
  color: var(--ion-color-dark);
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  border-radius: 0px;
}
:host ion-item.item ion-buttons ion-button {
  --background: unset;
  color: var(--cms-button-bg-color);
  font-size: 100%;
  margin: 0;
}

:host-context(.plt-desktop) ion-item {
  cursor: pointer;
  transition: all ease 0.3s;
}
:host-context(.plt-desktop) ion-item:hover ion-label {
  --color: var(--cms-link-text-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/personal-documents-item/personal-documents-item-component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AACE;EACE,+CAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,wBAAA;AACJ;AACI;EACE,wDAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,sBAAA;AACN;AAEI;;EAEE,4BAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AAAN;AAIM;EACE,mBAAA;EACA,iCAAA;EACA,eAAA;EACA,SAAA;AAFR;;AAUE;EACE,eAAA;EACA,yBAAA;AAPJ;AASM;EACE,8CAAA;AAPR","sourcesContent":[":host {\n  display: block;\n\n  ion-item.item {\n    border-bottom: 1px solid var(--ion-color-light);\n    padding-top: 8px;\n    padding-bottom: 8px;\n    padding-right: 8px;\n    --inner-padding-end: 5px;\n\n    [slot='start'] {\n      background-color: var(--cms-component-header-icon-color);\n      color: #fff;\n      width: 35px;\n      height: 35px;\n      font-size: 1.5rem;\n      display: inline-flex;\n      align-items: center;\n      justify-content: center;\n      border-radius: 3px;\n      margin: 0px 16px 0px 0;\n    }\n\n    [slot='end'],\n    [item-end] {\n      color: var(--ion-color-dark);\n      margin-top: 0px;\n      margin-right: 0px;\n      margin-bottom: 0px;\n      border-radius: 0px;\n    }\n\n    ion-buttons{\n      ion-button{\n        --background: unset;\n        color: var(--cms-button-bg-color);\n        font-size: 100%;\n        margin: 0;\n \n      }\n    }\n  }\n}\n\n:host-context(.plt-desktop) {\n  ion-item {\n    cursor: pointer;\n    transition: all ease 0.3s;\n    &:hover {\n      ion-label {\n        --color: var(--cms-link-text-color) !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
