// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .list-view ion-item.item {
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  --padding-start: 1rem;
  --padding-end: 1rem;
  --padding-top: 0.5rem;
  --padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--ion-color-light);
}

:host-context(.plt-desktop) ion-item {
  cursor: pointer;
  transition: all ease 0.3s;
}
:host-context(.plt-desktop) ion-item:hover ion-label h3 {
  color: var(--cms-link-text-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/agenda/agenda.scss"],"names":[],"mappings":"AAEI;EACE,0BAAA;EACA,wBAAA;EACA,qBAAA;EACA,mBAAA;EACA,qBAAA;EACA,wBAAA;EACA,+CAAA;AADN;;AAOE;EACE,eAAA;EACA,yBAAA;AAJJ;AAOQ;EACE,4CAAA;AALV","sourcesContent":[":host {\n  .list-view {\n    ion-item.item {\n      --inner-padding-start: 0px;\n      --inner-padding-end: 0px;\n      --padding-start: 1rem;\n      --padding-end: 1rem;\n      --padding-top: 0.5rem;\n      --padding-bottom: 0.5rem;\n      border-bottom: 1px solid var(--ion-color-light);\n    }\n  }\n}\n\n:host-context(.plt-desktop) {\n  ion-item {\n    cursor: pointer;\n    transition: all ease 0.3s;\n    &:hover {\n      ion-label {\n        h3 {\n          color: var(--cms-link-text-color) !important;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
