// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
.links-container-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.links-container-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.links-container-grid-cols-2, .links-container-grid-cols-3 {
  display: grid;
  padding: 10px;
}
.links-container-grid-cols-2 .child, .links-container-grid-cols-3 .child {
  padding: 25px;
}
.links-container-grid-cols-2 .child i, .links-container-grid-cols-3 .child i {
  margin-right: 5px;
}
.links-container-grid-cols-2 .child ion-label, .links-container-grid-cols-3 .child ion-label {
  font-weight: bold;
  font-size: 0.7em;
}

::ng-deep image-loader.grid-image img {
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/links/links.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAKA;EACE,gDAAA;AAHF;;AAKA;EACE,gDAAA;AAFF;;AAKA;EACE,aAAA;EACA,aAAA;AAFF;AAGE;EACI,aAAA;AADN;AAEM;EACE,iBAAA;AAAR;AAEM;EACE,iBAAA;EACA,gBAAA;AAAR;;AAOE;EACE,iBAAA;EACA,iBAAA;EACA,kBAAA;AAJJ","sourcesContent":[":host {\n  display: block;\n\n  .list-view {\n  }\n}\n\n.links-container-grid-cols-3 {\n  grid-template-columns: repeat(3, minmax(0, 1fr));\n}\n.links-container-grid-cols-2 {\n  grid-template-columns: repeat(2, minmax(0, 1fr));\n}\n\n.links-container-grid-cols-2, .links-container-grid-cols-3 {\n  display: grid;\n  padding: 10px;\n  .child{\n      padding: 25px;\n      i{\n        margin-right: 5px;\n      }\n      ion-label{\n        font-weight: bold;\n        font-size: 0.7em;\n      }\n      \n  }\n}\n\n::ng-deep image-loader.grid-image{\n  img{\n    aspect-ratio: 1 / 1;\n    object-fit: cover;\n    border-radius: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
