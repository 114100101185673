import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { HostComponentDirective } from 'src/app/directives/host-component.directive';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { CheckComponentsService } from 'src/app/services/check-components.service';
import { EventsService } from 'src/app/services/events.service';
import { InitialConfigService } from 'src/app/services/inital-config.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'page-generic-container',
  templateUrl: 'generic-container.html'
})
export class GenericContainerPage implements OnInit {
  pageParams: any = {};
  @ViewChild(HostComponentDirective) hostComponent: HostComponentDirective;

  public isEmpty: boolean = false;
  private idPage: any;

  constructor(
    public initialConfigProvider: InitialConfigService,
    private loading: LoadingService,
    private checkComponent: CheckComponentsService,
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute,
    private events: EventsService
  ) {}

  ngOnInit() {
    this.idPage = this.route.snapshot.paramMap.get('id');

    this.getComponentsPage();
  }

  public refresh($event) {
    this.getComponentsPage($event);
  }

  private async getComponentsPage($event?) {
    await this.loading.show();
    this.initialConfigProvider
      .getPageComponents(this.idPage)
      .pipe(finalize(async () => await this.loading.hide()))
      .subscribe({
        next: (response) => {
          this.pageParams = response;
          this.isEmpty = this.checkComponent.checkEmptyComponent(this.pageParams.components);
          this.setContentComponents();

          this.events.pages$$.next(response.title);

          this.registerAnalyticsInFirebase(response.title, response.id);

          if ($event && typeof $event.target.complete === 'function') $event.target.complete();
        },
        error: (error) => console.error(error)
      });
  }
  private registerAnalyticsInFirebase(namePage, idPage) {
    const paramsToAnalytics = { navigation: 0 };
    this.analyticsService.registerEvent(paramsToAnalytics, namePage, idPage);
  }

  private async setContentComponents() {
    const viewContainerRef = this.hostComponent.viewRef;
    viewContainerRef.clear();
    this.initialConfigProvider.getComponents(this.pageParams.components, viewContainerRef);
  }
}
