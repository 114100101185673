import { Component, EventEmitter, Input, NgZone, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DocumentService } from '../../services/document.service';
import { MenuController } from '@ionic/angular';
import { LoadingService } from '../../services/loading.service';
import { MEDIA_STATUS } from '@awesome-cordova-plugins/media/ngx';
import { BehaviorSubject, combineLatest, from, interval, merge, Observable } from 'rxjs';
import { shareReplay, map, switchMap, catchError, tap } from 'rxjs/operators';
import { ToastService } from 'src/app/services/toast.service';
import { Router } from '@angular/router';
import { PlatformService } from 'src/app/services/platform.service';

@Component({
  selector: 'episode',
  templateUrl: 'episode.html',
  styleUrls: ['episode.scss']
})
export class EpisodeComponent implements OnInit {
  @Input() idPage;
  @Input() episode;
  @Input() spaceBetweenElements;

  constructor(
    private router: Router,
    private platformService: PlatformService
  ) {

  }

  ngOnInit() {

  }

  toEpisode(episode) {
    if (this.platformService.isWeb()) {
      this.router.navigate([`/podcast/episode-player`], { queryParams: { componentId: this.idPage, episodeId: episode.id } });
      return  
    }
    this.router.navigate([`/podcast/episode`], { queryParams: { componentId: this.idPage, episodeId: episode.id } });
  }
}
