import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'relativeFormat',
  pure: false
})
@Injectable({ providedIn: 'root' })
export class RelativeTimePipe implements PipeTransform {
  // Show moment.js dateFormat for time elapsed.
  transform(date: any, outputFormat: string, inputFormat?: string): any {
    outputFormat = outputFormat ? outputFormat : 'llll';
    if (inputFormat){
      return moment.utc(date, inputFormat).local().fromNow();
    }
    return moment.utc(date).local().fromNow();
  }
}
