import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ContactsService } from 'src/app/services/contacts.service';
import { GalleryService } from 'src/app/services/gallery.service';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { LinksService } from 'src/app/services/links.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'page-detail-contacts',
  templateUrl: 'detail-contacts.html',
  styleUrls: ['detail-contacts.scss']
})
export class DetailContactsPage implements OnInit {
  public id: any;
  public agenda;
  public pageId: any;
  public contact$: Observable<any>;
  constructor(
    public router: Router,
    private linkProvider: LinksService,
    private toastController: ToastService,
    private loading: LoadingService,
    private route: ActivatedRoute,
    private contactService: ContactsService,
    public gConst: GlobalConstService,
    public galleryService: GalleryService
  ) {}

  ngOnInit() {
    this.id = +this.route.snapshot.paramMap.get('id');
    this.agenda = this.route.snapshot.paramMap.get('agenda');

    this.contact$ = this.agenda ? this.contactService.getUser(this.id) : this.contactService.getContact(this.id);
  }

  public openWebLink(url) {
    this.linkProvider.openInternalLink(url);
  }

  public goGroupChat(userData, group): void {
    this.router.navigate([`chat/single/${group.token}`], { state: { groupId: group.token, userData: userData, fireBaseGroup: '', userRooms: '' } });
  }

  public async initConversation(event, contact) {
    await this.loading.show();
    event.preventDefault();
    this.linkProvider
      .initConversation(contact.id)
      .pipe(finalize(async () => await this.loading.hide()))
      .subscribe({
        next: (res) => this.goGroupChat(contact, res.items.itemList[0]),
        error: (err) => {
          this.toastController.info('FAILED-TO-START-CHAT');
        }
      });
  }

  public viewImage(url: string) {
    this.galleryService.showViewer(url);
  }
}
