import { Component } from '@angular/core';
import { DetailContactsPage } from '../../pages/contacts-pages/detail-contacts/detail-contacts.page';
import { FullScreenContactsComponentPage } from '../../pages/contacts-pages/full-screen-contacts-component/full-screen-contacts-component.page';
import { FullScreenDirectoryPage } from '../../pages/contacts-pages/full-screen-directory/full-screen-directory.page';
import { NavController } from '@ionic/angular';
import { CheckComponentsService } from 'src/app/services/check-components.service';
import { AgendaService } from 'src/app/services/agenda.service';
import { Filters } from 'src/app/models/filters.model';
import { Router } from '@angular/router';

@Component({
  selector: 'agenda',
  templateUrl: 'agenda.html',
  styleUrls: ['agenda.scss']
})
export class AgendaComponent {
  public contacts = [];
  public initialConfigAgenda;
  public showHeader: boolean;
  public itemsFiltered: number;
  public itemsCount: number;

  constructor(private agendaProvider: AgendaService, private router: Router, private checkComponent: CheckComponentsService) {
    this.initialConfigAgenda = this.agendaProvider.initConfigAgendas;
    this.showHeader = this.initialConfigAgenda.view.showHeader;
    this.getContacts();
  }
  getContacts() {
    const filters: Filters = {
      searchKey: '',
      start: 0,
      orderBy: this.initialConfigAgenda.view.orderBy,
      orderDir: this.initialConfigAgenda.view.orderDir
    };

    this.agendaProvider.getUsers(this.initialConfigAgenda.id, filters).subscribe({
      next: (response) => {
        this.contacts = response.items.itemList;
        this.itemsCount = response.items.itemsCount;
        this.itemsFiltered = response.items.itemsFiltered;
        this.contacts = this.checkComponent.setItemsInComponent(this.contacts, this.itemsCount);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  public goToFullScreenAgenda() {
    this.router.navigate([`/contacts/${this.initialConfigAgenda.id}/list/agenda`]);
  }

  public isButtonView() {
    return this.initialConfigAgenda.view.mode === 'button';
  }

  public goToFullScreenContacts() {
    this.router.navigate([`/directory`]);
  }

  public goToDetail(contact) {
    this.router.navigate([`/contacts/detail/${contact.id}/agenda`]);
  }

  public isListView() {
    return this.initialConfigAgenda.view.mode === 'item-list';
  }

  public isVieMoreButtonVisible() {
    if (this.contacts) return this.itemsCount < this.itemsFiltered;
    return false;
  }
}
