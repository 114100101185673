import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { catchError, concatMap, shareReplay, tap } from 'rxjs/operators';
import { Filters } from 'src/app/models/filters.model';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { DocumentService } from 'src/app/services/document.service';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SearcherService } from 'src/app/services/searcher.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'page-documents-section',
  templateUrl: 'documents-section.html'
})
export class DocumentsSectionPage implements OnInit {
  public itemType: string = 'document';
  public filters$: BehaviorSubject<Filters> = new BehaviorSubject({
    searchKey: '',
    start: 0,
    length: 20,
    orderBy: 'name',
    orderDir: 'asc'
  });

  private $event;

  page$;
  public forbidden: boolean = false;

  constructor(
    public docsProvider: DocumentService,
    private analyticsService: AnalyticsService,
    private searcherService: SearcherService
  ) {}

  ngOnInit() {
    this.page$ = this.filters$.pipe(
      concatMap((filters) =>
        this.searcherService.allItems(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/documents`, filters, this.itemType)
      ),
      tap(() => this.$event && this.$event.target.complete && this.$event.target.complete()),
      tap(() => (this.forbidden = false)),
      catchError((err: any) => {
        if (err.status === 403) {
          this.forbidden = true;
        }

        throw err;
      }),
      shareReplay()
    );
    this.registerPageInAnalytics();
  }

  public getAllItems({ filters, event = null }) {
    if (event) this.$event = event;
    this.filters$.next(filters);
  }

  public getDoc($even) {
    const doc = $even.item;
    this.docsProvider.getDocumentAndDownload(doc.component_id, doc.id, true);
  }

  registerPageInAnalytics() {
    const paramsToAnalytics = { navigation: 1 };
    const name = 'documents section';
    this.analyticsService.registerEvent(paramsToAnalytics, name);
  }
}
