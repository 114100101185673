import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
//Pages
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

import { finalize } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { FileService } from 'src/app/services/file.service';

import { LoadingService } from 'src/app/services/loading.service';
import { PlatformService } from 'src/app/services/platform.service';

import { SurveyService } from 'src/app/services/survey.service';

@Component({
  selector: 'survey',
  templateUrl: 'survey.html',
  styleUrls: ['survey.scss']
})
export class SurveyComponent implements OnInit {
  @ViewChildren('fileImage') fileImages: QueryList<ElementRef>;

  surveyForm: UntypedFormGroup;
  public isFormLoaded = false;
  public canAnswer: boolean = false;
  public completedForm: boolean = false;
  public confirmationMessage: string = '';
  public surveyIniConfig;
  public isShowHeader: boolean;
  public surveyFormDataResponse: any;
  public isFormInvalid: boolean = false;
  public ErrorColor = false;
  public survey;

  public webImage$;
  public showImageContainer = false;

  public matchingImages$: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    public navCtrl: NavController,
    private loading: LoadingService,
    public surveyProvider: SurveyService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private fileService: FileService,
    private platformService: PlatformService
  ) {
    this.surveyForm = new UntypedFormGroup({});
    this.surveyIniConfig = this.surveyProvider.surveyInitConfig;
    this.isShowHeader = this.surveyIniConfig.view.showHeader;
  }

  ngOnInit() {
    this.getSurveyContent();
  }

  private getSurveyContent() {
    this.surveyProvider.getSurvey(this.surveyIniConfig.id).subscribe({
      next: (response) => {
        this.survey = response;
        this.canAnswer = !response.answered || (response.multipleResponses && response.answered);
        this.confirmationMessage = response.confirmationMessage;
        this.surveyFormDataResponse = response.items.itemList;
        this.surveyForm = this.createForm(this.surveyFormDataResponse);
        this.isFormLoaded = true;
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  public async onSubmit() {
    if (!this.surveyForm.valid) {
      this.checkFormKeys();
      this.isFormInvalid = true;
      return;
    }
    this.isFormInvalid = false;
    const userResponse = await this.surveyProvider.prepareUserResponse(this.surveyForm.value, this.surveyFormDataResponse);
    this.submit(userResponse);
  }

  public getTitleQuestion(option) {
    return Object.keys(option)[0];
  }

  public goToFullSurvey() {
    this.router.navigate([`survey/${this.surveyIniConfig.id}/list`]);
  }

  public isError(question) {
    const key = question.question;
    let status = this.surveyForm.controls[key].invalid;
    return this.isFormInvalid && status;
  }

  public isButtonView() {
    return this.surveyIniConfig.view.mode === 'button';
  }

  public isFullScreenView() {
    return this.surveyIniConfig.view.mode === 'item-list' && this.isFormLoaded;
  }

  private createForm(surveyFormDataResponse) {
    let group: any = {};
    surveyFormDataResponse.forEach((question) => {
      question.response = [];
      let validators: any = Validators.maxLength(question.maxLength);
      if (question.mandatory) {
        validators = [Validators.required, Validators.maxLength(question.maxLength)];
      }

      group[question.question] = new UntypedFormControl(null, validators);
    });

    return new UntypedFormGroup(group);
  }
  public setOptionValue(option) {
    let key = Object.keys(option);
    let value = option[key[0]];
    return value;
  }

  private async submit(responses) {
    await this.loading.show();
    console.log('submit: ', responses);

    this.surveyProvider
      .sendSurvey(this.surveyIniConfig.id, responses)
      .pipe(finalize(async () => await this.loading.hide()))
      .subscribe({
        next: async (res) => {
          this.completedForm = true;
          this.canAnswer = !res.answered || (res.multipleResponses && res.answered);
          this.confirmationMessage = res.confirmationMessage;

          if (this.survey.multipleResponses === true) {
            this.surveyForm.reset();
            this.surveyProvider.images$.next([]);
            this.matchingImages$.next([]);
          }

          await this.loading.hide();
          this.registerEventInAnalytics();
        },
        error: async (err) => {
          await this.loading.hide();
        }
      });
  }

  registerEventInAnalytics() {
    const paramsToAnalytics = { actions: 2 };
    const name = this.surveyIniConfig.title;
    const id = this.surveyIniConfig.id;
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
  }

  private checkFormKeys() {
    let keys = Object.keys(this.surveyForm.controls);
    keys.forEach((key) => {
      this.surveyForm.controls[key].updateValueAndValidity();
    });
  }

  public radioGroupChange($event, question) {
    this.surveyForm.patchValue({ [question]: $event.detail.value });
  }

  public checkboxChange($event, question, option) {
    const selected = Object.keys(option)[0];

    let value = [];

    if (this.surveyForm.value[question] !== null) {
      value = [...this.surveyForm.value[question]];
    }

    if ($event.detail.checked) {
      value = [...new Set([...value, selected])];
    } else {
      value = [...new Set([...value.filter((value) => value !== selected)])];
    }

    this.surveyForm.patchValue({ [question]: value });
  }

  /**
   * function to adjust the height of the message textarea
   * @param {any} event - the event, which is provided by the textarea input
   * @return {void}
   */
  public adjustTextarea(event: any): void {
    let textarea: any = event.target;
    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
    return;
  }

  public async uploadCameraPictureWeb(event, form, question) {
    const fileInput = this.fileImages.find((fileInput) => fileInput.nativeElement.id === `fileImage_${question.id}`);
    this.surveyProvider.uploadCameraPictureWeb(event, form, question, fileInput);
  }

  public async selectPicture(question) {
    const fileInput = this.fileImages.find((fileInput) => fileInput.nativeElement.id === `fileImage_${question.id}`);

    if (fileInput) {
      await this.surveyProvider.selectPicture(fileInput, question);
      await this.loadMatchingImage(question);
    }
  }

  async loadMatchingImage(question) {
    const images = this.surveyProvider.images$.value;


    if (images && images.length > 0) {
      const matchingImage = images.find((image) => image.questionId === question.id);

      if (matchingImage) {
        let base64Image;

        if (this.platformService.isWeb()) {
          console.log('enter web');
          base64Image = await this.fileService.getImageBase64(matchingImage.image.path || matchingImage.image.webPath);
        }else {
          console.log('enter mobile');
          const base64String = await this.fileService.convertFilePathToBase64(matchingImage.image.path || matchingImage.image.webPath);
          base64Image = `data:image/${matchingImage.image.format};base64,${base64String}`;
        }

        this.matchingImages$.next(
          this.matchingImages$.getValue().concat({
            question: question.id,
            image: base64Image
          })
        );

        return;
      }
    }
    return './assets/imgs/photo_placeholder.svg'; // Return null si no se encuentra ninguna imagen coincidente
  }

  getImageSrc(questionId: string) {
    const images = this.matchingImages$.value;
    const matchingImage = images.find((image) => image.question === questionId);
    if (!matchingImage) {
      return './assets/imgs/photo_placeholder.svg';
    }
    return matchingImage.image;
  }
}
