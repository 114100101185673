import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { InAppBrowser, InAppBrowserEventType } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { NavController, Platform } from '@ionic/angular';
import { finalize, take, tap } from 'rxjs/operators';
import { introStyleConfig } from 'src/app/models/introStyleModel.model';
import { ClientService } from 'src/app/services/client.service';
import { EventsService } from 'src/app/services/events.service';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { HandlerErrorService } from 'src/app/services/handler-error.service';
import { InitialConfigService } from 'src/app/services/inital-config.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LoginService } from 'src/app/services/login.service';
import { NetworkService } from 'src/app/services/network.service';
import { ToastService } from 'src/app/services/toast.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'page-login-step-one',
  templateUrl: 'login-step-one.html',
  styleUrls: ['login-step-one.scss']
})
export class LoginStepOnePage implements OnInit {
  public loginType: string;
  public introStyleConfig: introStyleConfig;
  public logo: string;
  public bgColor: string;
  public bgImage: string;
  public welcomeMessage: any;
  public isErrorInMail: boolean;
  public formEmpty: boolean;
  public errorMessage = null;
  private loginErrors: any;
  public showingPassword = false;
  public usernameIncorrect: { isUsernameIncorrect: boolean; textError: string } = { isUsernameIncorrect: false, textError: '' };
  public openedApp = false;
  public currentLang: string;
  public termsMandatory = false;
  public register = Environment.REGISTER;

  private defaultIntroStyleConfig = {
    logo: '',
    bgColor: '',
    bgImage: '',
    welcomeMessage: ''
  };

  public oauthLogin = 'OAUTH_LOGIN';
  public oneStepLogin = 'ONE_STEP_LOGIN';
  public threeStepLogin = 'THREE_STEPS_LOGIN';
  public oauthOneStepLogin = 'OAUTH_ONE_STEP_LOGIN';

  public loginFormThreeSteps = new UntypedFormGroup({
    username: new UntypedFormControl({ value: '', disabled: false }, Validators.required)
  });

  public loginOauthAndOneStepLogin = new UntypedFormGroup({
    username: new UntypedFormControl({ value: '', disabled: false }, Validators.required)
  });

  public loginFormOneStep = new FormGroup({
    username: new FormControl({ value: null, disabled: false }, Validators.required),
    password: new FormControl({ value: null, disabled: false }, Validators.required)
  });

  public termsForm: FormGroup = new FormGroup({
    terms: new FormControl(false)
  });

  constructor(
    public globalConst: GlobalConstService,
    private router: Router,
    private initialConfig: InitialConfigService,
    private loginService: LoginService,
    private loading: LoadingService,
    private handError: HandlerErrorService,
    public network: NetworkService,
    public clientService: ClientService,
    private iab: InAppBrowser,
    private events: EventsService,
    private platform: Platform,
    private toast: ToastService,
    private navCtrl: NavController,
    private languageService: LanguageService
  ) {
    this.loginErrors = this.handError.loginErrors;
  }

  ngOnInit() {
    const availableLogins = Environment.AVAILABLE_LOGINS;
    this.updateTermsFormValidity();
    this.loading.hide();

    if (Environment.CLIENT_ID !== null) {
      const styles = this.clientService.rescueAppStyleSheet();

      if (styles) {
        this.setStyles(styles);
      }

      if (availableLogins.includes(this.oneStepLogin)) {
        this.loginType = this.oneStepLogin;
      }

      if (availableLogins.includes(this.oauthLogin)) {
        this.loginType = this.oauthLogin;
        this.currentLang = this.languageService.langs$$.value;
      }

      if (availableLogins.includes(this.oauthLogin) && availableLogins.includes(this.oneStepLogin)) {
        this.loginType = this.oauthOneStepLogin;
        this.currentLang = this.languageService.langs$$.value;
      }

      return;
    }

    if (availableLogins.includes(this.threeStepLogin)) {
      this.loginType = this.threeStepLogin;
    }
  }

  setStyles(styles) {

    this.introStyleConfig = this.initialConfig.styleSheet ? this.initialConfig.styleSheet.intro : this.defaultIntroStyleConfig;
    
    this.introStyleConfig.logo = styles.intro.logo;
    this.introStyleConfig.bgColor = styles.intro.bgColor;
    this.introStyleConfig.bgImage = styles.intro.bgImage;
    this.introStyleConfig.welcomeMessage = styles.intro.welcomeMessage;

    this.logo = this.introStyleConfig.logo;
    this.bgColor = this.introStyleConfig.bgColor;
    this.bgImage = this.introStyleConfig.bgImage;
    this.welcomeMessage = this.introStyleConfig.welcomeMessage;
  }

  private checkFormKeysSteps(loginForm) {
    const keys = Object.keys(loginForm['controls']);
    keys.forEach((key) => loginForm['controls'][key].updateValueAndValidity());
  }

  // COMBINED OAUTH AND ONE STEP LOGIN

  public onSubmitOauthAndOneSteps() {
    const usernameSubmit = this.loginOauthAndOneStepLogin.get('username').value;
    const clientId = Environment.CLIENT_ID;
    this.formEmpty = usernameSubmit ? false : true;

    if (!this.checkTermsFormValidity()) {
      return;
    }

    if (this.loginOauthAndOneStepLogin.valid) {
      this.sendLoginFormOauthAndOneSteps(usernameSubmit, clientId);
    } else {
      this.checkFormKeysSteps(this.loginOauthAndOneStepLogin);
    }
  }

  private async sendLoginFormOauthAndOneSteps(username: any, clientId) {
    await this.loading.show();
    this.loginService
      .getUserLoginType(username, clientId)
      .pipe(finalize(() => this.loading.hide()))
      .subscribe({
        next: (response) => this.loginTypeHandler(username, clientId, response),
        error: (error) => this.errorHandler(error)
      });
  }

  private async loginTypeHandler(username, clientId, loginType) {
    if (loginType === 'AZURE_AD') {
      this.openAzureOauth();
      return;
    }

    this.goLoginStepThree(username, clientId);
  }

  // END COMBINED OAUTH AND ONE STEP LOGIN

  // ONE STEP LOGIN

  public onSubmitOneStep() {
    const { username, password } = this.loginFormOneStep.value;
    this.formEmpty = username && password ? false : true;

    if (!this.checkTermsFormValidity()) {
      return;
    }

    if (this.loginFormOneStep.valid) {
      this.sendLoginFormOneStep(username, password);
    } else {
      this.checkFormKeysSteps(this.loginFormOneStep);
    }
  }

  private sendLoginFormOneStep(username: any, password: any) {
    this.loading.show();

    this.loginService.loginStepThree({ username, password, clientId: Environment.CLIENT_ID }).subscribe({
      next: async (userData) => {
        this.events.publish({ name: 'anonApp', value: 'expired' });
        if (userData.resetPasswordRequested === 'reset-password') {
          this.goToResetPassword(userData);
          return;
        }
        this.initialConfig.saveUserData({ ...userData, username: username });
        await this.getThemeApp();
        this.events.publish({ name: 'session', value: 'ready' });
        this.router.navigate(['home']);
      },
      error: async (err) => {
        this.loading.hide();
        if (err.status === 404) this.errorMessage = await this.languageService.translate('LOGIN-ERRORS-404');
        else if (err.status === 401) this.errorMessage = this.errorMessage = await this.languageService.translate('LOGIN-ERRORS-401');
        else if (err.status === 500) this.errorMessage = this.errorMessage = await this.languageService.translate('LOGIN-ERRORS-500');
        else if (err.status === 400) this.errorMessage = this.errorMessage = await this.languageService.translate('LOGIN-ERRORS-400');
        else this.errorMessage = 'Error';
      },
      complete: async () => {
        this.loading.hide();
      }
    });
  }

  // END ONE STEPS LOGIN

  // THREE STEPS LOGIN

  public onSubmitThreeSteps() {
    const usernameSubmit = this.loginFormThreeSteps.get('username').value;
    this.formEmpty = usernameSubmit ? false : true;

    if (!this.checkTermsFormValidity()) {
      return;
    }

    if (this.loginFormThreeSteps.valid) {
      this.sendLoginFormThreeSteps(usernameSubmit);
    } else {
      this.checkFormKeysSteps(this.loginFormThreeSteps);
    }
  }

  private async sendLoginFormThreeSteps(username: any) {
    await this.loading.show();
    this.loginService
      .loginStepOne(username)
      .pipe(finalize(() => this.loading.hide()))
      .subscribe({
        next: (response) => this.clientDataHandler(response, username),
        error: (error) => this.errorHandler(error)
      });
  }

  private async clientDataHandler(availableClients, username) {
    if (availableClients.length > 1) {
      this.goLoginStepTwo(availableClients, username);
      return;
    }

    const userData = availableClients[0];

    console.log(userData);
    
    this.initialConfig.saveUserData({ ...userData, username: username });
    await this.getThemeApp();
    this.goLoginStepThree(username, userData.id);
  }

  // END THREE STEPS LOGIN

  // OAUTH LOGIN

  public openAzureOauth() {
    if (!this.checkTermsFormValidity()) {
      return;
    }

    const url = this.loginService.getOAuthURL(Environment.AZURE_OAUTH_TENANT, Environment.AZURE_OAUTH_CLIENT_ID, Environment.CLIENT_ID);
    console.log('Entra a', url);
    const ref = this.iab.create(url, '_blank', { location: 'no', hidenavigationbuttons: 'yes' });

    const eventType: InAppBrowserEventType = 'loadstart';
    // attach listener to loadstart

    ref.on(eventType).subscribe((event) => {
      const urlSuccessPage = 'redirect/finish';
      if (!event.url.includes(urlSuccessPage)) {
        return;
      }
      ref.close();
      const token = event.url.split('sid=')[1];
      this.loginService.saveUserSession({ account: { sessionToken: token } });

      this.loginService
        .updateSession()
        .pipe(take(1))
        .subscribe({
          next: async (response) => {
            this.initialConfig.saveUserData(response);
            this.loginService.saveUserSession(response);
            this.globalConst.userData = response;
            await this.loginService.loginWithToken(response.account.firebaseToken);
            await this.getThemeApp();
            this.initialConfig.rootPageComponents = response.rootPageComponents;
            this.initialConfig.styleSheet = response.client.style;
            this.events.publish({ name: 'session', value: 'ready' });

            if (!this.platform.is('desktop')) {
              this.router.navigate(['home']);
            }
          },
          error: (err) => {
            console.log('error en login');
            
            this.loading.hide();
          }
        });
    });
  }

  // END OAUTH LOGIN

  private getThemeApp() {
    let cssStyleSheet = this.initialConfig.styleSheet;
    if (!cssStyleSheet) {
      return;
    }
    this.clientService.saveAppTheme(cssStyleSheet);
    this.clientService.setThemeApplication(cssStyleSheet);
  }

  public goLoginStepThree(userData?, clientId?) {
    if (userData) {
      console.log(userData, clientId);
      
      this.router.navigate(['login/third/'], { state: { username: userData, id: clientId} });
      this.loading.hide();
      return;
    }
    this.router.navigate(['login/third/']);
    this.loading.hide();
  }

  public goLoginStepTwo(data, username) {
    this.router.navigate(['login/second/'], { state: { data, username } });
    this.loading.hide();
  }

  public gotToChangePassword() {
    this.router.navigate(['rescue'], {
      state: {
        userData: {
          username: Environment.CLIENT_ID === null ? this.loginFormThreeSteps.value.username : this.loginFormOneStep.value.username,
          password: Environment.CLIENT_ID === null ? this.loginFormThreeSteps.value.password : this.loginFormOneStep.value.password,
          clientId: Environment.CLIENT_ID
        }
      }
    });
  }

  public goToResetPassword(userData) {
    this.router.navigate(['/login/reset'], { state: { userData } });
    return;
  }

  private async errorHandler(error: any) {
    this.usernameIncorrect.isUsernameIncorrect = true;
    this.usernameIncorrect.textError = 'Usuario no encotrado';
    await this.loading.hide();
  }

  public updateTermsFormValidity() {
    this.termsMandatory = Environment.LOGIN_TERMS;
    if (this.termsMandatory) {
      this.termsForm.controls.terms.addValidators(Validators.required);
      this.termsForm.updateValueAndValidity();
    }

    return;
  }

  public checkTermsFormValidity() {
    if (this.termsMandatory) {
      if (this.termsForm.controls.terms.value === true) {
        return true;
      }

      if (this.termsForm.controls.terms.value === false) {
        this.toast.info('Debe aceptar los términos y condiciones para poder iniciar sesión');
        return false;
      }
    }
    return true;
  }

  public cancelLogin() {
    this.navCtrl.back();
  }

  public togglePassword(input) {
    this.showingPassword = !this.showingPassword;
    input.type = input.type === 'password' ? 'text' : 'password';
  }
}
