import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, IonContent, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { BehaviorSubject, from, Observable, Subscription, timer } from 'rxjs';
import { catchError, map, shareReplay, take, tap } from 'rxjs/operators';
import { LanguageService } from 'src/app/services/language.service';
import { ChatbotService } from '../../../entities/chatbot.service';
import { Message } from '../../../models/message.model';
import { AnalyticsService } from '../../../services/analytics.service';
import { CameraService } from '../../../services/camera.service';
import { GalleryService } from '../../../services/gallery.service';
import { FileService } from '../../../services/file.service';
import { LoadingService } from '../../../services/loading.service';
import { LoginService } from '../../../services/login.service';
import { MessagesService } from '../../../services/messages.service';
import { NetworkService } from '../../../services/network.service';
import { PlatformService } from '../../../services/platform.service';
import { PromptCameraService } from '../../../services/prompt-camera.service';
import { ToastService } from '../../../services/toast.service';
import { AlertButton, AlertOptions, ModalOptions } from '@ionic/core';
import { ChatMembersModalComponent } from 'src/app/components/chat-members-modal/chat-members-modal.component';
import { ContactsService } from 'src/app/services/contacts.service';
import { Datasource } from 'ngx-ui-scroll';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';
import { Clipboard } from '@capacitor/clipboard';
import { decode } from 'base64-arraybuffer';

@Component({
  selector: 'app-full-screen-chat',
  templateUrl: './full-screen-chat.page.html',
  styleUrls: ['./full-screen-chat.page.scss']
})
export class FullScreenChatPage {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('fileInput') fileInput: ElementRef;

  public title$: Observable<any>;
  public users$: Observable<any>;
  public room$: Observable<any>;

  public currentRoomToken: string;
  public currentUserId: any;
  public formMessage: UntypedFormGroup;

  public messages$$ = new BehaviorSubject([]);
  public messagesSubscription = new Subscription();

  public sendingMessage = false;

  private MESSAGE_TYPE_IMAGE = 1;
  private MESSAGE_TYPE_TEXT = 0;

  public dateLastMessage = 0;
  public isBot = false;
  public dataMessages: any[] = [];
  public dataSource = new Datasource({
    settings: {
      windowViewport: true,
      startIndex: 0,
      minIndex: 0
    },
    get: (index, count, success) => {
      // Check index equal to 0 otherwise it duplicates data.
      if (index === 0) success(this.dataMessages);
    }
  });
  constructor(
    private galleryService: GalleryService,
    public network: NetworkService,
    private cameraService: CameraService,
    private platformService: PlatformService,
    private messagesService: MessagesService,
    private route: ActivatedRoute,
    private fileService: FileService,
    public loginService: LoginService,
    private analyticsService: AnalyticsService,
    private formBuilder: UntypedFormBuilder,
    private toastController: ToastService,
    public router: Router,
    private chatbotService: ChatbotService,
    private loading: LoadingService,
    private promptCameraService: PromptCameraService,
    private languageService: LanguageService,
    private alertCtrl: AlertController,
    public modalCtrl: ModalController,
    private contactService: ContactsService
  ) {
    this.buildForm();
  }

  ionViewWillLeave() {
    if (this.messagesSubscription) this.messagesSubscription.unsubscribe();
  }

  async errorLoadData() {
    await this.loading.hide();
    this.toastController.info('FAILED-TO-START-CHAT');
    this.router.navigate(['/chat/list']);
  }

  async ionViewDidEnter() {
    this.messages$$ = new BehaviorSubject([]);
    await this.loading.show();

    this.currentRoomToken = this.route.snapshot.paramMap.get('id');
    this.currentUserId = this.loginService.rescueSessionDataUser().account.id;

    this.checkIsBot();

    this.room$ = this.messagesService.getRoomByToken(this.currentRoomToken);
    this.getMessages();

    this.users$ = this.room$.pipe(
      map((room: any) =>
        room.members.reduce((acc, user) => {
          acc[user.id] = this.contactService.getUser(user.id).pipe(shareReplay());
          return acc;
        }, {})
      ),
      shareReplay(),
      catchError((errors) => {
        this.errorLoadData();
        return from(['Error']);
      })
    );

    this.messagesService.updateMessagesUnread(this.currentRoomToken);
    this.resetForm();
    this.subscribeKeyBoardStatus();
  }

  private getMessages() {
    this.messagesSubscription = this.messagesService.getMessagesByRoomToken(this.currentRoomToken).subscribe({
      next: async (messages) => {
        if (this.messages$$.value.length == 0) {
          this.messages$$.next([...this.messages$$.value, ...messages]);
          await this.loading.hide();
          this.dataMessages = messages;
          await this.doUpdate();
          this.scrollBottom(1000);

          // Para que la primera vez lo oculte y permita seguir mandando
          if (messages.length === 1) {
            this.checkLastMessageSend(messages);
          }
          return;
        }

        if (this.isBot) {
          await this.addNewMessagesBot(messages);
          return;
        }
        await this.addNewMessages(messages);
      },
      error: (err) => {
        this.errorLoadData();
      }
    });
  }

  private async addNewMessagesBot(messages) {
    let oldMessagesDistinctDates = this.messages$$.value.map((x) => x.date);
    const newMessages: Message[] = messages.filter((newMessage) => !oldMessagesDistinctDates.includes(newMessage.date));
    this.checkLastMessageSend(newMessages);

    for (let newMessage of newMessages) {
      if (newMessage.sender == 'bot') await timer(2500).toPromise();
      this.addNewMessage(newMessage);
    }
  }

  private async addNewMessages(messages) {
    const currentMessagesDistinctDates = this.dataMessages.map((x) => x.date);
    const newMessages: Message[] = messages.filter((newMessage) => !currentMessagesDistinctDates.includes(newMessage.date));
    const newMessagesDistinctDates = newMessages.map((x) => x.date);
    const oldMessages = messages.filter((oldMessage) => !newMessagesDistinctDates.includes(oldMessage.date));
    const oldMessagesDates = oldMessages.map((x) => x.date);
    this.checkLastMessageSend(newMessages);

    const removeIdMessages = this.dataMessages.reduce(function (result, item, index) {
      if (!oldMessagesDates.includes(item.date)) {
        result.push(item.id);
      }
      return result;
    }, []);

    this.dataMessages = [...oldMessages, ...newMessages];
    if (removeIdMessages.length > 0) {
      await this.dataSource.adapter.remove({
        predicate: (data: any) => removeIdMessages.includes(data.data.id)
      });
    }
    if (newMessages.length > 0) {
      await this.dataSource.adapter?.append({
        items: newMessages
      });
    }

    this.scrollBottom(100);
  }

  async addNewMessage(message) {
    if (!this.messages$$.value.find((m) => m.id == message.id)) {
      this.messages$$.next([...this.messages$$.value, message]);
      this.scrollBottom(100);
    }
  }

  checkLastMessageSend(messages: Message[]) {
    if (messages.length === 1 || messages.find((m) => m.date == this.dateLastMessage)) {
      this.sendingMessage = false;
      this.dateLastMessage = 0;
    }
  }

  public scrollBottom(ms) {
    setTimeout(async () => {
      await this.content.scrollToBottom(300);
    }, ms);
  }

  public viewImage(url: string) {
    this.galleryService.showViewer(url);
  }

  //#region FOOTER
  public adjustTextarea(event: any): void {
    let textarea: any = event.target;
    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
    return;
  }

  // Send photo message either using Photo Gallery, or Camera.
  public async attach() {
    const result = await this.promptCameraService.ask();
    switch (result.action) {
      case this.promptCameraService.OPEN_INPUT_FILE:
        this.fileInput.nativeElement.click();
        break;
      case this.promptCameraService.REMOVE_IMAGE:
        break;
      default:
        if (result.image) {
          if (this.platformService.isWeb()) {
            const base64String = await this.fileService.getImageBase64(result.image.webPath);
            const imageBlob = this.fileService.base64ToBlob(base64String, `image/${result.image.format}`);
            this.upload(imageBlob);
            return;
          }

          const base64String = await this.fileService.convertFilePathToBase64(result.image.path || result.image.webPath);
          let base64Image = `data:image/${result.image.format};base64,${base64String}`;
          const imageBlob = this.fileService.base64ToBlob(base64Image, `image/${result.image.format}`);
          this.upload(imageBlob);
        }

        break;
    }
  }

  async uploadFromEvent($event) {
    const file = await this.fileService.generateFile($event.target.files[0]);
    this.upload(file.blob);
  }

  public async upload(file) {
    if (!this.checkNetworkIsOnline()) {
      return;
    }
    this.formMessage.patchValue({
      type: this.MESSAGE_TYPE_IMAGE,
      file: file
    });
    this.send();
  }

  public send() {
    if (this.sendingMessage) return;
    this.sendingMessage = true;
    if (!this.checkNetworkIsOnline()) {
      return;
    }
    const dateNow = moment();
    this.formMessage.patchValue({
      date: dateNow.format('X.SSS')
    });
    this.dateLastMessage = parseFloat(dateNow.format('XSSS'));

    if (this.isBot) {
      const onboardingId = this.currentRoomToken.split('-')[1];
      this.chatbotService.sendMessage(this.formMessage.get('message').value, onboardingId);
    } else {
      this.messagesService.sendMessage(this.formMessage.value);
    }
    this.resetForm();
  }

  private checkNetworkIsOnline() {
    if (!this.network.online()) {
      this.toastController.info('FAILED-TO-SEND-CHAT');
      return false;
    }

    return true;
  }

  //#endregion FOOTER

  //keyboard listener
  private async subscribeKeyBoardStatus() {
    if (this.platformService.isWeb()) return;

    Keyboard.addListener('keyboardDidShow', (info: KeyboardInfo) => {
      this.content.scrollToBottom(0);
    });

    Keyboard.addListener('keyboardDidHide', () => {
      this.content.scrollToBottom(0);
    });
  }

  @HostListener('click', ['$event'])
  onClick(e) {
    if (e.target.classList.contains('_system')) {
      const paramsToAnalytics = { actions: 7 };
      const href = e.target.href;
      this.analyticsService.registerEvent(paramsToAnalytics, href);
      window.open(href, '_system', 'location=yes');
      return false;
    }
  }

  private buildForm() {
    this.formMessage = this.formBuilder.group({
      roomToken: '',
      sender: '',
      type: [this.MESSAGE_TYPE_TEXT, Validators.required],
      message: ['', Validators.required],
      file: null,
      date: Date.now()
    });
  }

  private resetForm() {
    this.formMessage.patchValue({
      roomToken: this.currentRoomToken,
      sender: this.currentUserId,
      type: this.MESSAGE_TYPE_TEXT,
      message: '',
      file: null,
      date: Date.now()
    });
  }

  public trackMessage(index: number, message: any) {
    return message.date;
  }

  public checkIsBot() {
    this.isBot = false;
    if (this.currentRoomToken) {
      this.isBot = this.currentRoomToken.toUpperCase().includes('CHATBOT');
    }
  }

  public goToContact(contact) {
    this.router.navigate([`/contacts/detail/${contact.id}/agenda`]);
  }

  public goToPage(pageId) {
    this.router.navigate([`/generic/${pageId}`]);
  }

  public sendOptionMessage(message) {
    this.formMessage.patchValue({
      message
    });
    this.send();
  }

  async confirmDeleteMessage(message) {
    const alertButtons: AlertButton[] = [
      { text: await this.languageService.translate('CANCEL'), role: 'cancel' },
      { text: await this.languageService.translate('DELETE'), handler: () => this.deleteMessage(message) }
    ];

    const alertOptions: AlertOptions = {
      header: await this.languageService.translate('ARE-YOU-SURE-YOU-WANT-TO-DELETE-THIS-MESSAGE'),
      buttons: alertButtons
    };
    const alert = await this.alertCtrl.create(alertOptions);

    await alert.present();
  }

  public async deleteMessage(message) {
    this.messagesService.removeMessage(this.currentRoomToken, message.id);
    /*const indexRemove = this.dataMessages.findIndex(data => data.id === message.id);
    if (indexRemove >= 0) {
      this.dataMessages.splice(indexRemove, 1);
      await this.dataSource.adapter.remove({
        predicate: ({ $index }) => $index === indexRemove
      });
    }*/

    this.messages$$.next(
      this.messages$$.value.filter((m) => {
        return m.id !== message.id;
      })
    );
  }

  public copyToClipboard(message) {
    Clipboard.write({ string: message.message }).then(() => this.toastController.info('COPIED-TO-CLIPBOARD'));
  }

  public async showMembers() {
    const modalOptions: ModalOptions = {
      component: ChatMembersModalComponent,
      componentProps: {
        roomToken: this.currentRoomToken
      }
    };
    const modal = await this.modalCtrl.create(modalOptions);

    await modal.present();
  }

  public async showMember(room) {
    const participant = room.members.find((member) => member.id !== this.currentUserId);

    this.router.navigate([`contacts/detail/${participant.id}/agenda`]);
  }

  async doUpdate() {
    await this.dataSource.adapter?.reload();
  }
}
