import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'skipArchived',
  pure: false
})
@Injectable({ providedIn: 'root' })
export class SkipArchivedPipe implements PipeTransform {
  transform(chats: any[]): any {
    return chats.filter((x) => !x.archived);
  }
}
