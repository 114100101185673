import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  constructor(public loadingCtrl: LoadingController, private translate: TranslateService) { }

  public loading: { instance: HTMLIonLoadingElement; status: boolean } = { instance: null, status: false };
  isLoading = false;
  
  public async show(message?,) {
    if (!this.loading.instance) {
      this.loading.instance = await this.loadingCtrl.create(this.options(message));
    }

    if (this.loading.status === false) {
      await this.loading.instance.present();
    }

    this.loading.status = true;
  }

  public async hide() {
    if (this.loading.instance !== null && this.loading.status === true) {
      await this.loading.instance.dismiss().finally( ()=>{
        this.loading = { instance: null, status: false };
      });
      return
    }
    //this.loading.instance.dismiss();
    this.loadingCtrl.dismiss()
    return
  }

  public options(message?): LoadingOptions {
    return {
      spinner: 'crescent',
      message: message ? message : this.translate.instant('LOADING'),
      translucent: true
    };
  }
}
