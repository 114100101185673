import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Filters } from 'src/app/models/filters.model';
import { InitConfig } from 'src/app/models/initConfig.model';
//Pages
import { CheckComponentsService } from 'src/app/services/check-components.service';
import { EmbeddedService } from 'src/app/services/embedded.service';
import { HandlerErrorService } from 'src/app/services/handler-error.service';

@Component({
  selector: 'embedded',
  templateUrl: 'embedded.component.html',
  styleUrls: ['embedded.component.scss']
})
export class EmbeddedComponent {
  public frames: Array<File> = [];
  public embeddingsInitConfig: InitConfig;
  public showHeaders: boolean;
  public isEmpty: boolean = false;
  public showViewMoreBtn: boolean = false;

  public errorInComponentEmbedded: { haveError: boolean; textError: string } = { haveError: false, textError: '' };

  constructor(
    private router: Router,
    private handleError: HandlerErrorService,
    private embeddedService: EmbeddedService,
    private checkComponent: CheckComponentsService
  ) {
    this.embeddingsInitConfig = embeddedService.initConfigEmbedded;
    this.showHeaders = this.embeddingsInitConfig.view.showHeader;
    this.getPersonalEmbedded();
  }

  private getPersonalEmbedded() {
    const filters: Filters = {
      searchKey: '',
      start: 0,
      orderBy: this.embeddingsInitConfig.view.orderBy,
      orderDir: this.embeddingsInitConfig.view.orderDir,
      length: this.embeddingsInitConfig.view.maxNumItems
    };

    this.embeddedService.getEmbeddeds(this.embeddingsInitConfig.id, filters).subscribe({
      next: (response: InitConfig) => {
        this.showViewMoreBtn = response.items.itemsCount > response.items.itemsFiltered;
        this.embeddingsInitConfig.items = response.items;
        this.frames = this.checkComponent.setItemsInComponent(response.items.itemList, response.items.itemsCount);
        this.isEmpty = this.checkComponent.checkEmptyComponent(this.frames);
      },
      error: (error) => {
        console.error('Error getting embedded', error);
        this.errorHandler(error);
      }
    });
  }

  private errorHandler(error) {
    this.errorInComponentEmbedded = this.handleError.haveErrorInComponent(error);
  }

  public goToFullEmbedded() {
    this.router.navigate([`embeddings/${this.embeddingsInitConfig.id}/list`]);
  }
}
