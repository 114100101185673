import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, concatMap, shareReplay, tap } from 'rxjs/operators';
import { Filters } from 'src/app/models/filters.model';
import { Media } from 'src/app/models/media.model';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { EventsService } from 'src/app/services/events.service';
import { GalleryService } from 'src/app/services/gallery.service';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { SearcherService } from 'src/app/services/searcher.service';
import { Environment } from 'src/environments/environment';
// Components
import { GalleryModalComponent } from '../../../components/gallery/gallery-modal/gallery-modal';


// Models

@Component({
  selector: 'page-full-screen-gallery',
  templateUrl: 'full-screen-gallery.html',
  styleUrls: ['full-screen-gallery.scss']
})
export class FullScreenGalleryPage {
  public itemType: string = 'image';

  public filters$: BehaviorSubject<Filters> = new BehaviorSubject({
    searchKey: '',
    start: 0,
    length: 20
  });

  pageId;
  public page$: Observable<any>;

  private $event;
  public forbidden: boolean = false;

  constructor(
    public galleryProvider: GalleryService,
    public router: Router,
    private route: ActivatedRoute,
    public modalCtrl: ModalController,
    private events: EventsService,
    private analyticsService: AnalyticsService,
    private searcherService: SearcherService
  ) { }


  ionViewWillEnter() {this.pageId = +this.route.snapshot.paramMap.get('id');
    this.page$ = this.filters$.pipe(
      concatMap((filters) => this.searcherService.allItems(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/gallery/${this.pageId}/elements`, filters, this.itemType)),
      tap(() => this.$event && this.$event.target.complete && this.$event.target.complete()),
      tap((response) => this.events.pages$$.next(response.title)),
      tap(() => this.forbidden = false),
      catchError((err: any) => {
        if (err.status === 403) {
          this.forbidden = true;
        }

        throw err;
      }),
      shareReplay()
    );

    this.registerPageInAnalytics();
    this.getAllItems({ filters: { ...this.filters$.value, start: 0 } });
  }

  public goGalleryDetail($event, page) {
    const image: Media = $event.item;
    const position: number = $event.position;

    this.registerEventInAnalytics(image);
    this.openModalCarrouselDetail(position, page);
  }

  public goToAddItem(id) {
    this.router.navigate([`gallery/${id}/add`]);
  }

  private async openVimeoVideo(video) {
    this.galleryProvider.openVimeoVideo(video);
  }

  private async openModalCarrouselDetail(picturePosition: number, page) {
    let profileModal = await this.modalCtrl.create({
      component: GalleryModalComponent,
      componentProps: {
        position: picturePosition,
        initConfig: page
      }
    });
    await profileModal.present();
  }

  registerEventInAnalytics(media) {
    const paramsToAnalytics = { actions: 4 };
    const name = media.name;
    const id = media.id;
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
  }

  registerPageInAnalytics() {
    const paramsToAnalytics = { navigation: 2 };
    const name = 'Lista galeria';
    const id = this.pageId;
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
  }

  public getAllItems({ filters, event = null }) {
    if (event) this.$event = event;
    this.filters$.next(filters);
  }
}
