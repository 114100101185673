import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NewWallMessageModalComponent } from '../new-wall-item-modal/new-wall-item-modal';

@Component({
  selector: 'add-wall-item-input',
  templateUrl: 'add-wall-item-input.html',
  styleUrls: ['add-wall-item-input.scss']
})
export class AddWallItemInputComponent implements OnInit, OnChanges {
  @Input() inputPlaceholder: string = null;
  @Input() maxCharLength = 250;
  @Input() componentId;
  @Output() reloadEmitter = new EventEmitter<string>();

  constructor(private translateService: TranslateService, private modalController: ModalController) {}

  ngOnInit(): void {}

  ngOnChanges(changes) {
    this.initializePlaceholder();
  }

  private async initializePlaceholder(): Promise<void> {
    this.inputPlaceholder =
      this.inputPlaceholder !== null && this.inputPlaceholder !== ''
        ? this.inputPlaceholder
        : await this.translateService.get('PLACEHOLDERS-NEW-WALL-ITEM').toPromise();
  }

  public async presentNewMessageModal() {
    const modal = await this.modalController.create({
      component: NewWallMessageModalComponent,
      cssClass: 'modal-new-wall-item',
      componentProps: {
        placeholder: this.inputPlaceholder,
        maxCharLength: this.maxCharLength,
        componentId: this.componentId
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    this.reloadEmitter.emit(data.reload);
  }
}
