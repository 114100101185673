import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
//Fun
import { Observable } from 'rxjs';
import { HttpInterceptor, HttpHandler } from '@angular/common/http';
import { GlobalConstService } from './global-const.service';
import { LoginService } from './login.service';
import { PlatformService } from './platform.service';
import { SimulationService } from './simulation.service';
import { AnalyticsService } from './analytics.service';
//Providers
@Injectable({ providedIn: 'root' })
export class HttpInterceptorService implements HttpInterceptor {
  private deviceInfo: { device: string; platform: string } = { device: null, platform: null };
  constructor(
    public http: HttpClient,
    private loginService: LoginService,
    private platformService: PlatformService,
    private simulationService: SimulationService,
    private analyticsService: AnalyticsService
  ) {}

  intercept(request: any, next: HttpHandler) {
    request = this.injectBearerToken(request);
    request = this.injectDeviceInfo(request);
    request = this.injectSimulationMode(request);
    request = this.injectUtm(request);

    return next.handle(request);
  }

  private injectUtm(request: HttpRequest<any>) {
    if (!this.analyticsService.utmIsDefined()) {
      return request;
    }

    const { utm_source } = this.analyticsService.getUtm();

    if(utm_source){
      let setHeaders = { 'empatica-utm-source': utm_source };
  
      request = request.clone({ setHeaders });
      return request;
    }
  
  }

  private injectSimulationMode(request: HttpRequest<any>) {
    if (!this.simulationService.onSimulationMode()) {
      return request;
    }

    let setHeaders = { simulation: 'on' };

    request = request.clone({ setHeaders });
    return request;
  }

  private injectBearerToken(request: HttpRequest<any>) {
    if (request.url === 'https://fcm.googleapis.com/fcm/send') {
      return request;
    }
    if (request.url.includes('./assets/')) {
      return request;
    }
    let setHeaders = {};
    let dataUser: any = this.loginService.rescueSessionDataUser();

    if (!dataUser) {
      return request;
    }

    const bearerToken = dataUser.account.sessionToken;
    if (bearerToken) {
      setHeaders['Authorization'] = `Bearer ${bearerToken}`;
    }

    request = request.clone({ setHeaders });
    return request;
  }

  private injectDeviceInfo(request: HttpRequest<any>) {
    if (!request.url.includes('.empatica.io')) {
      return request;
    }
    if (request.url === 'https://fcm.googleapis.com/fcm/send') {
      return request;
    }
    if (request.url.includes('./assets/')) {
      return request;
    }
    let setHeaders = {};
    if (this.deviceInfo.device === null || this.deviceInfo.platform === null) {
      this.deviceInfo = {
        device: this.platformService.getDevice(),
        platform: this.platformService.get()
      };
    }
    setHeaders['Device'] = this.deviceInfo.device;
    setHeaders['Platform'] = this.deviceInfo.platform;

    request = request.clone({ setHeaders });
    return request;
  }
}
