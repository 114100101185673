import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({
  name: 'linksToButtons',
})
export class LinksToButtonsPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) { }

  transform(text: string) {
    if (!text) {
      return;
    }

    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(text, 'text/html');

    const links = htmlDoc.getElementsByTagName('a');

    for (let i = 0; i < links.length; i++) {
      const element: HTMLAnchorElement = links[i];
      const url = element.getAttribute('href');
      element.removeAttribute('rel');
      element.removeAttribute('onclick');
      element.setAttribute('href', '#')
      element.setAttribute('onclick', `event.preventDefault(); event.stopPropagation(); window.open("${url}", '_system', 'location=yes'); return false;`);
    }

    const innerHTML = htmlDoc.getElementsByTagName('body')[0].innerHTML;

    return this.domSanitizer.bypassSecurityTrustHtml(innerHTML);
  }
}
