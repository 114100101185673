import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pinnedFirst',
  pure: false
})
@Injectable({ providedIn: 'root' })
export class PinnedFirstPipe implements PipeTransform {
  transform(chats: any[]): any {
    return chats.sort((a, b) => {
      if (a.pinned && !b.pinned) {
        return -1;
      }
      if (!a.pinned && b.pinned) {
        return 1;
      }
      // a debe ser igual b
      return 0;
    });
  }
}
