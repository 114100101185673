// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-list wall-item:first-of-type {
  margin-top: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/wall/wall.scss"],"names":[],"mappings":"AAGK;EACE,gBAAA;AAFP","sourcesContent":[":host {\n  ion-list{\n   wall-item{\n     &:first-of-type{\n       margin-top: 1rem;\n     }\n   }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
