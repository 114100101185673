import { Component, OnDestroy } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'video-player',
  templateUrl: 'video-player.html',
  styleUrls: ['video-player.scss']
})
export class VideoPlayerComponent implements OnDestroy{
  public endpoint;
  public type;
  public orientation;

  constructor(public router: Router, public modalCtrl: ModalController, private navParams: NavParams) {
    const idVideo = this.navParams.get('idVideo');
    this.orientation = this.navParams.get('orientation');
    this.type = this.navParams.get('type');
    this.endpoint = this.getEndpoint(this.type, idVideo);
  }

  private getEndpoint(type: 'youtube' | 'vimeo' | 'native', idVideo) {
    if (type === 'vimeo') return `https://player.vimeo.com/video/${idVideo}?autoplay=1`;
    if (type === 'youtube') return `https://www.youtube.com/embed/${idVideo}?autoplay=1`;
    return idVideo;
  }

  public async closeAlertCancel() {
    this.endpoint = '';
    this.modalCtrl.dismiss('canceled');
  }

  ngOnDestroy(): void {
    this.endpoint = '';
  }
}
