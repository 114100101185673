import { Component, OnInit } from '@angular/core';

import { GlobalConstService } from 'src/app/services/global-const.service';
import { Filters } from 'src/app/models/filters.model';
import { InitConfig } from 'src/app/models/initConfig.model';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SearcherService } from 'src/app/services/searcher.service';
import { concatMap, tap, shareReplay, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { EventsService } from 'src/app/services/events.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'page-full-screen-wall',
  templateUrl: 'full-screen-wall.html',
  styleUrls: ['full-screen-wall.scss']
})
export class FullScreenWallPage implements OnInit {
  public itemType: string = 'wall';
  public filters$: BehaviorSubject<Filters> = new BehaviorSubject({
    searchKey: '',
    start: 0,
    length: 20,
    orderBy: 'publishDate',
    orderDir: 'desc'
  });

  public componentId: number;
  public component$: Observable<any>;

  private $event;
  public forbidden: boolean = false;

  constructor(private route: ActivatedRoute, private searcherService: SearcherService, private events: EventsService) {}

  ngOnInit() {
    this.componentId = +this.route.snapshot.paramMap.get('id');

    const endpoint: string = `${Environment.API_BASE_URL_V2}/api/empatica/v1/components/wall/${this.componentId}/elements`;

    this.component$ = this.filters$.pipe(
      concatMap((filters) => this.searcherService.allItems(endpoint, filters, this.itemType)),
      tap(() => this.$event && this.$event.target.complete && this.$event.target.complete()),
      tap((response) => this.events.pages$$.next(response.title)),
      tap(() => (this.forbidden = false)),
      catchError((err: any) => {
        if (err.status === 403) {
          this.forbidden = true;
        }

        throw err;
      }),
      shareReplay()
    );
  }

  ionViewWillEnter() {
    this.getAllItems({ filters: { ...this.filters$.value, start: 0 } });
  }

  public getAllItems({ filters, event = null }) {
    if (event) this.$event = event;
    this.filters$.next(filters);
  }

  public refresh() {
    this.getAllItems({ filters: { ...this.filters$.value, start: 0 } });
  }
}
