import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  /**
   * Takes a value and transform in appropriated size.
   */
  transform(value: number) {  
    if (value > 1024) {
      let result = Math.round(value / 1024);
      if (result > 1024) {
        result = Math.round(result / 1024);
        return `${result} MB`;
      }
      return `${result} KB`;
    }
    return value + ' B';
  }
}
