import { BlogAddItemPage } from './blog-pages/blog-add-item/blog-add-item.page';
import { DetailBlogPage } from './blog-pages/detail-blog/detail-blog.page';
import { FullScreenBlogPage } from './blog-pages/full-screen-blog/full-screen-blog.page';
import { DetailCalendarPage } from './calendar-pages/detail-calendar/detail-calendar.page';
import { FullScreenCalendarPage } from './calendar-pages/full-screen-calendar/full-screen-calendar.page';
import { FullScreenChatListPage } from './chat-pages/full-screen-chat-list/full-screen-chat-list.page';
import { FullScreenChatPage } from './chat-pages/full-screen-chat/full-screen-chat.page';
import { CommentsPage } from './comments/comments.page';
import { DetailContactsPage } from './contacts-pages/detail-contacts/detail-contacts.page';
import { FullScreenContactsComponentPage } from './contacts-pages/full-screen-contacts-component/full-screen-contacts-component.page';
import { FullScreenDirectoryPage } from './contacts-pages/full-screen-directory/full-screen-directory.page';
import { DocumentsSectionPage } from './documents-pages/documents-section/documents-section.page';
import { FullScreenDocumentsPage } from './documents-pages/full-screen-documents/full-screen-documents.page';
import { FullScreenPersonalDocumentsPage } from './documents-pages/full-screen-personal-documents/full-screen-personal-documents.page';
import { FullScreenEmbeddingsPage } from './embeddings-pages/full-screen-embeddings/full-screen-embeddings.page';
import { EpisodePlayerPage } from './episode-player/episode-player.page';
import { FullScreenGalleryPage } from './gallery-pages/full-screen-gallery/full-screen-gallery.page';
import { GalleryAddItemPage } from './gallery-pages/gallery-add-item/gallery-add-item.page';
import { GenericContainerPage } from './generic-container/generic-container.page';
import { HomePage } from './home/home.page';
import { LikesPage } from './likes/likes.page';
import { FullScreenLinksPage } from './links-pages/full-screen-links/full-screen-links.page';
import { LoginConfirmRescuePasswordPage } from './login-pages/login-confirm-rescue-password/login-confirm-rescue-password.page';
import { LoginRescuePasswordPage } from './login-pages/login-rescue-password/login-rescue-password.page';
import { LoginStepOnePage } from './login-pages/login-step-one/login-step-one.page';
import { LoginStepThreePage } from './login-pages/login-step-three/login-step-three.page';
import { LoginStepTwoPage } from './login-pages/login-step-two/login-step-two.page';
import { LoginWebPage } from './login-pages/login-web/login-web.page';
import { NotNetworkConnectionPage } from './not-network-connection/not-network-connection.page';
import { NotifyPage } from './notify/notify.page';
import { EditProfilePage } from './profile-pages/edit-profile/edit-profile.page';
import { ProfilePage } from './profile-pages/profile/profile.page';
import { FullScreenSurveyPage } from './survey-pages/full-screen-survey/full-screen-survey';
import { FullScreenWallPage } from './wall-pages/full-screen-wall/full-screen-wall.page';



export { BlogAddItemPage } from './blog-pages/blog-add-item/blog-add-item.page';
export { DetailBlogPage } from './blog-pages/detail-blog/detail-blog.page';
export { FullScreenBlogPage } from './blog-pages/full-screen-blog/full-screen-blog.page';
export { DetailCalendarPage } from './calendar-pages/detail-calendar/detail-calendar.page';
export { FullScreenCalendarPage } from './calendar-pages/full-screen-calendar/full-screen-calendar.page';
export { FullScreenChatListPage } from './chat-pages/full-screen-chat-list/full-screen-chat-list.page';
export { FullScreenChatPage } from './chat-pages/full-screen-chat/full-screen-chat.page';
export { CommentsPage } from './comments/comments.page';
export { DetailContactsPage } from './contacts-pages/detail-contacts/detail-contacts.page';
export { FullScreenContactsComponentPage } from './contacts-pages/full-screen-contacts-component/full-screen-contacts-component.page';
export { FullScreenDirectoryPage } from './contacts-pages/full-screen-directory/full-screen-directory.page';
export { DocumentsSectionPage } from './documents-pages/documents-section/documents-section.page';
export { FullScreenDocumentsPage } from './documents-pages/full-screen-documents/full-screen-documents.page';
export { FullScreenGalleryPage } from './gallery-pages/full-screen-gallery/full-screen-gallery.page';
export { GalleryAddItemPage } from './gallery-pages/gallery-add-item/gallery-add-item.page';
export { GenericContainerPage } from './generic-container/generic-container.page';
export { HomePage } from './home/home.page';
export { LikesPage } from './likes/likes.page';
export { FullScreenLinksPage } from './links-pages/full-screen-links/full-screen-links.page';
export { LoginConfirmRescuePasswordPage } from './login-pages/login-confirm-rescue-password/login-confirm-rescue-password.page';
export { LoginRescuePasswordPage } from './login-pages/login-rescue-password/login-rescue-password.page';
export { LoginStepOnePage } from './login-pages/login-step-one/login-step-one.page';
export { LoginStepThreePage } from './login-pages/login-step-three/login-step-three.page';
export { LoginStepTwoPage } from './login-pages/login-step-two/login-step-two.page';
export { NotNetworkConnectionPage } from './not-network-connection/not-network-connection.page';
export { NotifyPage } from './notify/notify.page';
export { EditProfilePage } from './profile-pages/edit-profile/edit-profile.page';
export { ProfilePage } from './profile-pages/profile/profile.page';
export { FullScreenSurveyPage } from './survey-pages/full-screen-survey/full-screen-survey';
export { EpisodePlayerPage } from './episode-player/episode-player.page';

export const Pages: any[] = [
  HomePage,
  GenericContainerPage,
  LoginStepOnePage,
  LoginStepTwoPage,
  LoginStepThreePage,
  LoginWebPage,
  FullScreenBlogPage,
  FullScreenCalendarPage,
  FullScreenDirectoryPage,
  FullScreenDocumentsPage,
  FullScreenPersonalDocumentsPage,
  FullScreenSurveyPage,
  FullScreenGalleryPage,
  EditProfilePage,
  DetailCalendarPage,
  ProfilePage,
  NotifyPage,
  FullScreenChatListPage,
  FullScreenLinksPage,
  DetailContactsPage,
  DetailBlogPage,
  LoginRescuePasswordPage,
  LoginConfirmRescuePasswordPage,
  DocumentsSectionPage,
  FullScreenContactsComponentPage,
  NotNetworkConnectionPage,
  CommentsPage,
  LikesPage,
  GalleryAddItemPage,
  BlogAddItemPage,
  FullScreenChatPage,
  FullScreenEmbeddingsPage,
  EpisodePlayerPage,
  FullScreenWallPage
];
