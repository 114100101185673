import { Component, Input } from '@angular/core';

@Component({
  selector: 'links-item',
  templateUrl: 'links-item.html',
  styleUrls: ['links-item.scss']
})
export class LinksItemComponent {
  @Input() link;
  @Input() spaceBetweenElements;

  constructor(
  ) {
  }


}
