import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Photo } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap, startWith, take, tap } from 'rxjs/operators';
import { BlogService } from 'src/app/services/blog.service';
import { EventsService } from 'src/app/services/events.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LoginService } from 'src/app/services/login.service';
import { PlatformService } from 'src/app/services/platform.service';
import { ToastService } from 'src/app/services/toast.service';
import { PromptCameraService } from '../../../services/prompt-camera.service';
import { decode } from 'base64-arraybuffer';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'blog-add-item',
  templateUrl: 'blog-add-item.html',
  styleUrls: ['blog-add-item.scss']
})
export class BlogAddItemPage implements OnInit {
  @ViewChild('fileImage') fileImage: ElementRef;
  public idPage;
  public id;

  public blogItemForm: UntypedFormGroup;
  public editorReady: boolean = false;
  public QEModules = {
    toolbar: [
      ['bold', 'underline'], // toggled buttons
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'] // link and image
    ]
  };

  public article = null;

  public image: Photo = null;
  public base64String: any = null;

  constructor(
    private loading: LoadingService,
    private toast: ToastService,
    private blogProvider: BlogService,
    private events: EventsService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private platformService: PlatformService,
    private promptCameraService: PromptCameraService,
    private sanitizer: DomSanitizer,
    private fileService: FileService
  ) {}

  ngOnInit() {
    this.blogItemForm = this.fb.group({
      authorName: '',
      title: ['', [Validators.required, Validators.minLength(1)]],
      body: [null, [Validators.required, Validators.minLength(1)]],
      summary: null,
      publishDate: [null, [Validators.required]],
      sharedURL: null,
      likesAllowed: false,
      commentsAllowed: false,
      sendNotification: false,
      sharedBlog: '',
      opened: false
    });

    if (this.platformService.isWeb()) {
      this.QEModules.toolbar = [
        ['bold', 'underline'], // toggled buttons
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image']
      ];
    }

    this.editorReady = true;

    this.idPage = +this.route.snapshot.paramMap.get('idPage');
    this.id = +this.route.snapshot.paramMap.get('id');
    if (!this.id) {
      this.initForm();
      return;
    }

    this.blogProvider.getArticle(this.id).subscribe((article) => {
      this.article = article;
      this.initForm(article);
    });
  }

  async initForm(article = null) {
    const account = this.loginService.rescueSessionDataUser().account;
    if (article) {
      article.publishDate = moment.utc(article.publishDate).local().format('YYYY-MM-DD HH:mm');
      this.blogItemForm.patchValue({
        authorName: article.authorName,
        title: article.title,
        body: article.body,
        summary: article.summary,
        publishDate: article.publishDate,
        likesAllowed: article.actions.likesAllowed,
        commentsAllowed: article.actions.commentsAllowed,
        sendNotification: article.sendNotification,
        sharedBlog: article.opened ? 'shareInternalUrl' : article.sharedURL ? 'shareExternalUrl' : '',
        sharedURL: article.sharedURL
      });
    } else {
      this.blogItemForm.patchValue({
        authorName: account.name + ' ' + account.surname,
        title: null,
        body: null,
        summary: null,
        publishDate: new Date().toISOString(),
        likesAllowed: false,
        commentsAllowed: false,
        sendNotification: false,
        sharedBlog: '',
        sharedURL: null
      });
    }
  }

  public submit() {

    if (false === this.blogItemForm.valid) {
      this.toast.info('YOU-MUST-FILL-OUT-THE-FORM');
      return;
    }

    let data = {
      ...this.blogItemForm.value,
      publishDate: this.generateDateUtc()
    };


    if (this.image !== null) {
      const base64 = this.base64String;
      data.headerImg = this.fileService.base64ToBlob(base64, `image/${this.image.format}`);
    }

    if (this.article && this.id) {
      this.editPost(this.idPage, this.id, data);
    } else {
      this.addPost(this.idPage, data);
    }
  }

  private async addPost(initConfigId, data) {
    await this.loading.show();
    this.blogProvider
      .addNewPost(initConfigId, data)
      .pipe(take(1))
      .subscribe({
        next: async (data) => {
          this.toast.info('ITEM-HAS-BEEN-CREATED-SUCCESSFULLY');
          this.events.nav.next({ route: `/blog/${initConfigId}/list`, refreshHome: true });
          await this.loading.hide();
        },
        error: async (error) => {
          console.log('error: ', error);

          this.toast.info('ITEM-COULD-NOT-BE-CREATED-CORRECTLY');
          this.events.nav.next({ route: `/blog/${initConfigId}/list`, refreshHome: true });
          await this.loading.hide();
        }
      });
  }

  private async editPost(initConfigId, articleId, data) {
    await this.loading.show();
    this.blogProvider
      .editItem(articleId, data)
      .pipe(take(1))
      .subscribe(
        async (editedPage) => {
          this.toast.info('ITEM-HAS-BEEN-UPDATED-SUCCESSFULLY');
          this.events.nav.next({ route: `/blog/${initConfigId}/list`, refreshHome: true });
          await this.loading.hide();
        },
        async () => {
          this.toast.info('ITEM-COULD-NOT-BE-UPDATED-CORRECTLY');
          this.events.nav.next({ route: `/blog/${initConfigId}/list`, refreshHome: true });
          await this.loading.hide();
        }
      );
  }

  public async selectPicture() {
    const result = await this.promptCameraService.ask();
    switch (result.action) {
      case this.promptCameraService.OPEN_INPUT_FILE:
        this.fileImage.nativeElement.click();
        break;
      case this.promptCameraService.REMOVE_IMAGE:
        break;
      default:
        if (result.image) {
          this.image = result.image;

          if (this.platformService.isWeb()) {
            const base64String = await this.fileService.getImageBase64(result.image.webPath);
            let formattedBase64 = base64String.split(',')[1]
            this.base64String = `data:image/${result.image.format};base64,${formattedBase64}`;
            return
          }

          const base64String = await this.fileService.convertFilePathToBase64(result.image.path || result.image.webPath);
          console.log('base64String: ', base64String);
          this.base64String = `data:image/${result.image.format};base64,${base64String}`;
        
        }
        break;
    }
  }

  triggerChangeDetection() {
    this.cdr.detectChanges();
  }

  private generateDateUtc() {
    let publishDate = this.blogItemForm.controls.publishDate.value;
    return moment(publishDate).utc().format('YYYY-MM-DD HH:mm');
  }

  public setPublishDate(event, field) {
    this.blogItemForm.controls[field].setValue(event.detail.value);
    this.blogItemForm.controls[field].updateValueAndValidity();
  }

  public changeShared($event) {
    this.blogItemForm.patchValue({
      opened: false,
      sharedURL: ''
    });
    const action = $event.target.value;

    this.blogItemForm.get('sharedURL').clearValidators();
    if (action === 'shareExternalUrl') {
      this.blogItemForm.get('sharedURL').setValidators([Validators.required]);
    }
    if (action === 'shareInternalUrl') {
      this.blogItemForm.patchValue({
        opened: true
      });
    }
    this.blogItemForm.get('sharedURL').updateValueAndValidity();
  }
}
