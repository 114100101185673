import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { EventsService } from 'src/app/services/events.service';
import { FileService } from 'src/app/services/file.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PlatformService } from 'src/app/services/platform.service';
import { SurveyService } from 'src/app/services/survey.service';

@Component({
  selector: 'page-full-screen-survey',
  templateUrl: 'full-screen-survey.html',
  styleUrls: ['full-screen-survey.scss']
})
export class FullScreenSurveyPage {
  @ViewChildren('fileImage') fileImages: QueryList<ElementRef>;

  public surveyForm: UntypedFormGroup;
  public surveyInitConfig: any;
  public answeredOnce: boolean = false;
  public completedForm: boolean = false;
  public confirmationMessage: string = '';
  public isFormLoaded: boolean = false;
  public surveyFormDataResponse: any;
  public isFormInvalid: boolean = false;

  public canAnswer: boolean = false;
  public pageId;
  public survey$;
  public survey;
  public title$: any;

  public webImage$;
  public showImageContainer = false;
  public matchingImages$: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(
    public router: Router,
    public surveyProvider: SurveyService,
    private loading: LoadingService,
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute,
    private events: EventsService,
    private fileService: FileService,
    private platformService: PlatformService
  ) {}

  ionViewWillEnter() {
    this.surveyForm = new UntypedFormGroup({});
    this.pageId = +this.route.snapshot.paramMap.get('id');
    this.getSurveyContent();
    this.registerPageInAnalytics();
  }

  private getSurveyContent() {
    this.survey$ = this.surveyProvider.getSurvey(this.pageId).pipe(
      tap(
        (response) => {
          this.survey = response;
          this.canAnswer = !response.answered || (response.multipleResponses && response.answered);
          this.confirmationMessage = response.confirmationMessage;
          this.surveyFormDataResponse = response.items.itemList;
          this.surveyForm = this.createForm(this.surveyFormDataResponse);
          this.isFormLoaded = true;
        },
        (error) => {
          console.error(error);
        }
      )
    );

    this.title$ = this.survey$.pipe(
      map((s: any) => s.title),
      tap((title) => this.events.pages$$.next(title))
    );
  }

  private createForm(surveyFormDataResponse) {
    let group: any = {};
    surveyFormDataResponse.forEach((question) => {
      question.response = [];
      let validators: any = Validators.maxLength(question.maxLength);
      if (question.mandatory) {
        validators = [Validators.required, Validators.maxLength(question.maxLength)];
      }

      group[question.question] = new UntypedFormControl(null, validators);
    });

    return new UntypedFormGroup(group);
  }

  public setOptionValue(option) {
    const key = Object.keys(option);
    const value = option[key[0]];
    return value;
  }

  public prepareQuestion(option) {
    option.isChecked = false;
  }

  public getTitleQuestion(option) {
    return Object.keys(option)[0];
  }

  public async onSubmit() {
    if (!this.surveyForm.valid) {
      this.checkFormKeys();
      this.isFormInvalid = true;
      return;
    }
    this.isFormInvalid = false;
    const userResponse = await this.surveyProvider.prepareUserResponse(this.surveyForm.value, this.surveyFormDataResponse);
    this.submit(userResponse);
  }

  private async submit(responses) {
    await this.loading.show();
    this.surveyProvider
      .sendSurvey(this.pageId, responses)
      .pipe(finalize(async () => await this.loading.hide()))
      .subscribe({
        next: (res) => {
          this.completedForm = true;
          this.answeredOnce = res.answered || (res.answered && res.multipleResponses);
          this.confirmationMessage = res.confirmationMessage;

          if (this.survey.multipleResponses === true) {
            this.surveyForm.reset();
            this.surveyProvider.images$.next([]);
            this.matchingImages$.next([]);
          }

          this.registerEventInAnalytics();
        },
        error: (err) => {
          console.log(err);
        }
      });
  }

  registerEventInAnalytics() {
    const paramsToAnalytics = { actions: 0 };
    const name = 'Titulo de encuesta';
    const id = this.pageId;
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
    this.loading.hide();
  }

  registerPageInAnalytics() {
    const paramsToAnalytics = { navigation: 2 };
    const name = 'Titulo de encuesta';
    const id = this.pageId;
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
  }

  private checkFormKeys() {
    let keys = Object.keys(this.surveyForm.controls);
    keys.forEach((key) => {
      this.surveyForm.controls[key].updateValueAndValidity();
    });
  }

  public checkboxChange($event, question, option) {
    const selected = Object.keys(option)[0];

    let value = [];

    if (this.surveyForm.value[question] !== null) {
      value = [...this.surveyForm.value[question]];
    }

    if ($event.detail.checked) {
      value = [...new Set([...value, selected])];
    } else {
      value = [...new Set([...value.filter((value) => value !== selected)])];
    }

    this.surveyForm.patchValue({ [question]: value });
  }

  /**
   * function to adjust the height of the message textarea
   * @param {any} event - the event, which is provided by the textarea input
   * @return {void}
   */
  public adjustTextarea(event: any): void {
    let textarea: any = event.target;
    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
    return;
  }

  public radioGroupChange($event, question) {
    this.surveyForm.patchValue({ [question]: $event.detail.value });
  }

  public async uploadCameraPictureWeb(event, form, question) {
    const fileInput = this.fileImages.find((fileInput) => fileInput.nativeElement.id === `fileImage_${question.id}`);
    this.surveyProvider.uploadCameraPictureWeb(event, form, question, fileInput);
  }

  public async selectPicture(question) {
    const fileInput = this.fileImages.find((fileInput) => fileInput.nativeElement.id === `fileImage_${question.id}`);

    if (fileInput) {
      await this.surveyProvider.selectPicture(fileInput, question);
      await this.loadMatchingImage(question);
    }
  }

  async loadMatchingImage(question) {
    const images = this.surveyProvider.images$.value;

    if (images && images.length > 0) {
      const matchingImage = images.find((image) => image.questionId === question.id);

      if (matchingImage) {
        let base64String;

        if (this.platformService.isWeb()) {
          const base64Image = await this.fileService.getImageBase64(matchingImage.image.path || matchingImage.image.webPath);
          let formattedBase64 = base64Image.split(',')[1];
          base64String = `data:image/${matchingImage.image.format};base64,${formattedBase64}`;
        }

        if (this.platformService.isMobile()) {
          const base64Image = await this.fileService.convertFilePathToBase64(matchingImage.image.path || matchingImage.image.webPath);
          base64String = `data:image/${matchingImage.image.format};base64,${base64Image}`;
        }

        this.matchingImages$.next(
          this.matchingImages$.getValue().concat({
            question: question.id,
            image: base64String
          })
        );

        return;
      }
    }
    return './assets/imgs/photo_placeholder.svg'; // Return null si no se encuentra ninguna imagen coincidente
  }

  getImageSrc(questionId: string) {
    const images = this.matchingImages$.value;
    const matchingImage = images.find((image) => image.question === questionId);
    if (!matchingImage) {
      return './assets/imgs/photo_placeholder.svg';
    }

    return matchingImage.image;
  }
}
