import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'urlify',
})
export class UrlifyPipe implements PipeTransform {
  private URL_REGEX = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

  constructor(private domSanitizer: DomSanitizer) { }

  transform(text: string) {

    if (!text) {
      return;
    }

    const convertedHTML = text.replace(this.URL_REGEX, (url) => `<a href="#" target="_system" onclick="event.preventDefault(); event.stopPropagation(); console.log(''); window.open(\`${url.replace("'", "\'")}\`, '_system', 'location=yes'); return false;">${url}</a>`);
    return this.domSanitizer.bypassSecurityTrustHtml(convertedHTML);
  }
}
