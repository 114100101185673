import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { switchMap } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoginService } from 'src/app/services/login.service';
import { ProfileService } from 'src/app/services/profile.service';
import { ToastService } from 'src/app/services/toast.service';
import { Environment } from 'src/environments/environment';
import { ChangePasswordModalComponent } from '../../../components/change-password-modal/change-password-modal';

@Component({
  selector: 'page-edit-profile',
  templateUrl: 'edit-profile.html'
})
export class EditProfilePage implements OnInit {
  public editProfileForm: UntypedFormGroup;
  public profileForm: User;
  public userData: any;
  public Object = Object;
  public OAUTH = Environment.AVAILABLE_LOGINS.includes('OAUTH_LOGIN');

  constructor(
    private modalCtrl: ModalController,
    private loginService: LoginService,
    private router: Router,
    private profileProvider: ProfileService,
    private toast: ToastService,
    private gConst: GlobalConstService,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    this.userData = this.loginService.rescueSessionDataUser().account;
    this.editProfileForm = new UntypedFormGroup({
      username: new UntypedFormControl({ value: this.userData.username, disabled: true }, Validators.required),
      email: new UntypedFormControl(this.userData.email),
      name: new UntypedFormControl(this.userData.name, [Validators.required, Validators.minLength(2)]),
      surname: new UntypedFormControl(this.userData.surname, [Validators.required, Validators.minLength(2)]),
      phoneNumber: new UntypedFormControl(this.userData.phoneNumber),
      mobilePhoneNumber: new UntypedFormControl(this.userData.mobilePhoneNumber),
      website: new UntypedFormControl(this.userData.website),
      description: new UntypedFormControl(this.userData.description),
      lang: new UntypedFormControl(this.userData.lang ? this.userData.lang : Environment.DEFAULT_LANGUAGE)
    });

    Object.values(this.userData.additional).forEach((ad: any) => {
      const fc = new UntypedFormControl({ value: ad.value, disabled: !ad.editable });
      if (ad.required) fc.setValidators([Validators.required]);
      this.editProfileForm.addControl(ad.id, fc);
    });
  }

  public async openChangePwdModal() {
    const modalOptions: ModalOptions = { component: ChangePasswordModalComponent };
    let changePwdModal = await this.modalCtrl.create(modalOptions);
    await changePwdModal.present();
  }

  public onSubmit() {
    this.profileProvider
      .putProfileEditedData(this.editProfileForm.value)
      .pipe(switchMap(() => this.loginService.getUserData()))
      .subscribe({
        next: (account) => {
          if (account.lang !== this.gConst.userData.account.lang) {
            this.languageService.change(account.lang);
          }

          this.gConst.userData.account = account;
          this.loginService.updateUserAccountData(account);

          this.toast.info('CHANGES-HAVE-BEEN-SUCCESSFULLY-SAVED');
          this.router.navigate([`/profile`]);
        },
        error: (err) => {
          this.toast.info('FAILED-TO-SAVE-DATA');
          this.router.navigate([`/profile`]);
        }
      });
  }
}
