// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host img {
  border-radius: 47px !important;
}
:host .title {
  text-align: center;
}
:host .contacts-component.list-view ion-item.item {
  --padding-start: 1rem;
  --padding-end: 1rem;
  border-bottom: 1px solid var(--ion-color-light);
}
:host .contacts-component.list-view ion-item.item p {
  color: var(--ion-color-medium);
}`, "",{"version":3,"sources":["webpack://./src/app/components/contacts/contacts-component.scss"],"names":[],"mappings":"AACE;EACE,8BAAA;AAAJ;AAEE;EACE,kBAAA;AAAJ;AAIM;EACE,qBAAA;EACA,mBAAA;EACA,+CAAA;AAFR;AAIQ;EACE,8BAAA;AAFV","sourcesContent":[":host {\n  img {\n    border-radius: 47px !important;\n  }\n  .title {\n    text-align: center;\n  }\n  .contacts-component {\n    &.list-view {\n      ion-item.item {\n        --padding-start: 1rem;\n        --padding-end: 1rem;\n        border-bottom: 1px solid var(--ion-color-light);\n\n        p {\n          color: var(--ion-color-medium);\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
