import { Injectable } from '@angular/core';
import { Environment } from 'src/environments/environment';

import { GlobalConstService } from './global-const.service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { HttpService } from './http.service';
import { InitialConfigService } from './inital-config.service';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  constructor(
    private http: HttpService, 
    private platformService: PlatformService,
    public initialConfigProvider: InitialConfigService,
    ) {}

  public getClientBySubdomain(subdomain: string) {
    return this.http.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/public/client/subdomain/${subdomain}`);
  }

  public getClient() {
    return this.http.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/public/client/${Environment.CLIENT_ID}`);
  }

  public setThemeApplication(cssStyleSheet) {
    this.initialConfigProvider.styleSheet = cssStyleSheet;

    const cssStyles = { appBody: document.querySelector('body') };

    cssStyles.appBody.style.setProperty('--cms-button-text-color', cssStyleSheet.general.buttonTextColor);
    cssStyles.appBody.style.setProperty('--cms-button-bg-color', cssStyleSheet.general.buttonBgColor);
    cssStyles.appBody.style.setProperty('--cms-button-bg-color-hover', cssStyleSheet.general.buttonBgHoverColor);
    cssStyles.appBody.style.setProperty('--cms-button-bg-color-pressed', cssStyleSheet.general.buttonBgPressedColor);
    cssStyles.appBody.style.setProperty('--cms-link-text-color', cssStyleSheet.general.linkTextColor);
    cssStyles.appBody.style.setProperty('--cms-error-text-color', cssStyleSheet.general.errorTextColor);
    cssStyles.appBody.style.setProperty('--cms-web-bg-color', cssStyleSheet.general.webBgColor);
    cssStyles.appBody.style.setProperty('--cms-error-bg-color', cssStyleSheet.general.errorBgColor);
    cssStyles.appBody.style.setProperty('--cms-intro-bg-image', `url("${cssStyleSheet.intro.bgImage}")`);
    cssStyles.appBody.style.setProperty('--cms-intro-bg-color', cssStyleSheet.intro.bgColor);
    cssStyles.appBody.style.setProperty('--cms-header-logo', cssStyleSheet.header.logo);
    cssStyles.appBody.style.setProperty('--cms-header-text-color', cssStyleSheet.header.textColor);
    cssStyles.appBody.style.setProperty('--cms-header-bg-color', cssStyleSheet.header.bgColor);
    cssStyles.appBody.style.setProperty('--cms-header-icon-color', cssStyleSheet.header.iconColor);
    cssStyles.appBody.style.setProperty('--cms-menu-bg-color', cssStyleSheet.menu.bgColor);
    cssStyles.appBody.style.setProperty('--cms-menu-bg-image', `url("${cssStyleSheet.menu.bgImage}")`);
    cssStyles.appBody.style.setProperty('--cms-menu-hover-color', cssStyleSheet.menu.hoverColor);
    cssStyles.appBody.style.setProperty('--cms-menu-text-color', cssStyleSheet.menu.textColor);
    cssStyles.appBody.style.setProperty('--cms-menu-icon-color', cssStyleSheet.menu.iconColor);
    cssStyles.appBody.style.setProperty('--cms-tabs-bg-color', cssStyleSheet.tabs?.bgColor);
    cssStyles.appBody.style.setProperty('--cms-tabs-text-color', cssStyleSheet.tabs?.textColor);
    cssStyles.appBody.style.setProperty('--cms-tabs-icon-color', cssStyleSheet.tabs?.iconColor);
    cssStyles.appBody.style.setProperty('--cms-component-header-text-color', cssStyleSheet.component.headerTextColor);
    cssStyles.appBody.style.setProperty('--cms-component-header-bg-color', cssStyleSheet.component.headerBgColor);
    cssStyles.appBody.style.setProperty('--cms-component-header-icon-color', cssStyleSheet.component.headerIconColor);
    cssStyles.appBody.style.setProperty('--cms-component-header-button-icon-color', cssStyleSheet.component.headerButtonIconColor);

    if (!this.platformService.isWeb()) {
      StatusBar.setStyle({ style: cssStyleSheet.header.statusBarColor === '1' ? Style.Dark : Style.Light });
      StatusBar.setBackgroundColor({ color: this.lightenDarkenColor(cssStyleSheet.header.bgColor, -10) });
    }
  }

  private lightenDarkenColor(col, amt) {
    let usePound = false;

    if (col[0] == '#') {
      col = col.slice(1);
      usePound = true;
    }

    let num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  }

  public saveAppTheme(cssStyleSheet) {
    localStorage.setItem('cssStyleSheet', JSON.stringify(cssStyleSheet));
  }

  public saveAppClient(opened) {
    localStorage.setItem('clientOpened', JSON.stringify(opened));
  }

  public rescueAppStyleSheet() {
    const r = localStorage.getItem('cssStyleSheet');
    return JSON.parse(r);
  }

  public isOpened(){
    console.log('isopened: ',this.initialConfigProvider.opened && !this.initialConfigProvider.userInitialConfigData);

    return this.initialConfigProvider.opened && !this.initialConfigProvider.userInitialConfigData;
  
  }    

  public isOpenedAccountClient(){
    const r = localStorage.getItem('clientOpened');
    const clientOpened = JSON.parse(r)
    return clientOpened;
  }
  
  public getClientById(id: number) {
    return this.http.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/public/client/${id}`);
  }
}
