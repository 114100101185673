import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';
import { EventsService } from './events.service';
import { GlobalConstService } from './global-const.service';

@Injectable({ providedIn: 'root' })
export class HttpService {
  constructor(private http: HttpClient, public gConst: GlobalConstService, public events: EventsService) { }

  public myPost(url: string, data: any): Observable<any> {
    return this.http.post<any>(url, data).pipe(catchError(this.handleError(() => this.myPost(url, data))), shareReplay());
  }

  public multipartPost(url: string, data: any): Observable<any> {
    return this.http.post<any>(url, data).pipe(catchError(this.handleError(() => this.myPost(url, data))), shareReplay());
  }

  public myPut(url: string, data?: any): Observable<any> {
    return this.http.put(url, data).pipe(catchError(this.handleError(() => this.myPut(url, data))));
  }

  public myGet(url: string, queryParams = null): Observable<any> {
    return this.http.get(url, { params: queryParams }).pipe(catchError(this.handleError(() => this.myGet(url))), shareReplay());
  }

  public myDelete(url: string): Observable<any> {
    return this.http.delete(url).pipe(catchError(this.handleError(() => this.myDelete(url))), shareReplay());
  }

  public handleError(afterRenewFunction: Function) {
    return (error: any): Observable<any> => {
      const errorMessage = 'GENERIC_ERROR';
      const errorResponse = {
        400: 'ERRORS-400',
        401: 'ERRORS-401',
        403: 'ERRORS-403',
        500: 'ERRORS-500',
        0: 'ERRORS-NO_INTERNET',
        default: 'ERRORS-GENERIC_ERROR'
      };
      error.errorMessage = errorResponse[error.status] ? errorResponse[error.status] : errorMessage;

      const endPointLoginUser = `${Environment.API_BASE_URL_V2}/api/empatica/v1/public/login`;
      const endPointNotifications = `${Environment.API_BASE_URL_V2}/api/empatica/v1/user/current/notifications/token`;
      
      if (error.status === 401 && error.url !== endPointLoginUser && error.url !== endPointNotifications) {
        this.events.publish({ name: 'session', value: 'expired' });
      }

      throw error;
    };
  }

  public myPatch(url: string, data?: any): Observable<any> {
    return this.http.patch<any>(url, data).pipe(catchError(this.handleError(() => this.myPatch(url, data))));
  }
}
