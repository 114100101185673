// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}
:host .isLiked {
  color: black;
  text-shadow: 0px 0px 1px black;
}
:host .notLiked {
  color: white;
  text-shadow: 0px 0px 1px black;
}
:host .disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: none;
}
:host .pointer {
  cursor: pointer !important;
}
:host .comments-and-likes-count {
  text-align: left;
  font-size: 12px;
  margin-top: 6px;
  height: 0px;
  overflow: hidden;
  opacity: 0;
  transition: all ease 0.3s;
}
:host .comments-and-likes-count.show {
  opacity: 1;
  height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/toolbar-comments-likes/toolbar-comments-likes.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAAE;EACE,YAAA;EACA,8BAAA;AAEJ;AACE;EACE,YAAA;EACA,8BAAA;AACJ;AAEE;EACE,oBAAA;EACA,YAAA;EACA,YAAA;AAAJ;AAGE;EACE,0BAAA;AADJ;AAIE;EACE,gBAAA;EACA,eAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;EACA,UAAA;EACA,yBAAA;AAFJ;AAII;EACE,UAAA;EACA,YAAA;AAFN","sourcesContent":[":host {\n  display: block;\n  .isLiked {\n    color: black;\n    text-shadow: 0px 0px 1px black;\n  }\n\n  .notLiked {\n    color: white;\n    text-shadow: 0px 0px 1px black;\n  }\n\n  .disabled{\n    pointer-events: none;\n    opacity: 0.5;\n    cursor: none;\n  }\n\n  .pointer {\n    cursor: pointer !important;\n  }\n\n  .comments-and-likes-count {\n    text-align: left;\n    font-size: 12px;\n    margin-top: 6px;\n    height: 0px;\n    overflow: hidden;\n    opacity: 0;\n    transition: all ease 0.3s;\n\n    &.show {\n      opacity: 1;\n      height: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
