import { Component, ElementRef, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';

// Components
import { GalleryModalComponent } from './gallery-modal/gallery-modal';
import { VideoPlayerComponent } from './video-player/video-player';

// Animations
import { AnimationList } from '../animations/animationList';
import { NavController, ModalController, IonicSlides } from '@ionic/angular';
import { InitConfig } from 'src/app/models/initConfig.model';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { CheckComponentsService } from 'src/app/services/check-components.service';
import { GalleryService } from 'src/app/services/gallery.service';
import { Filters } from 'src/app/models/filters.model';
import { Media } from 'src/app/models/media.model';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'gallery-component',
  templateUrl: 'gallery-component.html',
  styleUrls: ['gallery-component.scss'],
  animations: AnimationList
})
export class GalleryComponent {
  @ViewChild('slides')
  slides: ElementRef | undefined;
  swiperModules = [IonicSlides];

  public images: Array<Media> = [];
  public principalImage: Media | any;
  public galleryConfig: any = {};

  public slideOpts = {
    slidesPerView: 1,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    spaceBetween: 0,
    pager: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      dynamicBullets: true
    }
  };

  public showHeaders: boolean;
  public showViewMoreBtn: boolean = false;

  public isPagerView: boolean = false;
  public galleryInitialConfig: InitConfig;
  public isEmpty: boolean = false;
  private errorInComponentGallery: { haveError: boolean; textError: string } = {
    haveError: false,
    textError: ''
  };

  constructor(
    private router: Router,
    private modalCtrl: ModalController,
    private galleryProvider: GalleryService,
    private checkComponent: CheckComponentsService
  ) {
    this.galleryInitialConfig = this.galleryProvider.galleryInitialConfig;
    this.showHeaders = this.galleryInitialConfig.view.showHeader;
    this.getGalleryConfiguration();
  }

  private getGalleryConfiguration() {
    const filters: Filters = {
      searchKey: '',
      start: 0,
      length: this.galleryInitialConfig.view.maxNumItems
    };

    this.galleryProvider.getGallery(this.galleryInitialConfig.id, filters).subscribe({
      next: (response) => {
        this.galleryConfig = response;
        this.images = this.checkComponent.setItemsInComponent(response.items.itemList, response.items.itemsCount);
        this.isEmpty = this.checkComponent.checkEmptyComponent(this.images);

        this.showViewMoreBtn = response.items.itemsCount > response.items.itemsFiltered;
        if (this.galleryConfig.view.mode === 'carrousel') {
          this.setIconInPictureInCarrousel(this.images);
        } else {
          this.setIconInPicture(this.images);
        }

        if (!this.isEmpty) this.configViewGallery();
      },
      error: (error) => {
        console.error('Error getting gallery', error);
      }
    });
  }

  public setIconInPicture(images) {
    images.forEach((image) => {
      image.class = 'thumbnail-image';
      if (image.type === 'video') {
        image.class += ' is-video';
      }
      return image.class;
    });
  }
  public setIconInPictureInCarrousel(images) {
    images.forEach((image) => {
      if (image.type === 'video') {
        image.class = 'is-video';
      }
      return image.class;
    });
  }

  private configViewGallery() {
    if (this.galleryConfig.view.mode === 'carrousel') {
      this.isPagerView = this.images.length > 1;
    }
    this.principalImage = this.images[0];
  }

  private async openModalCarrouselDetail(picturePosition: number) {
    const galleryModal = await this.modalCtrl.create({
      component: GalleryModalComponent,
      componentProps: {
        position: picturePosition,
        initConfig: this.galleryConfig
      }
    });
    await galleryModal.present();
  }

  private refresh(data?) {
    this.getGalleryConfiguration();
  }

  public goGalleryDetail(picturePosition) {
    this.openModalCarrouselDetail(picturePosition);
  }

  public goGalleryFullScreen() {
    this.router.navigate([`gallery/${this.galleryInitialConfig.id}/list`], {
      state: {
        initConfig: this.galleryInitialConfig
      }
    });
  }

  trackByMethod(index: number, el: any): number {
    return el.id;
  }

  public isButtonView() {
    return this.galleryInitialConfig.view.mode === 'button' && !this.errorInComponentGallery.haveError;
  }

  public isListView() {
    return this.galleryInitialConfig.view.mode === 'item-list' && !this.errorInComponentGallery.haveError;
  }

  public isCarrouselView() {
    return this.galleryInitialConfig.view.mode === 'carrousel' && !this.errorInComponentGallery.haveError;
  }

  public isFirstPicture(position) {
    return position > 0;
  }
}
