// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-item.item {
  --background: transparent;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --border-radius: 10px;
  display: flex;
  box-shadow: 3px 3px 10px -5px #333;
  align-items: stretch;
}
:host ion-item.item .date {
  background-color: var(--cms-component-header-bg-color) !important;
  color: var(--cms-component-header-text-color) !important;
  text-align: center;
  padding: 15px 10px;
  margin: 0 1rem 0 0;
  width: 90px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
:host ion-item.item .date p {
  margin: 0;
  font-size: 16px !important;
}
:host ion-item.item .date p.day {
  line-height: 1 !important;
}
:host ion-item.item .date p.month {
  text-transform: uppercase;
  line-height: 1 !important;
  margin-top: 4px;
}
:host ion-item.item ion-label {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 10px 0px;
  align-self: center;
}
:host ion-item.item ion-label h3 {
  font-size: 14px !important;
  line-height: 1 !important;
  font-weight: bold;
}
:host ion-item.item ion-label p {
  font-size: 12px;
  color: var(--ion-color-medium);
}`, "",{"version":3,"sources":["webpack://./src/app/components/calendar/event-by-month/event-by-month.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,oBAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,qBAAA;EACA,aAAA;EACA,kCAAA;EACA,oBAAA;AAAJ;AAEI;EACE,iEAAA;EACA,wDAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AAAN;AACM;EACE,SAAA;EACA,0BAAA;AACR;AACQ;EACE,yBAAA;AACV;AAEQ;EACE,yBAAA;EACA,yBAAA;EACA,eAAA;AAAV;AAII;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,iBAAA;EACA,kBAAA;AAFN;AAIM;EACE,0BAAA;EACA,yBAAA;EACA,iBAAA;AAFR;AAKM;EACE,eAAA;EACA,8BAAA;AAHR","sourcesContent":[":host {\n  ion-item.item {\n    --background: transparent;\n    --padding-start: 0px;\n    --padding-end: 0px;\n    --padding-top: 0px;\n    --padding-bottom: 0px;\n    --border-radius: 10px;\n    display: flex;\n    box-shadow: 3px 3px 10px -5px #333;\n    align-items: stretch;\n\n    .date{\n      background-color: var(--cms-component-header-bg-color)!important;\n      color: var(--cms-component-header-text-color) !important;\n      text-align: center;\n      padding: 15px 10px;\n      margin: 0 1rem 0 0;\n      width: 90px;\n      height: 100%;\n      display: inline-flex;\n      align-items: center;\n      justify-content: center;\n      flex-direction: column;\n      p{\n        margin: 0;\n        font-size: 16px !important;\n\n        &.day{\n          line-height: 1 !important;\n        }\n\n        &.month{\n          text-transform: uppercase;\n          line-height: 1 !important;\n          margin-top: 4px;\n        }\n      }\n    }\n    ion-label {\n      display: flex;\n      flex-direction: column;\n      margin: 0;\n      padding: 10px 0px;\n      align-self: center;\n\n      h3{\n        font-size: 14px !important;\n        line-height: 1 !important;\n        font-weight: bold;\n      }\n\n      p{\n        font-size: 12px;\n        color: var(--ion-color-medium);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
