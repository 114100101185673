import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'converToDate',
})
export class ConverToDatePipe implements PipeTransform {
  constructor() { }

  transform(text: string) {
    if (!text) {
      return;
    }

    return moment(text, 'YYYY-MM-DD HH:mm').toDate();
  }
}
