import { Injectable } from '@angular/core';
import heic2any from 'heic2any';
import { LoadingService } from './loading.service';
import { Filesystem } from '@capacitor/filesystem';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private loading: LoadingService, private httpClient: HttpClient) {}

  public async generateFile(file: any) {
    file = await this.convertToJPEGifHEIC(file);
    const b64Data = await this.convertFileToBase64(file);
    return { blob: file, image: b64Data, name: file.name };
  }

  public async generateDocumentFile(file: any) {
    file = await this.convertToJPEGifHEIC(file);
    const b64Data = await this.convertFileToBase64(file);
    return { blob: file, image: b64Data, document_name: file.name };
  }

  public async generateFilePath(filePath:any) {
    console.log('file service generateFilePath: ', filePath);

    var filename = decodeURI(filePath.replace(/^.*[\\\/]/, ''));
    const b64Data = await this.convertFilePathToBase64(filePath);
    console.log('file service generateFilePath result: ', { filename, b64Data });
    return { image: b64Data, name: filename };
  }

  public blobToFile = (theBlob: Blob, fileName: string): File => {
    let b: any = theBlob;

    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModified = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>b;
  };

  private async convertToJPEGifHEIC(file: any) {
    if (/image\/hei(c|f)/.test(file.type)) {
      await this.loading.show();

      const jpgBlob = await heic2any({ blob: file, toType: 'image/jpeg', quality: 0.92 });

      await this.loading.hide();

      file = this.blobToFile(<any>jpgBlob, file.name.replace(/\.[^/.]+$/, '.jpg'));
    }
    return file;
  }

  public async convertFilePathToBase64(filePath) {
    try {
      let contents = await Filesystem.readFile({
        path: filePath
      });
      return contents.data;
    } catch (e) {
      console.log('error convert File to Base64', e);
    }
  }

  public base64ToBlob(base64String: string, contentType: string): Blob {
    console.log('base64ToBlob: ', base64String, contentType);

    const byteCharacters = atob(base64String.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  public async getImageBase64(webPath: string): Promise<any> {
    try {
      const blob = await this.httpClient.get(webPath, { responseType: 'blob' }).toPromise();
      const base64String = await this.convertFileToBase64(blob);
      return base64String;
    } catch (error) {
      console.error('Error converting blob to base64', error);
    }
  }

  public async convertFileToBase64(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(file);
    });
  }
}
