import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  constructor(
    private androidPermissions: AndroidPermissions,
    private toast: ToastService,
  ) { }

  public async getPermission() {
    const y = this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA);

    const requestPermission = this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE;
    const statusHasPermission = await this.androidPermissions.checkPermission(requestPermission);

    if (!statusHasPermission.hasPermission) {
      const statusReqPermission = await this.androidPermissions.requestPermission(requestPermission);

      if (!statusReqPermission.hasPermission) {
        this.toast.info('INSUFFICIENT-PERMISSIONS');
        return false;
      }
    }
    return true;
  }


  public getBlob(b64Data: string, contentType: string, sliceSize: number = 512) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }
}
