// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-content {
  --background: var(--ion-color-light);
}
:host ion-content .centered-card-container {
  height: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
:host ion-content .card.login-card .client-card {
  width: 100%;
}
:host ion-content .card.login-card .client-card .logo {
  height: 17vh;
}
:host ion-content .card.login-card .client-card .client_name {
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
}
:host ion-content .card.login-card .client-card .client_logo {
  object-fit: contain;
  max-height: 3em;
  width: 100%;
  object-position: center;
}
:host ion-content .card.login-card .client-card .with_client_logo {
  font-size: 1rem;
  margin-top: 10px;
}
:host ion-content .card.login-card ion-button {
  --border-radius: 4px;
  height: 44px;
  font-size: 1rem;
  --color: #fff !important;
  --background: var(--ion-color-primary) !important;
  margin: 0.4rem 0.2rem;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/login-pages/login-step-two/login-step-two.scss"],"names":[],"mappings":"AACE;EACE,oCAAA;AAAJ;AAEI;EACE,YAAA;EACA,mBAAA;EACA,aAAA;EACA,eAAA;AAAN;AAKQ;EACE,WAAA;AAHV;AAIU;EACE,YAAA;AAFZ;AAIU;EACE,kBAAA;EACA,iBAAA;EACA,iBAAA;AAFZ;AAIU;EACE,mBAAA;EACA,eAAA;EACA,WAAA;EACA,uBAAA;AAFZ;AAKU;EACE,eAAA;EACA,gBAAA;AAHZ;AAOQ;EACE,oBAAA;EACA,YAAA;EACA,eAAA;EACA,wBAAA;EACA,iDAAA;EACA,qBAAA;AALV","sourcesContent":[":host {\n  ion-content {\n    --background: var(--ion-color-light);\n\n    .centered-card-container {\n      height: 100%;\n      align-items: center;\n      display: flex;\n      flex-wrap: wrap;\n    }\n\n    .card {\n      &.login-card {\n        .client-card {\n          width: 100%;\n          .logo {\n            height: 17vh;\n          }\n          .client_name {\n            text-align: center;\n            font-weight: bold;\n            font-size: 1.2rem;\n          }\n          .client_logo {\n            object-fit: contain;\n            max-height: 3em;\n            width: 100%;\n            object-position: center;\n          }\n\n          .with_client_logo {\n            font-size: 1rem;\n            margin-top: 10px;\n          }\n        }\n\n        ion-button {\n          --border-radius: 4px;\n          height: 44px;\n          font-size: 1rem;\n          --color: #fff !important;\n          --background: var(--ion-color-primary) !important;\n          margin: 0.4rem 0.2rem;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
