import { Injectable, NgZone } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';
import { EventsService } from './events.service';
import { GlobalConstService } from './global-const.service';
import { HttpService } from './http.service';
import { LinksService } from './links.service';
import { LoginService } from './login.service';
import { PlatformService } from './platform.service';
import { ToastService } from './toast.service';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { Haptics } from '@capacitor/haptics';
@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(
    private httpService: HttpService,
    private router: Router,
    private toastService: ToastService,
    public events: EventsService,
    private loginService: LoginService,
    public afs: AngularFirestore,
    private platformService: PlatformService,
    private linksService: LinksService,
    private ngZone: NgZone
  ) {}

  public initializePushNotifications() {
    if (this.platformService.isWeb()) return;

    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      const notificationData = notification.data;

      const skipNotification: boolean = this.skipNotification(notificationData);

      if (skipNotification) {
        console.log('Skipped notification');
        return;
      }

      Haptics.vibrate();

      this.ngZone.run(() => {
        this.toastService.showPush(notification, (notification) => this.events.notifications.next(notification));
      });
      console.log('Push received: ' + JSON.stringify(notification));
    });

    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      this.events.notifications.next(notification);
    });
  }

  private skipNotification(notificationData): boolean {
    const currentUrl = this.router.url;

    if (notificationData.topic === 'bot' && currentUrl.includes(`chat/chatbot/${notificationData.idRoom}`)) {
      console.log('User already in conversation');
      return true;
    }

    if (currentUrl.includes(`/chat/single/${notificationData.idRoom}`) || currentUrl.includes(`/chat/group/${notificationData.idRoom}`)) {
      console.log('User already in conversation');
      return true;
    }

    return false;
  }
  public saveNotificationToken(token) {
    const notificationToken = { notificationToken: token };
    console.log('Token push -->', token);
    this.httpService
      .myPut(`${Environment.API_BASE_URL_V2}/api/empatica/v1/user/current/notifications/token`, notificationToken)
      .pipe(take(1))
      .subscribe({
        next: () => console.log('Notification token saved'),
        error: () => console.warn('No notification token saved')
      });
  }

  async getToken() {
    if (this.platformService.isWeb()) return;

    await PushNotifications.requestPermissions();

    PushNotifications.register();

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ', error);
    });

    PushNotifications.addListener('registration', (token: Token) => {
      this.saveNotificationToken(token.value);
      this.saveTokenToFirestore(token.value);
    });
  }

  // Save the token to firestore
  private saveTokenToFirestore(token) {
    try {
      const userData = this.loginService.rescueSessionDataUser();
      if (!token || !userData || !userData.account.id) return;
      const devicesRef = this.afs.collection('devices');
      const docData = { token, userId: userData.account.id };
      devicesRef.doc(token).set(docData);
    } catch (e) {
      console.log(e);
    }
  }

  public getNotifications(filters = {}) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/user/current/notifications`, filters);
  }

  public openNotification(notification) {
    const handlerNotificationType = this.linksService.linkType[notification.targetType];
    handlerNotificationType(notification);
  }

  public markAsViewed() {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/user/current/notifications/read`, {});
  }
}
