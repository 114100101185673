import { NgModule } from '@angular/core';
import { CheckSameSenderPipe } from './check-same-sender.pipe';
import { ConverToDatePipe } from './converToDate.pipe';
import { FileSizePipe } from './file-size.pipe';
import { FormatTimePipe } from './format-time';
import { InitialsPipe } from './initials.pipe';
import { IsMinePipe } from './is-mine.pipe';
import { LastMessagesFirstPipe } from './last-messages-first.pipe';
import { LinksToButtonsPipe } from './linksToButtons.pipe';
import { NormalizePipe } from './normalize.pipe';
import { PinnedFirstPipe } from './pinned-first.pipe';
import { RelativeTimePipe } from './relative-time.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SanitizePipe } from './sanitize.pipe';
import { SkipArchivedPipe } from './skipArchived.pipe';
import { UrlifyPipe } from './urlify.pipe';

// Add your pipes here for easy indexing.
@NgModule({
  declarations: [
    FormatTimePipe,
    RelativeTimePipe,
    SanitizePipe,
    NormalizePipe,
    FileSizePipe,
    UrlifyPipe,
    InitialsPipe,
    LinksToButtonsPipe,
    ConverToDatePipe,
    CheckSameSenderPipe,
    IsMinePipe,
    PinnedFirstPipe,
    LastMessagesFirstPipe,
    SkipArchivedPipe,
    SafeHtmlPipe
  ],
  imports: [],
  exports: [
    FormatTimePipe,
    RelativeTimePipe,
    SanitizePipe,
    NormalizePipe,
    FileSizePipe,
    UrlifyPipe,
    InitialsPipe,
    LinksToButtonsPipe,
    ConverToDatePipe,
    CheckSameSenderPipe,
    IsMinePipe,
    PinnedFirstPipe,
    LastMessagesFirstPipe,
    SkipArchivedPipe,
    SafeHtmlPipe
  ]
})
export class PipesModule { }
