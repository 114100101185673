import { Component, OnInit } from '@angular/core';
import { NetworkService } from 'src/app/services/network.service';
import { LoadingService } from 'src/app/services/loading.service';
import { InitialConfigService } from 'src/app/services/inital-config.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { ClientService } from 'src/app/services/client.service';
import { introStyleConfig } from 'src/app/models/introStyleModel.model';

@Component({
  selector: 'page-login-step-two',
  templateUrl: 'login-step-two.html',
  styleUrls: ['login-step-two.scss']
})
export class LoginStepTwoPage implements OnInit{
  private username;
  private userData;
  public userClientData;
  public introStyleConfig: introStyleConfig;
  public logo: string;
  public bgColor: string;
  public bgImage: string;
  public welcomeMessage: any;
  
  constructor(
    private router: Router,
    private initialConfig: InitialConfigService,
    private loading: LoadingService,
    private loginService: LoginService,
    public network: NetworkService,
    public clientService: ClientService,
  ) {
    this.userClientData = this.router.getCurrentNavigation().extras.state.data;
    this.username = this.router.getCurrentNavigation().extras.state.username;
  }

  ngOnInit(): void {
    const styles = this.clientService.rescueAppStyleSheet()
    
    if(styles){
      this.setStyles(styles);
    }
  }

  public async selectClient(client) {
    this.userData = { ...client, username: this.username };
    await this.saveUserData(this.userData);
    this.goLoginStepThree(this.userData);
    await this.loading.hide();
  }

  private saveUserData(userData) {
    this.initialConfig.saveUserData(userData);
    const cssStyleSheet = userData.style;
    if (!cssStyleSheet) return;
    this.clientService.saveAppTheme(cssStyleSheet);
    this.clientService.setThemeApplication(cssStyleSheet);
  }

  public goLoginStepThree(userData?) {
    
    if (userData) {
      this.router.navigate(['login/third/'], { state: { username: userData.username, id: userData.id } });
      this.loading.hide();
      return;
    }
    this.router.navigate(['login/third/']);
    this.loading.hide();
  }

  private getThemeApp() {
    return this.initialConfig.styleSheet;
  }
  
  setStyles(styles){
    this.logo = styles.intro.logo;
    this.bgColor = styles.intro.bgColor;
    this.bgImage = styles.intro.bgImage;
    this.welcomeMessage = styles.intro.welcomeMessage;
}

  public goBack() {
    this.router.navigate(['login/first']);
  }
}
