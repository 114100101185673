import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, concatMap, shareReplay, tap } from 'rxjs/operators';
import { Filters } from 'src/app/models/filters.model';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { DocumentService } from 'src/app/services/document.service';
import { EventsService } from 'src/app/services/events.service';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { InitialConfigService } from 'src/app/services/inital-config.service';
import { LoadingService } from 'src/app/services/loading.service';
import { SearcherService } from 'src/app/services/searcher.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'page-full-screen-documents',
  templateUrl: 'full-screen-documents.html'
})
export class FullScreenDocumentsPage implements OnInit {
  public itemType: string = 'document';
  public pageId: number;
  public page$: Observable<any>;
  private $event;
  public filters$: BehaviorSubject<Filters> = new BehaviorSubject({
    searchKey: '',
    start: 0,
    length: 20
  });
  public forbidden: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public docsProvider: DocumentService,
    private loading: LoadingService,
    private platform: Platform,
    private analyticsService: AnalyticsService,
    private searcherService: SearcherService,
    public initialConfig: InitialConfigService,
    public events: EventsService,
  ) { }

  ngOnInit() {
    this.pageId = +this.route.snapshot.paramMap.get('id');
    this.page$ = this.filters$.pipe(
      concatMap((filters) => this.searcherService.allItems(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/documents/${this.pageId}/elements`, filters, this.itemType)),
      tap(() => this.$event && this.$event.target.complete && this.$event.target.complete()),
      tap((response) => this.events.pages$$.next(response.title)),
      tap(() => this.forbidden = false),
      catchError((err: any) => {
        if (err.status === 403) {
          this.forbidden = true;
        }

        throw err;
      }),
      shareReplay()
    );
   //this.registerPageInAnalytics();
  
  }

  ionViewWillEnter() {
    this.getAllItems({ filters: { ...this.filters$.value, start: 0 } });
  }

  public getAllItems({ filters, event = null  }) {
    if (event) this.$event = event;
    this.filters$.next(filters);
  }

  public getDoc($even, page) {
    const doc = $even.item;
    this.docsProvider.getDocumentAndDownload(page.id, doc.id, true);
  }

  registerPageInAnalytics() {
    const paramsToAnalytics = { navigation: 2 };
    const name = 'Lista documentos';
    const id = this.pageId;
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
  }

  public goToAddItem(id) {
    this.router.navigate([`documents/${id}/add`]);
  }

  public refresh() {
    this.getAllItems({ filters: { ...this.filters$.value, start: 0 } });
  }
}
