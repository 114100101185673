import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { GalleryService } from 'src/app/services/gallery.service';
import { BlogService } from 'src/app/services/blog.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Router } from '@angular/router';
import { CommentsPage, LikesPage } from 'src/app/pages';
import { PodcastService } from 'src/app/services/podcast.service';
import { SimulationService } from 'src/app/services/simulation.service';
import { WallService } from 'src/app/services/wall.service';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'toolbar-comments-likes',
  templateUrl: 'toolbar-comments-likes.html',
  styleUrls: ['toolbar-comments-likes.scss']
})
export class ToolbarCommentsLikesComponent {
  @Input() item;
  @Input() idPage;
  @Input() postType;

  @Output() liked = new EventEmitter();
  @Output() commented = new EventEmitter();
  
  public loading = false;
  public defaultProvider;

  constructor(
    public router: Router,
    private blogProvider: BlogService,
    private galleryProvider: GalleryService,
    private podcastService: PodcastService,
    private wallProvider: WallService,
    private analyticsService: AnalyticsService,
    public modalCtrl: ModalController,
    private simulationService: SimulationService
  ) {}

  ngOnInit() {
    this.defaultProvider = this.getDefaultProvider();
  }

  private getDefaultProvider() {
    if (this.postType === 'BLOG') {
      return this.blogProvider;
    }

    if (this.postType === 'GALLERY') {
      return this.galleryProvider;
    }

    if (this.postType === 'PODCAST') {
      return this.podcastService;
    }

    if (this.postType === 'WALL') {
      return this.wallProvider;
    }
  }

  public like(item) {
    this.loading = true
    if (this.simulationService.onSimulationMode()) {
      return;
    }
    if (item.social.likes.liked === true) {
      this.defaultProvider.unlike(this.idPage, item.id)
      .subscribe(
          (newItem) => {
            this.liked.emit({ newItem: newItem })
            this.loading = false;
          }
        );
      return;
    }

    this.defaultProvider.like(this.idPage, item.id)
    .subscribe(
      (newItem) => {
        this.liked.emit({ newItem: newItem });
        this.loading = false;
      }
    );
  }

  public showAlertChooseRss(article) {
    this.blogProvider.shareInRss(article);
  }

  public async goToComments(article) {
    let commentsModal = await this.modalCtrl.create({
      component: CommentsPage,
      componentProps: {
        idPage: this.idPage,
        idArticle: article.id,
        type: this.postType
      }
    });

    commentsModal.onWillDismiss().then(({ data }) => this.callbackComments(data.comments));
    await commentsModal.present();
  }

  public async goToLikes(article) {
    let likesModal = await this.modalCtrl.create({
      component: LikesPage,
      componentProps: {
        idPage: this.idPage,
        idArticle: article.id,
        type: this.postType
      }
    });
    await likesModal.present();
  }

  public callbackComments(count) {
    this.commented.emit({ count: count });
  }
}
