// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-content {
  --overflow: hidden;
}
:host ion-card {
  padding: var(--big-item-padding);
  background-color: var(--ion-color-light);
  box-shadow: none;
  height: calc(100% - 22px);
  overflow: auto;
}
:host ion-card ion-card-content {
  padding: 10px;
  margin-bottom: 0px;
  height: calc(100% - 3.2em);
}
:host ion-card ion-card-content h2 {
  margin-bottom: 16px;
  color: var(--ion-color-dark);
}
:host ion-card ion-card-content p {
  color: var(--ion-color-dark);
}
:host ion-card ion-card-content ion-list {
  margin-top: 16px;
  background: transparent;
}
:host ion-card ion-card-content ion-list ion-item {
  --background: transparent;
  color: var(--ion-color-dark);
  align-items: flex-start;
}
:host ion-card ion-card-content ion-list ion-item ion-label {
  margin: 0;
  --color: var(--ion-color-dark);
  font-size: inherit !important;
}
:host ion-card ion-card-content ion-list ion-item i,
:host ion-card ion-card-content ion-list ion-item ion-icon {
  color: var(--ion-color-dark);
  margin-top: 1px !important;
}
:host ion-card ion-card-content ion-list ion-item p {
  color: var(--ion-color-dark);
  white-space: normal;
}
:host ion-footer .link {
  --color: var(--cms-button-text-color) !important;
  --ion-color-contrast: var(--cms-button-text-color) !important;
  --ion-color-contrast-rgb: var(--cms-button-text-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/calendar-pages/detail-calendar/detail-calendar.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AAEE;EACE,gCAAA;EACA,wCAAA;EACA,gBAAA;EACA,yBAAA;EACA,cAAA;AAAJ;AAEI;EACE,aAAA;EACA,kBAAA;EACA,0BAAA;AAAN;AAEM;EACE,mBAAA;EACA,4BAAA;AAAR;AAGM;EACE,4BAAA;AADR;AAIM;EACE,gBAAA;EACA,uBAAA;AAFR;AAIQ;EACE,yBAAA;EACA,4BAAA;EACA,uBAAA;AAFV;AAIU;EACE,SAAA;EACA,8BAAA;EACA,6BAAA;AAFZ;AAIU;;EAEE,4BAAA;EACA,0BAAA;AAFZ;AAIU;EACE,4BAAA;EACA,mBAAA;AAFZ;AAUI;EACE,gDAAA;EACA,6DAAA;EACA,iEAAA;AARN","sourcesContent":[":host {\n  ion-content {\n    --overflow: hidden;\n  }\n  ion-card {\n    padding: var(--big-item-padding);\n    background-color: var(--ion-color-light);\n    box-shadow: none;\n    height: calc(100% - 22px);\n    overflow: auto;\n\n    ion-card-content {\n      padding: 10px;\n      margin-bottom: 0px;\n      height: calc(100% - 3.2em);\n\n      h2 {\n        margin-bottom: 16px;\n        color: var(--ion-color-dark);\n      }\n\n      p {\n        color: var(--ion-color-dark);\n      }\n\n      ion-list {\n        margin-top: 16px;\n        background: transparent;\n\n        ion-item {\n          --background: transparent;\n          color: var(--ion-color-dark);\n          align-items: flex-start;\n\n          ion-label {\n            margin: 0;\n            --color: var(--ion-color-dark);\n            font-size: inherit !important;\n          }\n          i,\n          ion-icon {\n            color: var(--ion-color-dark);\n            margin-top: 1px !important;\n          }\n          p {\n            color: var(--ion-color-dark);\n            white-space: normal;\n          }\n        }\n      }\n    }\n  }\n\n  ion-footer {\n    .link {\n      --color: var(--cms-button-text-color) !important;\n      --ion-color-contrast: var(--cms-button-text-color) !important;\n      --ion-color-contrast-rgb: var(--cms-button-text-color) !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
