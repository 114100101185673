import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpService } from './http.service';
import { Filters } from '../models/filters.model';
import { Environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CalendarService {
  public calendarInitConfig;

  constructor(private httpService: HttpService) {}

  public getCalendar(id, filters: Filters) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/events/${id}/elements`, filters);
  }

  public getEvent(id) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/events/event/${id}`);
  }

  public orderEventsByMonth(eventsList) {
    let monthsOrderByMonth = eventsList.reduce(this.setEventInMonth, {});
    return Object.keys(monthsOrderByMonth).map((key) => ({ title: key, events: this.reverseOrder(monthsOrderByMonth[key]) }));
  }

  private reverseOrder(events) {
    return events.reverse();
  }

  private setEventInMonth(acc, event): {} {
    const title = moment(event.startDatetime).format('MMMM YYYY');
    if (!acc[title]) {
      acc[title] = [];
    }

    acc[title].push(event);

    return acc;
  }

  public addItem(componentId, data) {
    if (data.targetType !== 'component') {
      data.subTarget = null;
      data.subTargetType = null;
    }
    
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/event/${componentId}/add-element/`, data);
  }

  public editItem(itemId, data) {
    if (data.targetType !== 'component') {
      data.subTarget = null;
      data.subTargetType = null;
    }

    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/event/edit-element/${itemId}`, data);
  }

  public deleteItem(componentId, itemId) {
    return this.httpService.myDelete(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/event/${componentId}/elements/${itemId}`);
  }
}
