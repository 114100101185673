// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item ion-text {
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/users-fields/users-fields.component.scss"],"names":[],"mappings":"AACI;EACI,eAAA;AAAR","sourcesContent":["ion-item{\n    ion-text{\n        max-width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
