import { Entity } from './base-entity.model';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { shareReplay, pluck, filter, map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { IPaginate } from './paginate.interface';
import { IWhere } from './where.interface';
import { Environment } from 'src/environments/environment';


export interface State<T extends Entity> {
  entities: T[];
  loading: boolean;
  error?: Object;
}

export interface EntityAPI {
  path: string;
}

export interface EntityFC {
  path: string;
}

export abstract class BaseEntityService<T extends Entity> {
  private api: EntityAPI;
  public firestoreCollection: EntityFC;

  private type: new (data) => T;

  private _http: HttpClient;
  public _firestore: AngularFirestore;

  protected subject$ = new BehaviorSubject<State<T>>(this.getInitialState());

  state$: Observable<State<T>> = this.subject$.asObservable();
  entities$: Observable<T[]> = this.state$.pipe(pluck('entities'));
  loading$: Observable<boolean> = this.state$.pipe(pluck('loading'));

  selectById(uid): Observable<T> {
    return this.state$.pipe(
      pluck('entities'),
      map(entities => Entity.findById(entities, uid)),
      filter(e => Boolean(e))
    );
  }

  protected abstract getInitialState(): State<T>;
  protected abstract getAPI(): EntityAPI;
  protected abstract getFC(): EntityFC;

  constructor(type: new (data) => T, http: HttpClient, angularFirestore: AngularFirestore, firebase: AngularFireModule) {
    this.api = this.getAPI();
    this.firestoreCollection = this.getFC();
    this.type = type;
    this._http = http;
    this._firestore = angularFirestore;
  }

  get state() {
    return this.subject$.getValue();
  }



  protected upsert(newEntity) {
    const currentEntities = [...this.state.entities];

    const entityIndex = currentEntities.findIndex(currentEntity => currentEntity.uid === newEntity.uid);
    if (entityIndex >= 0) {
      currentEntities[entityIndex] = newEntity;
    } else {
      currentEntities.push(newEntity);
    }

    return currentEntities;
  }

  protected upsertList(newEntities) {
    const currentEntities = [...this.state.entities];
    newEntities.forEach(newEntity => {
      const entityIndex = currentEntities.findIndex(currentEntity => currentEntity.uid === newEntity.uid);
      if (entityIndex >= 0) {
        currentEntities[entityIndex] = newEntity;
      } else {
        currentEntities.push(newEntity);
      }
    });

    return currentEntities;
  }

  protected createModel(entity): T {
    return new this.type(entity);
  }

  protected createList(entities): T[] {
    return entities.map(entity => this.createModel(entity));
  }

  track(index, entity: T): string {
    return entity.uid;
  }

  protected dispatchLoading(state = {}) {
    this.subject$.next({ ...this.state, loading: true, error: null, ...state });
  }

  protected dispatchSuccess(state = {}) {
    this.subject$.next({ ...this.state, loading: false, error: null, ...state });
  }

  protected getRequest(url, params = {}): Observable<T | T[]> {
    return this._http.get<T | T[]>(url, { params }).pipe(shareReplay());
  }

  protected postRequest(url: string, body = {}): Observable<T | T[] | any> {
    return this._http
      .post<T | T[] | { currentPage: number; totalPages: number; items: T[] }>(url, body)
      .pipe(shareReplay());
  }

  protected deleteRequest(url): Observable<{ success: boolean }> {
    return this._http.delete<{ success: boolean }>(url).pipe(shareReplay());
  }

  public getDocument(documentId: string) {
    return this.getDocumentRequest(documentId).subscribe(entity => {
      this.dispatchSuccess({ entities: this.upsert(this.createModel(entity)) });
    });
  }

  public getDocumentRequest(documentId: string) {
    return this._firestore
      .collection(this.firestoreCollection.path)
      .doc(documentId)
      .snapshotChanges()
      .pipe(map((doc: any) => this.createModel({ uid: doc.payload.id, ...doc.payload.data() })));
  }

  public getCollection(where: Array<IWhere> = [], paginate: IPaginate = null, upsert: boolean = false) {
    this.getCollectionRequest(where, paginate).subscribe(entities => {
      if (upsert) entities = this.upsertList(entities);
      else entities = this.createList(entities);
      this.dispatchSuccess({ entities });
    });
  }

  public getCollectionRequest(where: Array<IWhere> = [], paginate: IPaginate = null) {
    return this._firestore
      .collection(this.firestoreCollection.path, ref => this.getFilters(ref, where, paginate))
      .snapshotChanges()
      .pipe(map(c => c.map((doc: any) => this.createModel({ uid: doc.payload.doc.id, ...doc.payload.doc.data() }))));
  }

  private getFilters(
    ref: firebase.default.firestore.CollectionReference | firebase.default.firestore.Query,
    where: Array<IWhere> = [],
    paginate?: IPaginate
  ) {
    for (let i = 0; i < where.length; i++) {
      ref = ref.where(where[i].field, where[i].operator, where[i].value);
    }

    if (paginate && paginate.startAfter && paginate.limit != null) {
      ref = ref.orderBy(paginate.orderBy, 'desc');
      ref = ref.startAfter(paginate.startAfter);
      ref = ref.limit(paginate.limit);
    } else if (paginate && paginate.startAfter == 0 && paginate.limit != null) {
      ref = ref.orderBy(paginate.orderBy, 'desc');
      ref = ref.limit(paginate.limit);
    }
    return ref;
  }

  protected clearState() {
    this.subject$.next(this.getInitialState());
  }
}
