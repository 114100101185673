// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-item h2 {
  font-size: 1.2rem !important;
  line-height: initial;
  margin-inline-start: 1em;
}`, "",{"version":3,"sources":["webpack://./src/app/components/likes-item/likes-item.scss"],"names":[],"mappings":"AAEI;EACE,4BAAA;EACA,oBAAA;EACA,wBAAA;AADN","sourcesContent":[":host {\n  ion-item{\n    h2{\n      font-size: 1.2rem !important;\n      line-height: initial;\n      margin-inline-start: 1em;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
