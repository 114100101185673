import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, concatMap, shareReplay, tap } from 'rxjs/operators';
import { Filters } from 'src/app/models/filters.model';
import { EventsService } from 'src/app/services/events.service';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { SearcherService } from 'src/app/services/searcher.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'page-full-screen-contacts-component',
  templateUrl: 'full-screen-contacts-component.html'
})
export class FullScreenContactsComponentPage implements OnInit {
  public initConfig;
  public endpoint;
  public itemType: string = 'contacts';
  public filters$: BehaviorSubject<Filters> = new BehaviorSubject({
    searchKey: '',
    start: 0,
    length: 20
  });

  private pageId: number;
  private agenda;
  public page$: Observable<any>;
  private $event;

  public id: number;
  public filters: { searchKey: string; start: number; orderBy: string; orderDir: string; length: number };
  title: string;
  public forbidden: boolean = false;

  constructor(
    public navCtrl: NavController,
    public router: Router,
    private searcherService: SearcherService,
    private route: ActivatedRoute,
    public events: EventsService
  ) {}

  ngOnInit() {
    this.pageId = +this.route.snapshot.paramMap.get('id');

    this.agenda = this.route.snapshot.paramMap.get('agenda');

    //this.endpoint = this.agenda ? `${Environment.API_BASE_URL_V2}/api/empatica/v1/components/agenda/${this.pageId}/elements` : GlobalConstService.API_ENDPOINT_GET_CONTACTS_COMPONENT;

    if (this.agenda) {
      this.page$ = this.filters$.pipe(
        concatMap((filters) =>
          this.searcherService.allItems(
            `${Environment.API_BASE_URL_V2}/api/empatica/v1/components/agenda/${this.pageId}/elements`,
            filters,
            this.itemType
          )
        ),
        tap(() => this.$event && this.$event.target.complete && this.$event.target.complete()),
        tap((response) => this.events.pages$$.next(response.title)),
        tap(() => (this.forbidden = false)),
        catchError((err: any) => {
          if (err.status === 403) {
            this.forbidden = true;
          }

          throw err;
        }),
        shareReplay()
      );
    } else {
      this.page$ = this.filters$.pipe(
        concatMap((filters) =>
          this.searcherService.allItems(
            `${Environment.API_BASE_URL_V2}/api/empatica/v1/components/contacts/${this.pageId}/elements`,
            filters,
            this.itemType
          )
        ),
        tap(() => this.$event && this.$event.target.complete && this.$event.target.complete()),
        tap((response) => this.events.pages$$.next(response.title)),
        tap(() => (this.forbidden = false)),
        catchError((err: any) => {
          if (err.status === 403) {
            this.forbidden = true;
          }

          throw err;
        }),
        shareReplay()
      );
    }
  }

  public goToDetail(contact) {
    const agenda = Boolean(contact.item.username) ? '/agenda' : '';
    this.router.navigate([`contacts/detail/${contact.item.id}${agenda}`]);
  }

  public getAllItems({ filters, event = null }) {
    if (event) this.$event = event;
    this.filters$.next(filters);
  }
}
