import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { decode } from 'base64-arraybuffer';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { filter, finalize, map, switchMap } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { DeviceService } from 'src/app/services/device.service';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { LinksService } from 'src/app/services/links.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ProfileService } from 'src/app/services/profile.service';
import { ToastService } from 'src/app/services/toast.service';
import { PromptCameraService } from '../../../services/prompt-camera.service';
import { LoginService } from 'src/app/services/login.service';
import { Photo } from '@capacitor/camera';
import { FileService } from 'src/app/services/file.service';
import { PlatformService } from 'src/app/services/platform.service';
@Component({
  selector: 'page-profile',
  templateUrl: 'profile.html',
  styleUrls: ['profile.scss']
})
export class ProfilePage implements OnInit {
  @ViewChild('fileAvatar') fileAvatar: ElementRef;
  public userGeneralData;

  public accountData$ = new BehaviorSubject(null);
  public accountImg$ = this.accountData$.pipe(
    filter(Boolean),
    map((account: any) => account.imgUrl)
  );
  public versionAndBuild$: Observable<any>;

  public image: Photo = null;
  public base64Image: any = null;

  constructor(
    public router: Router,
    public gConst: GlobalConstService,
    private linkProvider: LinksService,
    private profileProvider: ProfileService,
    private loading: LoadingService,
    private loginService: LoginService,
    private toast: ToastService,
    private cd: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private deviceService: DeviceService,
    private promptCameraService: PromptCameraService,
    private fileService: FileService,
    private platformService: PlatformService
  ) {}

  ngOnInit() {
    this.versionAndBuild$ = from(this.deviceService.getVersionAndBuild());
  }

  ionViewDidEnter() {
    this.analyticsService.registerMyProfilePage();
    this.userGeneralData = this.gConst.userData;
    this.accountData$.next(this.gConst.userData.account);
    console.log('this.userGeneralData: ', this.userGeneralData);
  }

  public goToEdit() {
    this.router.navigate(['profile/edit']);
  }

  public openWebLink($event) {
    if ($event) return;
    this.linkProvider.openInternalLink($event.web);
  }

  private async sendNewProfilePicture(image?) {
    await this.loading.show();

    let imageBlob;
    const base64 = this.base64Image;
    imageBlob = this.fileService.base64ToBlob(base64, `image/${this.image.format}`);

    console.log('sending image');

    this.profileProvider
      .updateProfilePicture(imageBlob)
      .pipe(
        switchMap(() => this.loginService.getUserData()),
        finalize(async () => await this.loading.hide())
      )
      .subscribe({
        next: (account) => {
          this.accountData$.next(account);
          this.gConst.userData.account = account;
        },
        error: () => {
          this.toast.info('FAILED-TO-CHANGE-PROFILE-PICTURE');
        }
      });
  }

  // onFileChange(event) {
  //   let reader = new FileReader();

  //   if (event.target.files && event.target.files.length) {
  //     const [file] = event.target.files;
  //     reader.readAsDataURL(file);

  //     reader.onload = () => {
  //       this.sendNewProfilePicture(reader.result);

  //       // need to run CD since file load runs outside of zone
  //       this.cd.markForCheck();
  //     };
  //   }
  //   this.fileAvatar.nativeElement.value = '';
  // }

  public async selectPicture() {
    const result = await this.promptCameraService.ask();
    switch (result.action) {
      case this.promptCameraService.OPEN_INPUT_FILE:
        this.fileAvatar.nativeElement.click();
        break;
      case this.promptCameraService.REMOVE_IMAGE:
        const remove: any = await this.promptCameraService.removeSheet();
        if (remove && remove.role == 'destructive') {
          this.sendNewProfilePicture(null);
        }
        break;
      default:
        if (result.image) {
          this.image = result.image;

          if (this.platformService.isWeb()) {
            const base64String = await this.fileService.getImageBase64(result.image.webPath);
            let formattedBase64 = base64String.split(',')[1]
            this.base64Image = `data:image/${result.image.format};base64,${formattedBase64}`;
            this.sendNewProfilePicture();
            return
          } 

          const base64String = await this.fileService.convertFilePathToBase64(result.image.path || result.image.webPath);
          this.base64Image = `data:image/${result.image.format};base64,${base64String}`;
          this.sendNewProfilePicture();
        }
        break;
    }
  }

  showDescription(): boolean {
    this.gConst.userData.account.fieldsConfig.some((e) => {
      if (e.name === 'description' && e.visible === false) {
        return false;
      }
    });
    return true;
  }
}
