import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { DetailContactsPage } from '../../pages/contacts-pages/detail-contacts/detail-contacts.page';

import { FullScreenContactsComponentPage } from '../../pages/contacts-pages/full-screen-contacts-component/full-screen-contacts-component.page';
import { NavController } from '@ionic/angular';
import { ContactsService } from 'src/app/services/contacts.service';
import { CheckComponentsService } from 'src/app/services/check-components.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { User } from 'src/app/models/user.model';
import { InitConfig } from 'src/app/models/initConfig.model';
import { Filters } from 'src/app/models/filters.model';
import { Router } from '@angular/router';

@Component({
  selector: 'contacts-component',
  templateUrl: 'contacts-component.html',
  styleUrls: ['contacts-component.scss']
})
export class ContactsComponent {
  data: any = 'init';
  initConfig: object;
  viewActive: any;
  public initialConfigContacts: InitConfig;
  public contacts: any[];
  android: boolean;
  public user: User;
  public showHeader: boolean;
  public itemsFiltered: number;
  public itemsCount: number;
  constructor(
    private contactsProvider: ContactsService,
    public router: Router,
    private checkComponent: CheckComponentsService,
    private analyticsService: AnalyticsService
  ) {
    this.initialConfigContacts = this.contactsProvider.initConfigContacts;
    this.showHeader = this.initialConfigContacts.view.showHeader;
    this.getContacts(this.initialConfigContacts.id);
  }

  getContacts(id) {
    const filters: Filters = {
      searchKey: '',
      start: 0,
      orderBy: this.initialConfigContacts.view.orderBy,
      orderDir: this.initialConfigContacts.view.orderDir
    };
    this.contactsProvider.getContacts(id, filters).subscribe({
      next: (response) => {
        this.contacts = response.items.itemList;
        this.itemsCount = response.items.itemsCount;
        this.itemsFiltered = response.items.itemsFiltered;
        this.contacts = this.checkComponent.setItemsInComponent(this.contacts, this.itemsCount);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  public goToFullScreenContacts() {
    this.router.navigate([`contacts/${this.initialConfigContacts.id}/list`]);
  }

  public goToDetail(contact) {
    this.registerEventInAnalytics(contact);
    this.router.navigate([`contacts/detail/${contact.id}`]);
  }

  private registerEventInAnalytics(document) {
    const paramsToAnalytics = { actions: 6 };
    const name = document.name;
    const id = document.id;
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
  }

  public isButtonView() {
    return this.initialConfigContacts.view.mode === 'button';
  }

  public isListView() {
    return this.initialConfigContacts.view.mode === 'item-list';
  }

  public isVieMoreButtonVisible() {
    if (this.contacts) return this.itemsCount < this.itemsFiltered;
    return false;
  }
}
