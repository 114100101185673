import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';
import { Filters } from '../models/filters.model';
import { InitConfig } from '../models/initConfig.model';
import { AnalyticsService } from './analytics.service';
import { Device } from '@capacitor/device';
import { DownloadFileOptions, DownloadFileResult, Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener, FileOpenerOptions } from '@capacitor-community/file-opener';
// Models
import { GlobalConstService } from './global-const.service';
import { HttpService } from './http.service';
import { LanguageService } from './language.service';
import { LinksService } from './links.service';
import { LoadingService } from './loading.service';
import { PlatformService } from './platform.service';
import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class DocumentService {
  public docsInitConfig: InitConfig;
  private regularExpressionSpecialCharacters = GlobalConstService.PATTERN_SPECIAL_CHARACTERS;
  private regularExpressionWhiteCharacters = GlobalConstService.PATTERN_WHITE_CHARACTERS;
  docName: any;

  constructor(
    private httpService: HttpService,
    private toast: ToastService,
    private languageService: LanguageService,
    private linkService: LinksService,
    private platformService: PlatformService,
    private analyticsService: AnalyticsService,
    private loading: LoadingService,
    private platform: Platform
  ) {}

  public getAllDocuments(filters: Filters) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/documents`, filters);
  }

  public getDocuments(id, filters: Filters) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/documents/${id}/elements`, filters);
  }

  public getDocument(idDoc?: number) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/documents/document/${idDoc}`);
  }

  public async downloadDoc(doc, open = false) {
    try {
      if (this.platformService.isWeb()) {
        this.registerEventInAnalytics(doc);
        //window.location.assign(doc.uri ? doc.uri : doc.url);
        window.open(doc.uri ? doc.uri : doc.url);
        return;
      }

      if (this.platformService.isAndroid()) {
        console.log('android');
        const permissions = await this.getFilesystemAccess();
        if (!permissions) return;
      }

      console.log('doc: ', doc);

      this.registerEventInAnalytics(doc);

      const docName = doc.name ? doc.name : doc.title;
      let docNameCleaned = docName
        ? docName
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace('?', '')
            .replace('¿', '')
            .replace('¡', '')
            .replace('!', '')
            .split(this.regularExpressionWhiteCharacters)
            .join('_')
        : `${moment().toString()}`;

      let extension = doc.extension || 'jpeg';

      const fileName = `${docNameCleaned}.${extension}`;

      const options: DownloadFileOptions = {
        url: doc.uri || doc.url,
        path: fileName,
        directory: Directory.Data
      };

      const downloadedFile: DownloadFileResult = await Filesystem.downloadFile(options);

      console.log('downloadedFile: ', downloadedFile);

      if (downloadedFile.path) {
        try {
          const fileOpenerOptions: FileOpenerOptions = {
            filePath: downloadedFile.path,
            openWithDefault: true
          };
          await FileOpener.open(fileOpenerOptions);
        } catch (error) {
          if (error.status === 8) {
            this.toast.info('NO-AVAILABLE-APPLICATION-CAN-OPEN-THE-FILE');
          }

          console.log('Error opening file', error);
        }
      }
    } catch (e) {
      this.toast.info('ERROR-DOWNLOADING-THE-FILE');
      console.log(e);
    }
  }

  private registerEventInAnalytics(document) {
    const paramsToAnalytics = { actions: 3 };
    const name = document.name;
    const id = document.id;
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
  }

  private setMIMETypeToOpenFile(extension) {
    if (extension === 'jpeg' || extension === 'jpg' || extension === 'png' || extension === 'gif') {
      return `image/${extension}`;
    }
    if (extension === 'xls' || extension === 'xlm') {
      return 'application/vnd.ms-excel';
    }
    if (extension === 'xlsx') {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    if (extension === 'doc') {
      return 'application/msword';
    }
    if (extension === 'docx') {
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    }
    if (extension === 'odp') {
      return 'application/vnd.oasis.opendocument.presentation';
    }
    if (extension === 'ods') {
      return 'application/vnd.oasis.opendocument.spreadsheet';
    }
    if (extension === 'ppt') {
      return 'application/vnd.ms-powerpoint';
    }
    if (extension === 'pptx') {
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    }
    if (extension === 'mp4') {
      return 'video/mp4';
    }
    if (extension === 'mp3') {
      return 'audio/mpeg';
    }

    return `application/${extension}`;
  }

  public async openExternal(url) {
    this.linkService.openInternalLink(url);
  }

  public uploadProfilePicture() {}

  public async getDocumentAndDownload(idPage?: number, idDoc?: number, open = false) {
    await this.loading.show();
    this.getDocument(idDoc)
      .pipe(take(1))
      .subscribe({
        next: async (doc) => {
          console.log('getDocumentAndDownload doc: ', doc);

          await this.platform.ready();
          try {
            await this.downloadDoc(doc, open);
          } catch (error) {
            console.error('Error downloading doc', error);
          }
          await this.loading.hide();
        },
        error: async (err) => {
          console.error(err);
          await this.loading.hide();
        }
      });
  }

  public addItem(componentId, data) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/documents/${componentId}/add-element/`, data);
  }

  public editItem(itemId, data) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/documents/edit-element/${itemId}`, data);
  }

  public deleteItem(componentId, itemId) {
    return this.httpService.myDelete(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/${componentId}/documents/document-element/${itemId}`);
  }

  private getFilesystemAccess(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const status = await Filesystem.checkPermissions();
      const state = status.publicStorage;

      if (state === 'granted') {
        return resolve(true);
      } else if (state === 'denied') {
        // You make want to redirect to the main app settings.
      } else {
        Filesystem.requestPermissions();
      }
      return resolve(false);
    });
  }
}
