import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SimulationService {
  private mode: string = 'default';

  constructor() { }

  activateSimulationMode(): void {
    this.mode = 'simulation';
  }

  deactivateSimulationMode(): void {
    this.mode = 'default';
  }

  onSimulationMode(): boolean {
    return this.mode === 'simulation';
  }

}
