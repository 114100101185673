import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { PlatformService } from 'src/app/services/platform.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss']
})
export class NotifyComponent implements OnInit {
  public notifications: any[] = [];
  public itemsCount;
  public itemsFiltered;
  public itemsNotViewedCount: number;

  constructor(
    public router: Router,
    private notifyProvider: NotificationService,
    private analyticsService: AnalyticsService,
    private platformService: PlatformService
  ) {}

  ngOnInit() {
    this.updateNotifications();
  }

  public openNotification(notification) {
    this.notifyProvider.openNotification(notification.action);
  }

  trackByFn(index, item) {
    return item.id;
  }

  private updateNotifications() {
    const notifications$ = this.platformService.isMobile()
      ? this.notifyProvider.getNotifications()
      : timer(0, 20000).pipe(switchMap(() => this.notifyProvider.getNotifications()));

    notifications$.subscribe({
      next: (res) => {
        this.notifications = res.items.itemList;
        this.itemsCount = res.items.itemsCount;
        this.itemsFiltered = res.items.itemsFiltered;
        this.itemsNotViewedCount = res.items.itemsNotViewedCount;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  public loadMore($event) {
    this.updateNotifications();
  }

  registerEventInAnalytics(notification) {
    const paramsToAnalytics = { actions: 2 };
    const name = notification.title;
    const id = notification.id;
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
  }
}
