import { Component, Input } from '@angular/core';
import { DetailCalendarPage } from '../../../pages/calendar-pages/detail-calendar/detail-calendar.page';
import { AnimationList } from '../../animations/animationList';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'event-by-month',
  templateUrl: 'event-by-month.html',
  styleUrls: ['event-by-month.scss'],
  animations: AnimationList
})
export class EventByMonthComponent {
  @Input('titleMonth')
  titleMonth: string;

  @Input('listOfEvents')
  listOfEvents: Array<any>;

  @Input('title')
  title: string;

  @Input('componentId')
  componentId: any;

  @Input('order')
  order: any;

  public offsetDays;
  public orderedItems;

  constructor(public router: Router) { }

  ngOnInit() {
    this.orderEvents();
  }

  public orderEvents(){
  this.orderedItems = this.listOfEvents.sort();
  }

  public goToDetailEvent(event) {
    event.pageTitle = this.title;
    this.router.navigate([`calendar/${this.componentId}/detail/${event.id}`]);
  }

  public trackEvent(event) {
    return event.title;
  }

  public isDiferentDay(startStr, endStr){

    const start = new Date(startStr);
    const end = new Date(endStr);

    if (
      start.getFullYear() === end.getFullYear() &&
      start.getMonth() === end.getMonth() &&
      start.getDate() === end.getDate()
    ) {
      return false;
    }

    const timeDifference = Math.abs(end.getTime() - start.getTime());
    this.offsetDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return true;
   
  }

}
