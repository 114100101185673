import { Component, Input } from '@angular/core';
@Component({
  selector: 'input-error',
  templateUrl: 'input-error.html'
})
export class InputErrorComponent {
  @Input()
  control;

  @Input()
  message;

  constructor() {}

  getFirstError(errors) {
    return Object.keys(errors)[0];
  }
}
