import { Component, ViewChild, OnInit, ElementRef,  } from '@angular/core';
import { NavController, NavParams, AlertController, ModalController, IonicSlides } from '@ionic/angular';
import { DocumentService } from 'src/app/services/document.service';
import { GalleryService } from 'src/app/services/gallery.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { AlertButton, AlertOptions } from '@ionic/core';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { map, concatMap, tap, filter, shareReplay } from 'rxjs/operators';
import { InitConfig } from 'src/app/models/initConfig.model';
import { EventsService } from 'src/app/services/events.service';
import { LanguageService } from 'src/app/services/language.service';
import { PlatformService } from 'src/app/services/platform.service';
import { Swiper } from 'swiper/types';
import { register } from 'swiper/element/bundle';


@Component({
  selector: 'gallery-modal',
  templateUrl: 'gallery-modal.html',
  styleUrls: ['gallery-modal.scss']
})
export class GalleryModalComponent implements OnInit {

  swiperModules = [IonicSlides];

  public indexActiveSlide$: BehaviorSubject<any> = new BehaviorSubject(0);
  public images$: BehaviorSubject<any> = new BehaviorSubject([]);
  public imagesSubscription: Subscription;
  public analyticsSubscription: Subscription;
  public positionSelectedPicture: any;

  public mainViewer = {
    slidesPerView: 1,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
      bulletClass: 'swiper-pagination-bullet',
      dynamicBullets: true
    }
  };

  public thumbsViewer: any = {
    slidesPerView: 4.5,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    spaceBetween: 5,
    centeredSlides: true
  };

  public idPage;
  public initConfig: InitConfig;
  public canEdit$;
  public pageItemList$: Observable<any>;
  public userHasPermission$: any;
  page$: Observable<any>;

  @ViewChild('mainslider') mainsliderRef: ElementRef | undefined;
  @ViewChild('thumbnailslider') thumbnailsliderRef: ElementRef | undefined;
  swiperMain?: Swiper;
  swiperThumb?: Swiper;

  constructor(
    private navParams: NavParams,
    private platformService: PlatformService,
    private docsProvider: DocumentService,
    private galleryProvider: GalleryService,
    private alertCtrl: AlertController,
    private analyticsService: AnalyticsService,
    private modalCtrl: ModalController,
    private gConst: GlobalConstService,
    private events: EventsService,
    private languageService: LanguageService
  ) {}
  
  swiperMainReady(){
    this.swiperMain = this.mainsliderRef?.nativeElement.swiper
  }

  swiperThumbReady(){
    this.swiperThumb = this.thumbnailsliderRef?.nativeElement.swiper
  }

  goNext(){
    this.swiperMain.slideNext();
    this.swiperThumb.slideNext();
  }

  goPrev(){
    this.swiperMain.slidePrev();
    this.swiperThumb.slidePrev();
  }

  ngOnInit() {
    this.addBreakpointsToThumbnailsViewer();

    this.positionSelectedPicture = this.navParams.get('position');
    this.idPage = this.navParams.get('initConfig').id;
    this.initConfig = this.navParams.get('initConfig');

    register();

    this.swiperMainReady();
    this.swiperThumbReady();

    this.page$ = this.galleryProvider
      .getGallery(this.idPage, {
        searchKey: '',
        start: 0,
        length: this.initConfig.items.itemsCount
      })
      .pipe(shareReplay());

    this.pageItemList$ = this.page$.pipe(map((response) => response.items.itemList));

    this.imagesSubscription = this.pageItemList$.subscribe((images) => {
      this.images$.next(images);

      if (!this.positionSelectedPicture) return;
      this.mainsliderRef.nativeElement.swiper.slideTo(this.positionSelectedPicture, 500);
      this.thumbnailsliderRef.nativeElement.swiper.slideTo(this.positionSelectedPicture, 500);
    });

    this.userHasPermission$ = this.page$.pipe(map((response) => response.permissions));

    this.analyticsSubscription = this.images$
      .pipe(
        filter((items) => items.length),
        concatMap(() => this.indexActiveSlide$)
      )
      .subscribe((i) => {
        this.analyticsService.registerMedia(this.images$.value[i].id, this.images$.value[i].name);
      });

    this.canEdit$ = this.images$.pipe(
      filter((items) => items.length),
      concatMap(() =>
        this.indexActiveSlide$.pipe(
          filter((index) => this.images$.value[index] !== null),
          map((index) => this.images$.value[index].authorId === this.gConst.userData.account.id)
        )
      ),
      concatMap((isMine: boolean) =>
        this.userHasPermission$.pipe(
          map((userPermission: any) => {
            return userPermission.super || (userPermission.edit && isMine);
          })
        )
      )
    );
  }

  private addBreakpointsToThumbnailsViewer() {
    if (window.innerWidth >= 1024) {
      this.thumbsViewer.breakpoints = {
        1024: {
          slidesPerView: 10,
          spaceBetween: 10
        }
      };
    }
  }

  ngOnDestroy() {
    this.imagesSubscription.unsubscribe();
    this.analyticsSubscription.unsubscribe();
  }

  ionViewDidEnter() {
    if (!this.positionSelectedPicture) return;

    this.swiperMain?.slideTo(this.positionSelectedPicture, 500);
    this.swiperThumb?.slideTo(this.positionSelectedPicture, 500);
  }

   slideChanged(e: any) {
    console.log('event: ',e.detail[0].activeIndex);
    
    const index = e.detail[0].activeIndex;
    this.indexActiveSlide$.next(index);
    this.thumbnailsliderRef.nativeElement.swiper.slideTo(index, 500);
    console.log('event 2: ',index);
    
  }

  public goToSlide(e:any) {
    console.log('goToSlide: ',e);


    this.indexActiveSlide$.next(e);
    this.mainsliderRef.nativeElement.swiper.slideTo(e);

    console.log('goToSlide2:', e);
    
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }



  public updateLikedItem(liked, i) {
    this.images$.value[i].social.likes = liked.newItem.social.likes;
  }

  public updateCommentedItem(count, i) {
    this.images$.value[i].social.comments.count = count.count;
  }

  public downloadImage() {
    const images = this.images$.value;
    let index = this.indexActiveSlide$.value;
    index = index ? index : 0;
    const image = images[index];
    image.uri = image.url;
    this.docsProvider.downloadDoc(image, true);
  }

  public async confirmDeleteItem() {
    const alertButtons: AlertButton[] = [
      {
        text: await this.languageService.translate('CANCEL'),
        role: 'cancel'
      },
      {
        text: await this.languageService.translate('DELETE'),
        handler: () => this.deleteItem()
      }
    ];

    const alertOptions: AlertOptions = {
      header: await this.languageService.translate('ARE-YOU-SURE-YOU-WANT-TO-DELETE-THIS-ITEM'),
      buttons: alertButtons
    };
    const alert = await this.alertCtrl.create(alertOptions);

    await alert.present();
  }

  public deleteItem() {
    let index = this.indexActiveSlide$.value;
    const idItem = this.images$.value[index].id;
    this.galleryProvider.deleteItem(this.idPage, idItem).subscribe(() => {
      this.events.nav.next({ route: `/gallery/${this.idPage}/list`, refreshHome: true });
      this.closeModal();
    });
  }
  public isVideo(image: any) {
    return image && image.type && image.type === 'video';
  }

  public currentIsImage() {
    const index = this.indexActiveSlide$.value;
    return index === undefined || index === null || !this.images$.value[index] ? false : this.images$.value[index].type != 'video';
  }

  public watchVideo(image) {
    this.registerEventInAnalytics(image);

    if (image.sourceType === 'youtube') {
      this.galleryProvider.openYoutubeVideo(image);
    }
    if (image.sourceType === 'vimeo') {
      this.galleryProvider.openVimeoVideo(image);
    }
    if (image.extension === 'mp4' && this.platformService.isWeb()) {
      this.galleryProvider.openNativeVideo(image);
    }
    if (image.extension === 'mp4' && !this.platformService.isWeb()) {
      this.docsProvider.downloadDoc(image, true);
    }
  }

  private registerEventInAnalytics(media) {
    const paramsToAnalytics = { actions: 4 };
    const name = media.name;
    const id = media.id;
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
  }

  imAuthor() {
    const index = this.indexActiveSlide$.value;
    return this.images$.value[index].authorId == this.gConst.userData.account.id;
  }

  getCurrentImageTitle() {
    const index = this.indexActiveSlide$.value;
    if (index === null) return;
    const image = this.images$.value[index];
    if (!image) return;
    return this.images$.value[index].name;
  }
}
