// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `page-comments .back-button {
  display: block;
}

:host-context(.plt-desktop) {
  max-width: 600px;
  max-height: 90vh;
  margin: auto;
}

ion-item-options {
  padding-bottom: 10px;
}
ion-item-options ion-item-option {
  background: white;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/comments/comments.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;;AAIA;EACE,gBAAA;EACA,gBAAA;EACA,YAAA;AADF;;AAIA;EACE,oBAAA;AADF;AAEE;EACE,iBAAA;EACA,YAAA;AAAJ","sourcesContent":["page-comments {\n  .back-button {\n    display: block;\n  }\n}\n\n:host-context(.plt-desktop) {\n  max-width: 600px;\n  max-height: 90vh;\n  margin: auto;\n}\n\nion-item-options{\n  padding-bottom: 10px;\n  ion-item-option{\n    background: white;\n    color: black;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
