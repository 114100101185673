// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .photo-placeholder {
  --padding-start: 0;
  width: 100%;
  margin-bottom: 1em;
  cursor: pointer;
  --inner-padding-end: 10px;
  --inner-padding-start: 10px;
  --inner-padding-top: 15px;
  position: relative;
}
:host .photo-placeholder .photo-container {
  position: relative;
  width: 100%;
}
:host .photo-placeholder ion-icon {
  position: absolute;
  top: 7px;
  right: 7px;
  border-radius: 50%;
  padding: 11px;
  height: 30px;
  width: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: #f4f5f8;
  color: var(--cms-button-bg-color);
  box-shadow: 0 0 8px 3px #151515;
  z-index: 10;
}
:host .photo-placeholder img {
  width: 100%;
  max-height: 200px;
  text-align: center;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/gallery-pages/gallery-add-item/gallery-add-item.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,WAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;EACA,2BAAA;EACA,yBAAA;EACA,kBAAA;AAAJ;AAEI;EACE,kBAAA;EACA,WAAA;AAAN;AAGI;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;EACA,wBAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,iCAAA;EACA,+BAAA;EACA,WAAA;AADN;AAII;EACE,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;AAFN","sourcesContent":[":host {\n  .photo-placeholder {\n    --padding-start: 0;\n    width: 100%;\n    margin-bottom: 1em;\n    cursor: pointer;\n    --inner-padding-end: 10px;\n    --inner-padding-start: 10px;\n    --inner-padding-top: 15px;\n    position: relative;\n\n    .photo-container{\n      position: relative;\n      width: 100%;\n    }\n\n    ion-icon {\n      position: absolute;\n      top: 7px;\n      right: 7px;\n      border-radius: 50%;\n      padding: 11px;\n      height: 30px;\n      width: 30px;\n      display: flex !important;\n      align-items: center;\n      justify-content: center;\n      background-color: #f4f5f8;\n      color: var(--cms-button-bg-color);\n      box-shadow: 0 0 8px 3px #151515;\n      z-index: 10;\n    }\n\n    img {\n      width: 100%;\n      max-height: 200px;\n      text-align: center;\n      object-fit: cover;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
