// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `page-likes .back-button {
  display: block;
}

:host-context(.plt-desktop) {
  max-width: 600px;
  max-height: 90vh;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/likes/likes.scss"],"names":[],"mappings":"AACE;EACE,cAAA;AAAJ;;AAIA;EACE,gBAAA;EACA,gBAAA;EACA,YAAA;AADF","sourcesContent":["page-likes {\n  .back-button {\n    display: block;\n  }\n}\n\n:host-context(.plt-desktop) {\n  max-width: 600px;\n  max-height: 90vh;\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
