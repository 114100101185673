import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss']
})
export class ImageLoaderComponent implements OnInit {
  @Input() src = null;
  public loading: boolean = true;

  constructor() {}

  ngOnInit() {}

  onLoad() {
    this.loading = false;
  }
}
