export namespace Environment {
  export const firebase = {
    apiKey: 'AIzaSyBO7jtPn2BR22ZaLy3hajR0zR-XIUyG8q0',
    authDomain: 'empatica-pro.firebaseapp.com',
    databaseURL: 'https://empatica-pro.firebaseio.com',
    projectId: 'empatica-pro',
    storageBucket: 'empatica-pro.appspot.com',
    messagingSenderId: '246523865686'
  };
  export const loading = { spinner: 'circles' };
  export const toast: { position: 'bottom' | 'top' | 'middle' } = { position: 'bottom' };
  export const toastDuration = 5000;
  export const firebaseRoomCollection: string = 'rooms/';
  export const firebaseUserCollection: string = 'users/';
  export const API_BASE_URL_V2 = 'https://api.empatica.io';
  export const environmentStatus: 'prod' | 'demo' = 'prod';
  export const platform: 'mobile' | 'web' = 'mobile';
  export const CLIENT_ID = null;
  export const AVAILABLE_LOGINS = ['THREE_STEPS_LOGIN'];
  export const LOGIN_TERMS = false;
  export const AZURE_OAUTH_TENANT = null;
  export const AZURE_OAUTH_CLIENT_ID = null;
  export const AVAILABLE_LANGUAGES = ['es', 'pt', 'pl', 'en'];
  export const DEFAULT_LANGUAGE = 'es';
  export const REGISTER = false;
}
