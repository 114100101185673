import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { take } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';
import { GlobalConstService } from '../services/global-const.service';
import { BaseEntityService, EntityAPI, State } from './base-entity.service';
import { Onboarding } from './onboarding.model';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService extends BaseEntityService<Onboarding> {
  constructor(http: HttpClient, firestore: AngularFirestore, firebase: AngularFireModule, private globalConst: GlobalConstService) {
    super(Onboarding, http, firestore, firebase);
  }

  getAPI(): EntityAPI {
    return {
      path: 'chatbots/'
    };
  }

  getFC() {
    return {
      path: 'rooms/'
    };
  }

  getInitialState(): State<Onboarding> {
    return {
      entities: [],
      loading: false,
      error: null
    };
  }

  sendMessage(message: string, onboardingId: string) {
    return this.postRequest(`${Environment.API_BASE_URL_V2}${this.getAPI().path}${onboardingId}`, {
      message: message
    })
    .pipe(take(1))
    .subscribe();;
  }

  clearOnboardings() {
    this.clearState();
  }
}
