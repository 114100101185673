import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Generated class for the SanitizePipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'sanitize',
})
export class SanitizePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}
  /**
   * Takes a value and makes it lowercase.
   */
  transform(url: string, type:any) {
    //return this.sanitizer.bypassSecurityTrustUrl(url);
    if (!url) {
      return
    }
    let method;
    switch (type) {
      default:
      case 'url':
        method = 'bypassSecurityTrustResourceUrl';
        break;
      case 'HTML':
        method = 'bypassSecurityTrustHtml';
        break;
    }
    return this.sanitizer[method](url);
  }
}
