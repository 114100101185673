// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link {
  cursor: pointer;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/contacts-pages/detail-contacts/detail-contacts.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,0BAAA;AACF","sourcesContent":[".link{\n  cursor: pointer;\n  text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
