import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, concatMap, shareReplay, tap } from 'rxjs/operators';
import { Filters } from 'src/app/models/filters.model';
import { EmbeddedService } from 'src/app/services/embedded.service';
import { EventsService } from 'src/app/services/events.service';
import { SearcherService } from 'src/app/services/searcher.service';
import { Environment } from 'src/environments/environment';


@Component({
  selector: 'page-full-screen-embeddings',
  templateUrl: 'full-screen-embeddings.html'
})
export class FullScreenEmbeddingsPage implements OnInit {
  public itemType: string = 'embedded';
  public filters$: BehaviorSubject<Filters> = new BehaviorSubject({
    searchKey: '',
    start: 0,
    length: 20
  });

  public pageId;
  public page$: Observable<any>;
  public forbidden: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private searcherService: SearcherService,
    private embbdededService: EmbeddedService,
    private events: EventsService
  ) { }

  ngOnInit() {
    this.pageId = +this.route.snapshot.paramMap.get('id');
    this.page$ = this.filters$.pipe(
      concatMap((filters) => this.searcherService.allItems(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/embedded/${this.pageId}/elements`, filters, this.itemType)),
      tap((response) => this.events.pages$$.next(response.title)),
      tap(() => this.forbidden = false),
      catchError((err: any) => {
        if (err.status === 403) {
          this.forbidden = true;
        }

        throw err;
      }),
      shareReplay()
    );
  }

  public getAllItems({ filters }) {
    this.filters$.next(filters);
  }
}
