import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire/compat';
import { Environment } from 'src/environments/environment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpInterceptorService } from './services/interceptor-http.service';
import { ComponentsModule } from './components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Components } from './components';
import { QuillModule } from 'ngx-quill';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { CommonModule } from '@angular/common';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicSelectableComponent } from 'ionic-selectable';
import { UiScrollModule } from 'ngx-ui-scroll';
import { ToastrModule } from 'ngx-toastr';
import { FileChooser } from '@awesome-cordova-plugins/file-chooser/ngx';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import { Media } from '@awesome-cordova-plugins/media/ngx';
import { MusicControls } from '@awesome-cordova-plugins/music-controls/ngx';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { DocumentPicker } from '@awesome-cordova-plugins/document-picker/ngx';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(Environment.firebase),
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFirestoreModule,
        HttpClientModule,
        ComponentsModule,
        QuillModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
        IonicSelectableComponent,
        UiScrollModule,
        ToastrModule.forRoot()
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true
        },
        FileTransfer,
        File,
        AndroidPermissions,
        PhotoViewer,
        InAppBrowser,
        Calendar,
        DocumentPicker,
        FileChooser,
        FilePath,
        Media,
        MusicControls,
        CallNumber,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
