// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  width: 100%;
}
:host .new-message-container {
  padding: 1rem 1rem 0rem;
  width: 100%;
  position: relative;
}
:host .new-message-input {
  border: 1px solid var(--ion-color-light);
  background-color: white;
  color: var(--ion-color-medium);
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
:host .new-message-input:hover {
  background-color: var(--ion-color-light);
}
:host .new-message-input i {
  font-size: 125%;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/wall/add-wall-item-input/add-wall-item-input.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;AACF;AACI;EACE,uBAAA;EACA,WAAA;EACA,kBAAA;AACN;AAEI;EACE,wCAAA;EACA,uBAAA;EACA,8BAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AAAN;AAEM;EACE,wCAAA;AAAR;AAGM;EACE,eAAA;EACA,kBAAA;AADR","sourcesContent":[":host {\n  display: block;\n  width: 100%;\n  .new-message {\n    &-container {\n      padding: 1rem 1rem 0rem;\n      width: 100%;\n      position: relative;\n    }\n\n    &-input {\n      border: 1px solid var(--ion-color-light);\n      background-color: white;\n      color: var(--ion-color-medium);\n      border-radius: 8px;\n      padding: 10px 15px;\n      font-size: 14px;\n      display: flex;\n      align-items: center;\n      cursor: pointer;\n\n      &:hover {\n        background-color: var(--ion-color-light);\n      }\n\n      i {\n        font-size: 125%;\n        margin-right: 10px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
