// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host div.avatar-container {
  position: relative;
}
:host div.avatar-container i {
  position: absolute;
  top: 23px;
  right: -4px;
  border-radius: 50%;
  padding: 11px;
  height: 30px;
  width: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: #f4f5f8;
  color: var(--cms-button-bg-color);
  box-shadow: 0 0 8px 3px #b8b8b8;
  z-index: 10;
}

.app_version {
  text-align: center;
  color: #c0c0c0;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/profile-pages/profile/profile.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AACI;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;EACA,wBAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,iCAAA;EACA,+BAAA;EACA,WAAA;AACN;;AAIA;EACE,kBAAA;EACA,cAAA;AADF","sourcesContent":[":host {\n  div.avatar-container {\n    position: relative;\n    i {\n      position: absolute;\n      top: 23px;\n      right: -4px;\n      border-radius: 50%;\n      padding: 11px;\n      height: 30px;\n      width: 30px;\n      display: flex !important;\n      align-items: center;\n      justify-content: center;\n      background-color: #f4f5f8;\n      color: var(--cms-button-bg-color);\n      box-shadow: 0 0 8px 3px #b8b8b8;\n      z-index: 10;\n    }\n  }\n}\n\n.app_version {\n  text-align: center;\n  color: #c0c0c0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
