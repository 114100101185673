import { Component, OnInit, OnChanges, Input } from '@angular/core';

// Pages
import { InitConfig } from 'src/app/models/initConfig.model';
import { CheckComponentsService } from 'src/app/services/check-components.service';
import { NetworkService } from 'src/app/services/network.service';
import { Filters } from 'src/app/models/filters.model';
import { Router } from '@angular/router';
import { WallService } from 'src/app/services/wall.service';

@Component({
  selector: 'wall',
  templateUrl: 'wall.html',
  styleUrls: ['wall.scss']
})
export class WallComponent {
  public initialConfigWall: InitConfig;
  public wallListElements: Array<any> = [];
  public isEmpty: boolean = false;
  public showHeader: boolean;
  public showViewMoreBtn: boolean = false;
  public maxCharLength: number = 250;
  public inputPlaceholder: string;

  private errorInComponentWall: { haveError: boolean; textError: string } = {
    haveError: false,
    textError: ''
  };

  constructor(
    private router: Router,
    private wallProvider: WallService,
    private checkComponent: CheckComponentsService,
    public network: NetworkService
  ) {
    this.initialConfigWall = this.wallProvider.wallConfig;
    this.showHeader = this.initialConfigWall.view.showHeader;
    this.getDataWall();
  }

  private getDataWall() {
    const filters: Filters = {
      searchKey: '',
      start: 0,
      length: this.initialConfigWall.view.maxNumItems
    };

    this.wallProvider.getDataWall(this.initialConfigWall.id, filters).subscribe({
      next: (response: any) => {
        this.wallListElements = this.checkComponent.setItemsInComponent(response.items.itemList, response.items.itemsCount);
        this.showViewMoreBtn = response.items.itemsCount > response.items.itemsFiltered;
        this.initialConfigWall.items = response.items;
        this.initialConfigWall.permissions = response.permissions;
        this.maxCharLength = response.maxCharLength;
        this.inputPlaceholder = response.inputPlaceholder;
        this.isEmpty = this.checkComponent.checkEmptyComponent(this.wallListElements);
      },
      error: (error) => {
        console.error('Error getting data wall', error);
      }
    });
  }

  public refresh() {
    this.getDataWall();
  }

  public goToFullWall() {
    this.router.navigate([`/wall/${this.initialConfigWall.id}/list`]);
  }

  public isButtonView() {
    return this.initialConfigWall.view.mode === 'button' && !this.errorInComponentWall.haveError;
  }
}
