import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkSameSender'
})
export class CheckSameSenderPipe implements PipeTransform {

  transform(currentMessage, previousMessage): boolean {
    if (previousMessage == undefined) {
      return true;
    }

    return currentMessage.sender !== previousMessage.sender;
  }

}
