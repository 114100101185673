import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { InitConfig } from 'src/app/models/initConfig.model';
import { Post } from 'src/app/models/post.model';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { BlogService } from 'src/app/services/blog.service';
import { DocumentService } from 'src/app/services/document.service';
import { GalleryService } from 'src/app/services/gallery.service';

@Component({
  selector: 'blog-item',
  templateUrl: 'blog-item.html',
  styleUrls: ['blog-item.scss']
})
export class BlogItemComponent{
  @Input() idPage;
  @Input() article;
  @Input() spaceBetweenElements;
  @Input() viewStyle;
  public initialConfigBlog: InitConfig;


  constructor(
    private router: Router,
    private blogProvider: BlogService,
    private galleryService: GalleryService,
    private docsService: DocumentService,
    private analyticsService: AnalyticsService,
  ) { 
    this.initialConfigBlog = this.blogProvider.blogConfig;

  }
  
  public goToDetailBlog(article: Post) {
    this.registerEventInAnalytics(article);
    this.router.navigate([`/blog/${this.article.componentId}/detail/${article.id}`]);
  }

  public viewMedia(media) {
    media = { ...media, url: media.headerImg };

    if (media.sourceType === 'youtube') {
      this.galleryService.openYoutubeVideo(media);
      return;
    }
    if (media.sourceType === 'vimeo') {
      this.galleryService.openVimeoVideo(media);
      return;
    }
    if (media.extension === 'mp4') {
      this.docsService.downloadDoc(media, true);
      return;
    }

    this.galleryService.showViewer(media.headerImg);
  }

  private registerEventInAnalytics(article) {
    const paramsToAnalytics = { actions: 8 };
    const name = article.title;
    const id = article.id;
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
  }

  public updateLikedItem(liked) {
    this.article = liked.newItem;
  }

  public updateCommentedItem(count) {
    this.article.social.comments.count = count.count;
  }
  
}
