import { Injectable } from '@angular/core';
import { GlobalConstService } from './global-const.service';
import { HttpService } from './http.service';
import { InitConfig } from '../models/initConfig.model';
import { Filters } from '../models/filters.model';
import { ToastService } from './toast.service';
import { Post } from '../models/post.model';
import { AlertController, Platform } from '@ionic/angular';
import { LanguageService } from './language.service';
import { Share, ShareOptions } from '@capacitor/share';
import { Clipboard } from '@capacitor/clipboard';
import { Environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class BlogService {
  public blogConfig: InitConfig;

  constructor(
    private httpService: HttpService,
    public toast: ToastService,
    private alertController: AlertController,
    private platform: Platform,
    private languageService: LanguageService
  ) {}

  public getDataBlog(id, filters: Filters) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/blog/${id}/elements`, filters);
  }

  public getArticle(idArticle) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/blog/blog-post/${idArticle}`);
  }

  public async shareInRss(articleToShare?: Post) {
    const articlePreparedToShare = this.preparePostToShareInRss(articleToShare);
    if (this.platform.is('desktop')) {
      const alert = await this.alertController.create({
        cssClass: 'share-alert',
        header: await this.languageService.translate('SHARE'),
        message: await this.languageService.translate('COPY-THE-LINK-TO-THE-CLIPBOARD'),
        buttons: [
          {
            text: await this.languageService.translate('CANCEL'),
            role: 'cancel',
            cssClass: 'secondary'
          },
          {
            text: await this.languageService.translate('COPY'),
            handler: () => Clipboard.write({ string: articlePreparedToShare.text })
          }
        ]
      });

      await alert.present();

      return;
    }

    try {
      let shareRet = await Share.share(articlePreparedToShare);
    } catch (e) {
      console.log(e);
    }
  }

  private preparePostToShareInRss(articleToShare?: Post): ShareOptions {
    const title = articleToShare.title ?? '';
    const summary = articleToShare.summary ?? '';
    const textToShare = `${title}
    
${summary}
    
🌍 ${articleToShare.sharedURL}`;

    return {
      title: articleToShare.title ? articleToShare.title : articleToShare.sharedURL,
      text: textToShare,
      url: articleToShare.sharedURL,
      dialogTitle: articleToShare.title
    };
  }

  //Old API method
  public addItem(componentId, data) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/content/blog/${componentId}/add-element`, data);
  }

  //New API Method
  public addNewPost(componentId, data) {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (data[key] === null) {
        return;
      }

      if (key !== 'headerImg') {
        formData.append(key, data[key]);
      }
      console.log('addNewPost: ', key, ' : ', data[key]);
    });

    if (data.headerImg !== null) {
      formData.append('headerImg', data.headerImg);
    }

    formData.forEach((value, key) => {
      console.log(`formData - ${key}: ${value}`);
    });

    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/blog/${componentId}/add-element`, formData);
  }

  public removeItem(componentId, itemId) {
    return this.httpService.myDelete(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/blog/blog-post/${itemId}`);
  }

  public editItem(itemId, data) {
    return this.httpService.myPut(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/blog/blog-post/${itemId}`, data);
  }

  public deleteItem(itemId) {
    return this.httpService.myDelete(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/blog/blog-post/${itemId}`);
  }

  public like(componentId, idArticle) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/blog/blog-post/${idArticle}/like`, {});
  }

  public unlike(componentId, idArticle) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/blog/blog-post/${idArticle}/unlike`, {});
  }

  public getComments(idPage, idArticle, filters: Filters): any {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/blog/blog-post/${idArticle}/comments`, filters);
  }

  public sendComment(idPage, idArticle, comment) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/blog/blog-post/${idArticle}/comments`, {
      comment: comment
    });
  }

  public removeComment(idPage, idArticle, commentId) {
    return this.httpService.myDelete(`${Environment.API_BASE_URL_V2}/api/empatica/v1/blog/blog-post/${idArticle}/comments/${commentId}`);
  }
}
