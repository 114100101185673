import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Device } from '@capacitor/device';


@Injectable({ providedIn: 'root' })
export class PlatformService {
  private device: string = null;

  constructor(private platform: Platform) {
  }

  public isMobile() {
    return (this.platform.is('ios') || this.platform.is('android') && this.platform.is('mobile') && !this.platform.is('mobileweb')) ;
  }

  public isWeb() {
    return this.platform.is('desktop') || this.platform.is('mobileweb');
  }

  public isIos() {
    return this.platform.is('ios') && !this.platform.is('mobileweb');
  }

  public isAndroid() {
    return this.platform.is('android') && !this.platform.is('mobileweb');
  }

  public async isAndroid13() {
    console.log('is 13 or higher');
    const deviceInfo = await Device.getInfo();
    const osVersion = typeof deviceInfo.osVersion === 'string' ? parseFloat(deviceInfo.osVersion) : deviceInfo.osVersion;
    console.log('device info: ', deviceInfo);
    return this.isAndroid() && Math.floor(osVersion) >= 13;
  }

  public async isBelowAndroid13() {
    console.log('is below 13');
    const deviceInfo = await Device.getInfo();
    const osVersion = typeof deviceInfo.osVersion === 'string' ? parseFloat(deviceInfo.osVersion) : deviceInfo.osVersion;
    console.log('device info: ', deviceInfo);
    return this.isAndroid() && Math.floor(osVersion) < 13;
  }

  get() {
    if (this.platform.is('desktop') || this.platform.is('mobileweb')) return 'web';
    if (this.platform.is('android') && !this.platform.is('mobileweb')) return 'android';
    if (this.platform.is('ios') && !this.platform.is('mobileweb')) return 'ios';
    return 'Sin definir';
  }

  public async loadDevice() {
    const info = await Device.getInfo();
    this.device = info.model;
  }
  public getDevice() {
    return this.device;
  }
}
