import { Component, OnInit, OnChanges } from '@angular/core';

// Pages
import { InitConfig } from 'src/app/models/initConfig.model';
import { Post } from 'src/app/models/post.model';
import { CheckComponentsService } from 'src/app/services/check-components.service';
import { BlogService } from 'src/app/services/blog.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { NetworkService } from 'src/app/services/network.service';
import { Filters } from 'src/app/models/filters.model';
import { Router } from '@angular/router';

@Component({
  selector: 'blog',
  templateUrl: 'blog.html',
  styleUrls: ['blog.scss']
})
export class BlogComponent {
  public initialConfigBlog: InitConfig;
  public blogListArticles: Array<Post> = [];
  public isEmpty: boolean = false;
  public showHeader: boolean;
  public showViewMoreBtn: boolean = false;
  public itemsCount;
  public viewMode;

  private errorInComponentBlog: { haveError: boolean; textError: string } = {
    haveError: false,
    textError: ''
  };

  constructor(
    private router: Router,
    private blogProvider: BlogService,
    private checkComponent: CheckComponentsService,
    private analyticsService: AnalyticsService,
    public network: NetworkService
  ) {
    this.initialConfigBlog = this.blogProvider.blogConfig;

    this.showHeader = this.initialConfigBlog.view.showHeader;
    this.getDataBlog();
  }

  private getDataBlog() {
    const filters: Filters = {
      searchKey: '',
      start: 0,
      length: this.initialConfigBlog.view.maxNumItems
    };

    this.blogProvider.getDataBlog(this.initialConfigBlog.id, filters).subscribe({
      next: (response: InitConfig) => {
        this.blogListArticles = this.checkComponent.setItemsInComponent(response.items.itemList, response.items.itemsCount);
        this.showViewMoreBtn = response.items.itemsCount > response.items.itemsFiltered;
        this.initialConfigBlog.items = response.items;
        this.isEmpty = this.checkComponent.checkEmptyComponent(this.blogListArticles);
        this.itemsCount = this.initialConfigBlog.items.itemsCount;
        this.viewMode = this.initialConfigBlog.view.mode;
      },
      error: (error) => {
        console.error('Error getting data blog', error);
      }
    });
  }

  public goToDetailBlog(article: Post) {
    this.registerEventInAnalytics(article);
    this.router.navigate([`/blog/${this.initialConfigBlog.id}/detail/${article.id}`]);
  }

  private refresh(data?) {
    this.getDataBlog();
  }

  public goToFullBlog() {
    this.router.navigate([`/blog/${this.initialConfigBlog.id}/list`]);
  }

  private registerEventInAnalytics(article) {
    const paramsToAnalytics = { actions: 8 };
    const name = article.title;
    const id = article.id;
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
  }

  public isButtonView() {
    return this.initialConfigBlog.view.mode === 'button' && !this.errorInComponentBlog.haveError;
  }
}
