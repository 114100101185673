// NEW MESSAGES SERVICES
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { map, pluck, shareReplay, skip, switchMap, take, tap } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';
import { Filters } from '../models/filters.model';
import { Message } from '../models/message.model';
import { GlobalConstService } from './global-const.service';
import { HttpService } from './http.service';
import { LoginService } from './login.service';
import { PlatformService } from './platform.service';

interface State {
  roomsList: Array<any>;
  loading: boolean;
}
@Injectable({ providedIn: 'root' })
export class MessagesService {
  private itemsSubject$ = new BehaviorSubject<State>({ roomsList: [], loading: true });
  public roomsList$ = this.itemsSubject$.asObservable();

  private loadRoomFirebase: Subscription[] = [];
  private filters: Filters = {
    searchKey: '',
    start: 0,
    orderBy: 'name',
    orderDir: 'asc',
    length: 20
  };
  public totalMessagesUnread: number;
  roomSubscription: Subscription;

  constructor(
    private baseService: HttpService,
    private gConstService: GlobalConstService,
    private firestore: AngularFirestore,
    private loginService: LoginService,
    private platformService: PlatformService,
    private http: HttpClient
  ) {}

  get state() {
    return this.itemsSubject$.getValue();
  }

  public unsubscribeAll(){
    if (this.loadRoomFirebase) {
      this.loadRoomFirebase.forEach((subscription) => {
        if (subscription) subscription.unsubscribe();
      });
    }
  }

  public getRooms(filters: Filters) {
    const request = this.http.get(`${Environment.API_BASE_URL_V2}/api/empatica/v1/chat/rooms`, { params: <any>filters }).pipe(pluck('items', 'itemList'), shareReplay());

    request.subscribe((roomsList: Array<any>) => {
      roomsList = filters.start === 0 ? roomsList : [...this.state.roomsList, ...roomsList];
      this.itemsSubject$.next({ roomsList: roomsList, loading: false });

      this.gConstService.userRooms = roomsList;
      this.calculateTotalMessageUnread();
      this.updateMessageUnreadFirebase();
    });

    return request;
  }

  /* ROOMS */
  public getListOfRooms($event?, filters?) {
    if (filters) this.filters = filters;
    if (this.roomSubscription) {
      this.roomSubscription.unsubscribe();
    }
    const rooms$ = this.platformService.isMobile()
      ? this.getRooms(this.filters)
      : timer(0, 20000).pipe(
          switchMap(() => {
            return this.getRooms(this.filters);
          })
        );

    this.roomSubscription = rooms$.subscribe((res) => {
      if ($event) $event.target.complete();
    });
  }

  public updateMessageUnreadFirebase() {
    if (this.loadRoomFirebase) {
      this.loadRoomFirebase.forEach((subscription) => {
        if (subscription) subscription.unsubscribe();
      });
    }
    this.loadRoomFirebase = [];
    let currentUserId = this.loginService.rescueSessionDataUser().account.id;

    this.gConstService.userRooms.forEach((currentRoom: any) => {
      const roomSubscription = this.firestore
        .collection('rooms', (r) => r.where('idRoom', '==', currentRoom.token))
        .valueChanges()
        .pipe(skip(1))
        .subscribe((roomsFB: any) => {
          if (roomsFB.length > 0) {
            this.getRooms(this.filters);
          }
        });
      this.loadRoomFirebase.push(roomSubscription);
    });
  }

  public createFormData(roomToken, sender, type, message, date, file): any {
    const formData = new FormData();
    formData.append('roomToken', roomToken);
    formData.append('sender', sender);
    formData.append('type', type);
    formData.append('message', message);
    formData.append('date', date);
    formData.append('file', file);
    return formData;
  }

  public sendMessage({ roomToken, sender, type, message, date, file }) {
    const formData = this.createFormData(roomToken, sender, type, message, date, file);
    this.baseService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/chat/${roomToken}/message`, formData).pipe(take(1)).subscribe();
  }

  public getMessagesByRoomToken(roomToken) {
    return this.firestore
      .collection('rooms')
      .doc(roomToken)
      .collection('messages', (message) => message.orderBy('date', 'asc'))
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data() as Message;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        }),
        shareReplay()
      );
  }

  public getRoomByToken(roomToken) {
    return this.baseService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/chat/${roomToken}/lite`);
  }

  private calculateTotalMessageUnread() {
    this.totalMessagesUnread = this.gConstService.userRooms.reduce((acc, room) => {
      if (!room.messagesUnread) {
        room.messagesUnread = 0;
      }
      return (acc += room.messagesUnread);
    }, 0);
  }

  public updateMessagesUnread(token) {
    this.baseService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/chat/${token}/clean-unread-messages`, {}).pipe(take(1)).subscribe();
  }

  public pin(room) {
    const request$ = this.baseService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/chat/${room.token}/pin`, {});

    request$.pipe(take(1), shareReplay()).subscribe();

    return request$;
  }

  public unpin(room) {
    const request$ = this.baseService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/chat/${room.token}/unpin`, {});

    request$.pipe(take(1), shareReplay()).subscribe();

    return request$;
  }

  public archive(room) {
    const request$ = this.baseService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/chat/${room.token}/archive`, {});

    request$.pipe(take(1), shareReplay()).subscribe();

    return request$;
  }

  public removeMessage(roomToken: string, messageId: string) {
    const request$ = this.baseService.myDelete(`${Environment.API_BASE_URL_V2}/api/empatica/v1/chat/${roomToken}/message/${messageId}`);

    request$.pipe(take(1), shareReplay()).subscribe();

    return request$;
  }

  public chatMembers(roomToken: string) {
    const request$ = this.baseService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/chat/${roomToken}/members`);

    return request$;
  }
}
