// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}
:host .loading-image {
  min-height: 34vh;
}
:host img {
  position: relative;
  display: block;
  width: 100%;
}

:host-context(ion-slide) {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/image-loader/image-loader.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,gBAAA;AACJ;AACE;EACE,kBAAA;EACA,cAAA;EACA,WAAA;AACJ;;AAGA;EACE,WAAA;EACA,YAAA;AAAF","sourcesContent":[":host {\n  width: 100%;\n\n  .loading-image {\n    min-height: 34vh;\n  }\n  img {\n    position: relative;\n    display: block;\n    width: 100%;\n  }\n}\n\n:host-context(ion-slide){\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
