import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { GalleryService } from 'src/app/services/gallery.service';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { WallService } from 'src/app/services/wall.service';

@Component({
  selector: 'wall-item',
  templateUrl: 'wall-item.html',
  styleUrls: ['wall-item.scss']
})
export class WallItemComponent implements OnInit {
  @Input() item;
  @Input() permissions;
  @Output() reloadEmitter = new EventEmitter<string>();

  constructor(
    public gConst: GlobalConstService,
    private wallProvider: WallService,
    public alertController: AlertController,
    private translate: TranslateService,
    private galleryService: GalleryService
  ) {}

  public currentUserId: any;

  ngOnInit() {
    this.currentUserId = this.gConst.userData.account.id;
  }

  public updateLikedItem(liked) {
    this.item = liked.newItem;
  }

  public updateCommentedItem(count) {
    this.item.social.comments.count = count.count;
  }

  public async deleteMessage() {
    const alert = await this.alertController.create({
      cssClass: '',
      header: await this.translate.get('WALL-DELETE-MESSAGE').toPromise(),
      message: await this.translate.get('ARE-YOU-SURE-YOU-WANT-TO-DELETE-THIS-MESSAGE').toPromise(),
      buttons: [
        {
          text: await this.translate.get('CANCEL').toPromise(),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            
          }
        },
        {
          text: await this.translate.get('DELETE').toPromise(),
          cssClass: 'danger',
          handler: () => {
            this.wallProvider
              .deleteMessage(this.item.id)
              .pipe(take(1))
              .subscribe(() => {
                this.reloadEmitter.emit('reload');
              });
          }
        }
      ]
    });

    await alert.present();
  }

  viewImage(image) {
    this.galleryService.showViewer(image);
  }
}
