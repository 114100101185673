// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-item {
  margin-bottom: 0;
  align-items: flex-end;
}
:host ion-item ion-button {
  font-size: 1rem;
  --color: var(--ion-color-primary) !important;
  --background: transparent!important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/change-password-modal/change-password-modal.scss"],"names":[],"mappings":"AAEI;EACI,gBAAA;EACA,qBAAA;AADR;AAGQ;EACE,eAAA;EACA,4CAAA;EACA,mCAAA;AADV","sourcesContent":[":host{\n\n    ion-item{\n        margin-bottom: 0;\n        align-items: flex-end;\n\n        ion-button {\n          font-size: 1rem;\n          --color: var(--ion-color-primary) !important;\n          --background: transparent!important;\n        }\n      }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
