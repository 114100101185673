// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  justify-content: center;
  align-items: center;
}
:host .lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
:host .lds-ripple div {
  position: absolute;
  border: 4px solid #dcdcdc;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
:host .lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/loading/loading.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACJ;AAAI;EACE,kBAAA;EACA,yBAAA;EACA,UAAA;EACA,kBAAA;EACA,8DAAA;AAEN;AADM;EACE,sBAAA;AAGR;AAEE;EACE;IACE,SAAA;IACA,UAAA;IACA,QAAA;IACA,SAAA;IACA,UAAA;EAAJ;EAEE;IACE,QAAA;IACA,SAAA;IACA,WAAA;IACA,YAAA;IACA,UAAA;EAAJ;AACF","sourcesContent":[":host {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  .lds-ripple {\n    display: inline-block;\n    position: relative;\n    width: 80px;\n    height: 80px;\n    div {\n      position: absolute;\n      border: 4px solid #dcdcdc;\n      opacity: 1;\n      border-radius: 50%;\n      animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;\n      &:nth-child(2) {\n        animation-delay: -0.5s;\n      }\n    }\n  }\n\n  @keyframes lds-ripple {\n    0% {\n      top: 36px;\n      left: 36px;\n      width: 0;\n      height: 0;\n      opacity: 1;\n    }\n    100% {\n      top: 0px;\n      left: 0px;\n      width: 72px;\n      height: 72px;\n      opacity: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
