import { Component, OnInit, OnDestroy } from '@angular/core';
import { Post } from 'src/app/models/post.model';
import { InitConfig } from 'src/app/models/initConfig.model';
import { AlertController, NavController, NavParams, Platform } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalConstService } from 'src/app/services/global-const.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ToastService } from 'src/app/services/toast.service';
import { BlogService } from 'src/app/services/blog.service';
import { AlertButton, AlertOptions } from '@ionic/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { map, concatMap, catchError, take } from 'rxjs/operators';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { GalleryService } from 'src/app/services/gallery.service';
import { LoginService } from 'src/app/services/login.service';
import { DocumentService } from 'src/app/services/document.service';
import { EventsService } from 'src/app/services/events.service';
import { LanguageService } from 'src/app/services/language.service';
@Component({
  selector: 'page-detail-blog',
  templateUrl: 'detail-blog.html',
  styleUrls: ['detail-blog.scss']
})
export class DetailBlogPage implements OnDestroy {
  private articleSubscription: Subscription;
  public article$: BehaviorSubject<any> = new BehaviorSubject(null);
  public idArticle: number;
  public idPage;
  public blogBody;

  public canEdit = new BehaviorSubject(false);
  public forbidden: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private blogProvider: BlogService,
    public alertCtrl: AlertController,
    private languageService: LanguageService,
    public toast: ToastService,
    private analyticsService: AnalyticsService,
    public gConst: GlobalConstService,
    protected sanitizer: DomSanitizer,
    private galleryService: GalleryService,
    private loginService: LoginService,
    private docsService: DocumentService,
    private events: EventsService
  ) {}

  ngOnDestroy(): void {
    this.articleSubscription.unsubscribe();
  }

  ionViewWillEnter() {
    this.idPage = +this.route.snapshot.paramMap.get('idPage');
    this.idArticle = +this.route.snapshot.paramMap.get('id');
    this.getArticleDetail();
    this.registerAnalyticsInFirebase();
  }

  private getArticleDetail() {
    this.articleSubscription = this.blogProvider
      .getArticle(this.idArticle)
      .pipe(
        map((article) => ({ ...article, body: article.body })),
        catchError((err: any) => {
          if (err.status === 403) {
            this.forbidden = true;
          }

          throw err;
        })
      )
      .subscribe(async (article) => {
        this.article$.next(article);
        this.events.pages$$.next(article.title);
        const account = (await this.loginService.rescueSessionDataUser()).account;
        this.canEdit.next((article.permissions.edit && article.authorId == account.id) || article.permissions.super);
      });
  }

  public showAlertChooseRss(article) {
    this.blogProvider.shareInRss(article);
  }

  private registerAnalyticsInFirebase() {
    const paramsToAnalytics = { navigation: 3 };
    this.analyticsService.registerEvent(paramsToAnalytics, 'Detalle de blog', this.idArticle);
  }

  public updateLikedItem(liked) {
    this.article$.next(liked.newItem);
  }

  public updateCommentedItem(count) {
    this.article$.pipe(take(1)).subscribe((article) => {
      article.social.comments.count = count.count;
      this.article$.next(article);
    });
  }

  public goToEditItem() {
    this.router.navigate([`/blog/${this.idPage}/edit/${this.idArticle}`]);
  }

  public async confirmDeleteItem() {
    const alertButtons: AlertButton[] = [
      {
        text: await this.languageService.translate('CANCEL'),
        role: 'cancel'
      },
      {
        text: await this.languageService.translate('DELETE'),
        handler: () => this.deleteItem()
      }
    ];

    const alertOptions: AlertOptions = {
      header: await this.languageService.translate('ARE-YOU-SURE-YOU-WANT-TO-DELETE-THIS-ITEM'),
      buttons: alertButtons
    };
    let alert = await this.alertCtrl.create(alertOptions);
    await alert.present();
  }

  public deleteItem() {
    this.blogProvider.deleteItem(this.idArticle).subscribe(() => {
      this.router.navigate([`/blog/${this.idPage}/list`]);
    });
  }

  public viewMedia(media) {
    media = { ...media, url: media.headerImg };

    if (media.sourceType === 'youtube') {
      this.galleryService.openYoutubeVideo(media);
      return;
    }
    if (media.sourceType === 'vimeo') {
      this.galleryService.openVimeoVideo(media);
      return;
    }
    if (media.extension === 'mp4') {
      this.docsService.downloadDoc(media, true);
      return;
    }

    this.galleryService.showViewer(media.headerImg);
  }
}
