import { Component, Input } from '@angular/core';


@Component({
  selector: 'likes-item',
  templateUrl: 'likes-item.html',
  styleUrls: ['likes-item.scss']
})
export class LikesItemComponent {

  @Input() like;

  constructor() { }

}
