import { Injectable } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { CameraCapacitorService } from './camera-capacitor.service';
import { LanguageService } from './language.service';
import { PlatformService } from './platform.service';
import { DocumentPicker } from '@awesome-cordova-plugins/document-picker/ngx';
import { FileChooser } from '@awesome-cordova-plugins/file-chooser/ngx';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root'
})
export class PromptCameraService {

  public REMOVE_IMAGE = 'remove_image';
  public OPEN_INPUT_FILE = 'open_input_file';
  public SELECT_DOCUMENT_MOBILE = 'select_document_mobile';
  constructor(
    private actionSheetController: ActionSheetController,
    private languageService: LanguageService,
    private cameraCapacitor: CameraCapacitorService,
    private platformService: PlatformService,
    private docPicker: DocumentPicker,
    private fileChooser: FileChooser,
    private filePath: FilePath,
    private fileService: FileService
  ) { }

  public async show(showRemoveOption?: boolean | false, showSelectDocument?: boolean | false) {
    let image: any;
    let resultAction = { action: '', image };

    let buttons = [];
    buttons = [
      {
        text: await this.languageService.translate('OPTION_TAKE_PHOTO'),
        handler: async () => {
          const photo = await this.cameraCapacitor.takePhoto();
          resultAction.image = photo.path;
          console.log('OPTION_TAKE_PHOTO: ', resultAction.image);
          return;
        }
      },
      {
        text: await this.languageService.translate('OPTION_SELECT_PHOTO'),
        handler: async () => {
          console.log('OPTION_SELECT_PHOTO: ', );
          
          const image = await this.cameraCapacitor.selectPicture();
          
          
          resultAction.image = image.photos[0];

          console.log('resultAction.image: ', resultAction.image);
          console.log('OPTION_SELECT_PHOTO: ', resultAction.image);
          return;

        }
      },
      {
        text: await this.languageService.translate('CANCEL'),
        role: 'cancel'
      }
    ];

    if (showSelectDocument) {
      let buttonRemove = {
        text: await this.languageService.translate('OPTION_SELECT_DOCUMENT'),
        handler: async () => {

          if (this.platformService.isIos()) {
            resultAction.image = await this.docPicker.getFile('all');
            resultAction.action = this.SELECT_DOCUMENT_MOBILE;
            return;
          }
          if (this.platformService.isAndroid()) {

            if(this.platformService.isAndroid13){
              const url = await this.fileChooser.open();
              resultAction.image = url;
              resultAction.action = this.SELECT_DOCUMENT_MOBILE;
              return;
            }

            const url = await this.fileChooser.open();
            resultAction.image = await this.filePath.resolveNativePath(url);
            resultAction.action = this.SELECT_DOCUMENT_MOBILE;
            return;


          }
          resultAction.action = this.OPEN_INPUT_FILE;
        }


      };
      buttons.splice(buttons.length - 1, 0, buttonRemove);
    }

    if (showRemoveOption) {
      let buttonRemove = {
        text: await this.languageService.translate('OPTION_REMOVE_PHOTO'),
        handler: () => {
          resultAction.action = this.REMOVE_IMAGE;
        },
        role: 'destructive',
        cssClass: 'remove-btn',
      };
      buttons.unshift(buttonRemove);
    }



    const actionSheet = await this.actionSheetController.create({
      cssClass: 'action-sheets',
      buttons
    });


    await actionSheet.present();
    await actionSheet.onDidDismiss();
    console.log('resultAction: ', resultAction);
    
    return resultAction;
  }

  public async ask() {
    let resultAction = { action: '', image: null };

    const actionSheet = await this.actionSheetController.create({
      cssClass: 'action-sheets',
      buttons: [
        {
          text: await this.languageService.translate('OPTION_TAKE_PHOTO'),
          handler: async () => {
            const photo = await this.cameraCapacitor.takePhoto();
            resultAction.image = photo;
            return;
          }
        },
        {
          text: await this.languageService.translate('OPTION_SELECT_PHOTO'),
          handler: async () => {
            const image = await this.cameraCapacitor.selectPicture();
            resultAction.image = image.photos[0]
            return;
          }
        },
        {
          text: await this.languageService.translate('CANCEL'),
          role: 'cancel'
        }
      ]
    });


    await actionSheet.present();
    await actionSheet.onDidDismiss();

    return resultAction;
  }

  public async removeSheet() {
    const actionSheet = await this.actionSheetController.create({
      cssClass: 'action-sheets',
      buttons: [
        {
          text: await this.languageService.translate('OPTION_REMOVE_PHOTO'),
          handler: () => {
            return true;
          },
          role: 'destructive',
          cssClass: 'remove-btn'
        },
        {
          text: await this.languageService.translate('CANCEL'),
          role: 'cancel'
        }
      ]
    });

    await actionSheet.present();
    return await actionSheet.onDidDismiss();
  }
}

