import { Injectable } from '@angular/core';
import { GlobalConstService } from './global-const.service';
import { HttpService } from './http.service';
import { InitConfig } from '../models/initConfig.model';
import { Filters } from '../models/filters.model';
import { Post } from '../models/post.model';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { LanguageService } from './language.service';
import { Share, ShareOptions } from '@capacitor/share';
import { Clipboard } from '@capacitor/clipboard';
import { Environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WallService {
  public wallConfig: InitConfig;

  constructor(
    private httpService: HttpService,
  ) {}

  public getDataWall(id, filters: Filters) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/wall/${id}/elements`, filters);
  }

  public newMessage(id: number, message: string, blob: any = null, sendNotification: any): Observable<any> {
    const formData = new FormData();
    formData.append('message', message);
    formData.append('sendNotification', sendNotification);

    if (blob !== null) {
      formData.append('image', blob, blob.type);
    }

    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/wall/${id}/add-element`, formData);
  }

  public deleteMessage(id: number) {
    return this.httpService.myDelete(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/wall/wall-post/${id}`);
  }

  public like(idPage, wallPostId) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/wall/wall-post/${wallPostId}/like`, {});
  }

  public unlike(idPage, wallPostId) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/wall/wall-post/${wallPostId}/unlike`, {});
  }

  public getComments(idPage, wallPostId, filters: Filters): any {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/wall/wall-post/${wallPostId}/comments`, filters);
  }

  public sendComment(idPage, wallPostId, comment) {
    return this.httpService.myPost(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/wall/wall-post/${wallPostId}/comments`, { comment: comment });
  }

  public removeComment(itemId, commentId) {
    return this.httpService.myDelete(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/wall/wall-post/${itemId}/comments/${commentId}`);
  }
}
