// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-item-sliding ion-item-option {
  background-color: white;
  color: #808080;
  font-size: 1.3em;
}
:host ion-item-sliding ion-item {
  border-bottom: 1px solid var(--ion-color-light);
  --padding-top: 6px;
  --padding-bottom: 6px;
}
:host ion-item-sliding ion-item ion-avatar {
  margin-right: 1rem;
}
:host ion-item-sliding ion-item .user-message-container {
  color: black;
}
:host ion-item-sliding ion-item .user-message-container .last-message {
  color: #808080;
}
:host ion-item-sliding ion-item ion-badge {
  margin-inline-start: 8px;
}
:host ion-item-sliding ion-item ion-badge.pinned {
  margin-inline-end: 0px;
  color: #808080;
  font-size: 17px;
}
:host ion-item-sliding ion-item ion-badge.messagesUnread.ios, :host ion-item-sliding ion-item ion-badge.messagesUnread.md {
  background: var(--ion-color-danger);
  --color: #fff !important;
  color: #fff !important;
  font-family: var(--font-bold);
  min-width: 20px;
  height: 20px;
  padding: 4px 2px 2px 2px;
}

:host-context(.plt-desktop) ion-item {
  cursor: pointer;
  transition: all ease 0.3s;
}
:host-context(.plt-desktop) ion-item ion-label {
  --color: black !important;
}
:host-context(.plt-desktop) ion-item:hover ion-label {
  --color: var(--cms-link-text-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/chat-list/chat-list.component.scss"],"names":[],"mappings":"AAEI;EACE,uBAAA;EACA,cAAA;EACA,gBAAA;AADN;AAGI;EACE,+CAAA;EACA,kBAAA;EACA,qBAAA;AADN;AAEM;EACE,kBAAA;AAAR;AAEM;EACE,YAAA;AAAR;AACQ;EACE,cAAA;AACV;AAEM;EACE,wBAAA;AAAR;AACQ;EACE,sBAAA;EACA,cAAA;EACA,eAAA;AACV;AAEU;EAEE,mCAAA;EACA,wBAAA;EACA,sBAAA;EACA,6BAAA;EACA,eAAA;EACA,YAAA;EACA,wBAAA;AADZ;;AAUE;EACE,eAAA;EACA,yBAAA;AAPJ;AAQI;EACE,yBAAA;AANN;AASM;EACE,8CAAA;AAPR","sourcesContent":[":host {\n  ion-item-sliding {\n    ion-item-option {\n      background-color: white;\n      color: #808080;\n      font-size: 1.3em;\n    }\n    ion-item {\n      border-bottom: 1px solid var(--ion-color-light);\n      --padding-top: 6px;\n      --padding-bottom: 6px;\n      ion-avatar {\n        margin-right: 1rem;\n      }\n      .user-message-container {\n        color: black;\n        .last-message {\n          color: #808080;\n        }\n      }\n      ion-badge {\n        margin-inline-start: 8px;\n        &.pinned {\n          margin-inline-end: 0px;\n          color: #808080;\n          font-size: 17px;\n        }\n        &.messagesUnread {\n          &.ios,\n          &.md {\n            background: var(--ion-color-danger);\n            --color: #fff !important;\n            color: #fff !important;\n            font-family: var(--font-bold);\n            min-width: 20px;\n            height: 20px;\n            padding: 4px 2px 2px 2px;\n          }\n        }\n      }\n    }\n  }\n}\n\n:host-context(.plt-desktop) {\n  ion-item {\n    cursor: pointer;\n    transition: all ease 0.3s;\n    ion-label {\n      --color: black !important;\n    }\n    &:hover {\n      ion-label {\n        --color: var(--cms-link-text-color) !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
