import { Component, ViewChild, OnInit } from '@angular/core';
import { Filters } from 'src/app/models/filters.model';
import { BlogService } from 'src/app/services/blog.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { NavController, NavParams, IonContent, IonNav, ModalController, AlertController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { GalleryService } from 'src/app/services/gallery.service';
import { PlatformService } from 'src/app/services/platform.service';
import { PodcastService } from '../../services/podcast.service';
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard';
import { SimulationService } from 'src/app/services/simulation.service';
import { BehaviorSubject } from 'rxjs';
import { Clipboard } from '@capacitor/clipboard';
import { ToastService } from 'src/app/services/toast.service';
import { LoginService } from 'src/app/services/login.service';
import { AlertButton, AlertOptions } from '@ionic/core';
import { LanguageService } from 'src/app/services/language.service';
import { take } from 'rxjs/operators';
import { WallService } from 'src/app/services/wall.service';
@Component({
  selector: 'page-comments',
  templateUrl: 'comments.html',
  styleUrls: ['comments.scss']
})
export class CommentsPage implements OnInit {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonNav) navBar: IonNav;

  public idPage: number;
  public idArticle: number;

  public comments$$ = new BehaviorSubject([]);
  public itemsCount: number;

  public comment: string;
  public sendingComment: boolean = false;

  public filters: Filters = {
    searchKey: '',
    start: 0,
    orderBy: 'publishDate',
    orderDir: 'desc',
    length: 20
  };
  public defaultProvider;
  public type;
  currentUserId: any;

  constructor(
    public router: Router,
    private loginService: LoginService,
    public blogProvider: BlogService,
    public modalCtrl: ModalController,
    private analyticsService: AnalyticsService,
    private galleryService: GalleryService,
    private blogService: BlogService,
    private navParams: NavParams,
    private platformService: PlatformService,
    private podcastService: PodcastService,
    private simulationService: SimulationService,
    private toastController: ToastService,
    private alertCtrl: AlertController,
    private languageService: LanguageService,
    private wallService: WallService
  ) {}

  ngOnInit() {
    this.idPage = +this.navParams.get('idPage');
    this.idArticle = +this.navParams.get('idArticle');
    this.type = this.navParams.get('type');

    this.navParams.get('initConfig');

    this.defaultProvider = this.getDefaultProvider();
    this.subscribeKeyBoardStatus();
    this.loadComments();

    this.currentUserId = this.loginService.rescueSessionDataUser().account.id;
  }

  getDefaultProvider() {
    if (this.type.toUpperCase() == 'BLOG') return this.blogService;
    if (this.type.toUpperCase() == 'GALLERY') return this.galleryService;
    if (this.type.toUpperCase() == 'PODCAST') return this.podcastService;
    if (this.type.toUpperCase() == 'WALL') return this.wallService;
  }

  async ionViewWillLeave() {
    try {
      await this.modalCtrl.dismiss(this.itemsCount);
    } catch (e) {
      console.log(e);
    }
  }

  public send() {
    if (this.simulationService.onSimulationMode()) {
      return;
    }
    this.filters.length = 20;
    this.filters.start = 0;
    this.sendingComment = true;
    this.defaultProvider.sendComment(null, this.idArticle, this.comment).subscribe(
      () => {
        this.loadComments();
        this.comment = null;
        this.sendingComment = false;
      },
      () => (this.sendingComment = false)
    );

    this.registerEventInAnalytics(10, { page_id: this.idPage, item_id: this.idArticle });
  }

  private registerEventInAnalytics(action, { page_id, item_id }) {
    const paramsToAnalytics = { actions: action };
    const page = page_id;
    const item = item_id;
    this.analyticsService.registerEvent(paramsToAnalytics, page, item);
  }

  private loadComments($event = null) {
    this.defaultProvider.getComments(null, this.idArticle, this.filters).subscribe((response) => {
      const comments = $event === null ? response.items.itemList : [...this.comments$$.value, ...response.items.itemList];

      this.comments$$.next(comments);
      this.itemsCount = response.items.itemsCount;

      if ($event !== null && $event.target !== null && $event.target !== undefined) {
        $event.target.complete();
      }
    });
  }

  private async subscribeKeyBoardStatus() {
    if (this.platformService.isWeb()) return;

    Keyboard.addListener('keyboardDidShow', (info: KeyboardInfo) => {
      this.content.scrollToBottom(0);
    });

    Keyboard.addListener('keyboardDidHide', () => {
      this.content.scrollToBottom(0);
    });
  }

  /**
   * function to adjust the height of the message textarea
   * @param {any} event - the event, which is provided by the textarea input
   * @return {void}
   */
  public adjustTextarea(event: any): void {
    let textarea: any = event.target;
    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
    return;
  }

  public doInfinite($event) {
    this.filters.start = this.comments$$.value.length;
    this.loadComments($event);
  }

  public closeModal() {
    this.modalCtrl.dismiss({
      comments: this.itemsCount
    });
  }

  trackByFn(index, item) {
    return item.id;
  }

  public copyToClipboard(comment) {
    Clipboard.write({ string: comment.comment }).then(() => this.toastController.info('COPIED-TO-CLIPBOARD'));
  }

  async confirmDelete(comment) {
    const alertButtons: AlertButton[] = [
      { text: await this.languageService.translate('CANCEL'), role: 'cancel' },
      { text: await this.languageService.translate('DELETE'), handler: () => this.removeComment(comment) }
    ];

    const alertOptions: AlertOptions = {
      header: await this.languageService.translate('ARE-YOU-SURE-YOU-WANT-TO-DELETE-THIS-MESSAGE'),
      buttons: alertButtons
    };
    const alert = await this.alertCtrl.create(alertOptions);

    await alert.present();
  }

  public async removeComment(comment) {
    this.defaultProvider
      .removeComment(this.idArticle, comment.id)
      .pipe(take(1))
      .subscribe(() => {
        this.loadComments();
      });
  }
}
