import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Photo } from '@capacitor/camera';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { FileService } from 'src/app/services/file.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PlatformService } from 'src/app/services/platform.service';
import { PromptCameraService } from 'src/app/services/prompt-camera.service';
import { ToastService } from 'src/app/services/toast.service';
import { WallService } from 'src/app/services/wall.service';

@Component({
  selector: 'new-wall-item-modal',
  templateUrl: 'new-wall-item-modal.html',
  styleUrls: ['new-wall-item-modal.scss']
})
export class NewWallMessageModalComponent implements OnInit {
  @Input() placeholder: string;
  @Input() maxCharLength: number = 250;
  @Input() componentId: number;
  @ViewChild('fileImage') fileImage: ElementRef;

  public wallItemForm: UntypedFormGroup;
  public showImageContainer = false;
  public charsUsed = 0;

  public image: Photo = null;
  public base64Image: any = null;

  constructor(
    private modalController: ModalController,
    private fb: UntypedFormBuilder,
    private promptCameraService: PromptCameraService,
    private wallProvider: WallService,
    private fileService: FileService,
    private toastService: ToastService,
    private loading: LoadingService,
    private platformService: PlatformService
  ) {}

  ngOnInit(): void {
    this.wallItemForm = this.fb.group({
      message: [''],
      image: [null],
      sendNotification: [false]
    });

    this.wallItemForm.get('message').setValidators([Validators.required, Validators.maxLength(this.maxCharLength)]);
  }

  public dismiss(reload) {
    this.modalController.dismiss({ reload });
  }


  public submit() {

    if (false === this.wallItemForm.valid) {
      this.toastService.info('YOU-MUST-FILL-OUT-THE-FORM');
      return;
    }

    
    let imageBlob;

    if (this.image !== null) {
      const base64 = this.base64Image;
      imageBlob = this.fileService.base64ToBlob(base64, `image/${this.image.format}`);
    }

    this.addWall(imageBlob)
  }

  public async addWall(imageBlob){
    await this.loading.show();
    this.wallProvider
    .newMessage(
      this.componentId, 
      this.wallItemForm.get('message').value, 
      imageBlob, 
      this.wallItemForm.get('sendNotification').value)
    .pipe(take(1))
    .subscribe({
      next: (data) => {
        this.toastService.info('ITEM-HAS-BEEN-CREATED-SUCCESSFULLY');
        this.dismiss(true);
      },
      error: async (error) => {
        this.dismiss(false);
        await this.loading.hide();
        this.toastService.danger('SUBMIT-ERROR');
      },
      complete: async () => {
        await this.loading.hide();
      }
    });
  }


  public async selectPicture() {
    const result = await this.promptCameraService.ask();
    switch (result.action) {
      
      
      case this.promptCameraService.OPEN_INPUT_FILE:
        this.fileImage.nativeElement.click();
        break;
      case this.promptCameraService.REMOVE_IMAGE:
        break;
      default:
        if (result.image) {
          this.image = result.image;

          if (this.platformService.isWeb()) {
            const base64String = await this.fileService.getImageBase64(result.image.webPath);
            let formattedBase64 = base64String.split(',')[1]
            this.base64Image = `data:image/${result.image.format};base64,${formattedBase64}`;
            return
          }

          const base64String = await this.fileService.convertFilePathToBase64(result.image.path || result.image.webPath);
          this.base64Image = `data:image/${result.image.format};base64,${base64String}`;
        
        }
    }
  }


  cancelImageUpload() {
    this.showImageContainer = false;
    this.wallItemForm.patchValue({ image: null });
  }

  //  public async uploadCameraPictureWeb(event) {
  //    if (event.target.files && event.target.files.length) {
  //      let [file] = event.target.files;
  //      file = await this.fileService.generateFile(file);
  //      this.image$.next({ ...this.image$.value, ...file });
  //      this.wallItemForm.patchValue({ image: file });
  //    }
  //    this.fileImage.nativeElement.value = '';
  //  }

 


}