import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform({ name, surname }: { name: string, surname: string }): any {
    if (!name && !surname) {
      return '-';
    }
    if (name && !surname) {
      if(!name.includes(' ')) return name.charAt(0);
      return (name.split(/\s/).reduce((response,word)=> response+=word.slice(0,1),'')).slice(0,2);
    }

    return `${name.charAt(0)}${surname.charAt(0)}`;
  }

}
