import { Injectable, OnInit } from '@angular/core';
import { Subscription, Subject, BehaviorSubject } from 'rxjs';
import { Network } from '@capacitor/network';

@Injectable({ providedIn: 'root' })
export class NetworkService {
  onlineSubscription: Subscription;
  offlineSubscription: Subscription;
  public connected$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  checkConnection() {
    Network.addListener('networkStatusChange', (status) => {
      console.log('networkStatusChange', status);
      if (this.connected$$.value !== status.connected) this.connected$$.next(status.connected);
    });
  }
  // Check if network is online or offline.
  public online(): boolean {
    return this.connected$$.value;
  }
}
