import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CheckComponentsService {

  constructor() { }

  public checkEmptyComponent(itemList) {
    return !itemList || !itemList.length;
  }

  public getItemsToShow(items: Array<any>, maxNumItems: number) {
    return items.slice(0, maxNumItems);
  }

  public setItemsInComponent(listOfItemsComponent, maxNumOfItems) {
    return listOfItemsComponent.slice(0, maxNumOfItems);
  }
}