// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  text-align: center;
  font-size: 1.25rem !important;
}

.content-img {
  width: 100%;
  display: flex;
}

.big-icon {
  width: 50%;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/components/forbidden/forbidden.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,6BAAA;AACJ;;AAEA;EACI,WAAA;EACA,aAAA;AACJ;;AAEA;EACI,UAAA;EACA,cAAA;AACJ","sourcesContent":[".title{\n    text-align: center;\n    font-size: 1.25rem !important;\n}\n\n.content-img{\n    width: 100%;\n    display: flex;\n}\n\n.big-icon{\n    width: 50%;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
