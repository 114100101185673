import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public langs$$ = new BehaviorSubject(null);

  constructor(private translateService: TranslateService) {}

  public async change(lang?: string) {
    const language = lang ? lang : this.translateService.getBrowserLang();
    const validLanguage = Environment.AVAILABLE_LANGUAGES.find((l) => l === language) ? language : Environment.DEFAULT_LANGUAGE;
    this.langs$$.next(validLanguage);
    moment.locale(validLanguage);
    moment.updateLocale(validLanguage, {
      months: await this.getMonthsTranslate()
    });
  }

  public subscribeLangChange() {
    this.langs$$.pipe(skip(1)).subscribe((lang) => {
      this.translateService.use(lang);
    });
  }

  public async translate(key: string): Promise<string> {
    return await this.translateService.get(key).toPromise();
  }

  public async getMonthsTranslate(): Promise<string[]> {
    return await Promise.all([
      this.translateService.get('MONTH-JANUARY').toPromise(),
      this.translateService.get('MONTH-FEBRUARY').toPromise(),
      this.translateService.get('MONTH-MARCH').toPromise(),
      this.translateService.get('MONTH-APRIL').toPromise(),
      this.translateService.get('MONTH-MAY').toPromise(),
      this.translateService.get('MONTH-JUNE').toPromise(),
      this.translateService.get('MONTH-JULY').toPromise(),
      this.translateService.get('MONTH-AUGUST').toPromise(),
      this.translateService.get('MONTH-SEPTEMBER').toPromise(),
      this.translateService.get('MONTH-OCTOBER').toPromise(),
      this.translateService.get('MONTH-NOVEMBER').toPromise(),
      this.translateService.get('MONTH-DECEMBER').toPromise()
    ]);
  }
}
