import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { Observable } from 'rxjs';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-chat-members',
  templateUrl: './chat-members-modal.component.html',
  styleUrls: ['./chat-members-modal.component.scss']
})
export class ChatMembersModalComponent implements OnInit {
  public chatMembers$: Observable<any>;

  constructor(private messagesService: MessagesService, private navParams: NavParams, private modalCtrl: ModalController, private router: Router) {}

  ngOnInit() {
    const roomToken = this.navParams.get('roomToken');
    this.chatMembers$ = this.messagesService.chatMembers(roomToken);
  }

  public closeModal() {
    this.modalCtrl.dismiss();
  }

  public goToContact(participant) {
    this.router.navigate([`contacts/detail/${participant.id}/agenda`]);
    this.closeModal();
  }
}
