import { Injectable } from '@angular/core';
import { Environment } from 'src/environments/environment';
import { HttpService } from './http.service';

@Injectable({ providedIn: 'root' })
export class EmbeddedService {
  public initConfigEmbedded;

  constructor(private httpService: HttpService) {}

  public getEmbeddeds(id, filters) {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/components/embedded/${id}/elements`, filters);
  }
}
