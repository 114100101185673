import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, finalize, map, switchMap, take } from 'rxjs/operators';
import { Filters } from 'src/app/models/filters.model';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'page-notify',
  templateUrl: 'notify.html',
  styleUrls: ['notify.scss'],
  providers: [TranslatePipe]
})
export class NotifyPage implements OnInit, OnDestroy {
  public notifications: any = [];
  public markAsViewedSubscription: Subscription;
  public itemsCount;

  public itemsNotViewedCount: number;

  public filters$$: BehaviorSubject<Filters | any> = new BehaviorSubject({
    searchKey: '',
    start: 0,
    length: 10,
    $event: null
  });

  constructor(
    public router: Router,
    private notifyProvider: NotificationService,
    private loading: LoadingService,
    private analyticsService: AnalyticsService,
    private translatePipe: TranslatePipe,
    private pushNotification: NotificationService
  ) {}

  ngOnDestroy() {
    this.markAsViewedSubscription.unsubscribe();
  }

  ngOnInit() {
    this.registerPageNameInAnalytics();
    this.markAsViewedSubscription = this.notifyProvider.markAsViewed().pipe(take(1)).subscribe();

    this.analyticsService.registerNotificationPage();

    this.filters$$
      .pipe(switchMap((filters) => this.notifyProvider.getNotifications(filters).pipe(map((res) => ({ ...res, $event: filters.$event })))))
      .subscribe((res) => {
        if (res.$event !== null) {
          res.$event.target.complete();
        }
        this.notifications = [...this.notifications, ...res.items.itemList];
        this.itemsCount = res.items.itemsCount;
        this.itemsNotViewedCount = res.items.itemsNotViewedCount;
      });
  }

  private registerPageNameInAnalytics() {
    this.analyticsService.registerPageName(this.translatePipe.transform('NOTIFICATION-TITLE'));
  }

  public openNotification(notification) {
    this.pushNotification.openNotification(notification.action);
  }

  public loadMore($event) {
    const currentFiltersValue = this.filters$$.value;
    this.filters$$.next({ ...currentFiltersValue, start: currentFiltersValue.start + 10, $event });
  }

  registerEventInAnalytics(notification) {
    const paramsToAnalytics = { actions: 2 };
    const name = notification.title;
    const id = notification.id;
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
  }
}
