import { Pipe, PipeTransform } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Pipe({
  name: 'normalize'
})
export class NormalizePipe implements PipeTransform {

  /**
   * Takes a value and makes it lowercase.
   */
  transform(path) {
    return Capacitor.convertFileSrc(path);
  }
}
