import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';
import { HttpService } from './http.service';

@Injectable({ providedIn: 'root' })
export class TabsService {
  constructor(private httpService: HttpService) {}

  public get() {
    return this.httpService.myGet(`${Environment.API_BASE_URL_V2}/api/empatica/v1/tabs`).pipe(
      tap((tabs) => {
        document.querySelector('body').style.setProperty('--tabs-height', tabs.length > 0 ? 'calc(60px + (env(safe-area-inset-bottom) / 2))' : '0');
      })
    );
  }
}
