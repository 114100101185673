import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { AlertOptions, AlertButton } from '@ionic/core';
import { LanguageService } from './language.service';

@Injectable({ providedIn: 'root' })
export class HandlerErrorService {
  public loginErrors: any;
  public componentErrors: any;
  public errorInComponent: {
    textError: string;
    haveError: boolean;
  };
  constructor(
    public http: HttpClient,
    private languageService: LanguageService,
    private alertCtrl: AlertController
  ) {

    this.loginErrors = {
      400: {
        0: 'LOGIN-ERRORS-400',
        1: 'LOGIN-ERRORS-400',
        2: 'LOGIN-ERRORS-400'
      },
      401: {
        0: 'LOGIN-ERRORS-401'
      },
      404: {
        0: 'LOGIN-ERRORS-404',
        1: 'LOGIN-ERRORS-404'
      }
    };

    this.componentErrors = {
      403: {
        0: 'No tienes permisos para visualizar este contenido'
      }
    };
  }

  public haveErrorInComponent(error) {
    this.errorInComponent = {
      haveError: false,
      textError: ''
    };
    this.errorInComponent.textError = 'error';
    if (error.error.messages) this.errorInComponent.textError = this.componentErrors[error.status][error.error.messages[0].code];

    this.errorInComponent.haveError = true;

    return this.errorInComponent;
  }

  public async cmsError() {
    const alertButtons: AlertButton[] = [{
      text: await this.languageService.translate('CANCEL'),
      role: 'cancel'
    }];
    const alertOptions: AlertOptions = {
      header: await this.languageService.translate('CONFIGURATION-ERROR'),
      subHeader: await this.languageService.translate('WRONG-CMS-CONFIGURATION'),
      buttons: alertButtons
    };

    let alert = await this.alertCtrl.create(alertOptions);

    await alert.present();
  }
}
