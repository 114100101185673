// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-item.item {
  --padding-start: 1rem;
  --padding-end: 1rem;
  --min-height: 80px;
  border-bottom: 1px solid var(--ion-color-light);
}
:host ion-item.item ion-thumbnail {
  width: 50px;
  height: 50px;
  max-width: 50px;
  max-height: 50px;
  min-width: 50px;
  min-height: 50px;
  --background: var(--ion-color-light) !important;
  border-radius: 50%;
  overflow: hidden;
}
:host ion-item.item ion-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
:host ion-item.item ion-label {
  margin: 5px 5px 5px 0px;
}
:host ion-item.item ion-label h3 {
  text-overflow: ellipsis;
  overflow: hidden;
}
:host ion-item.item ion-label p,
:host ion-item.item ion-label small {
  color: var(--ion-color-medium);
  margin: 0px;
}

:host-context(.plt-desktop) {
  cursor: pointer;
}
:host-context(.plt-desktop) ion-item.item:hover ion-label h3 {
  color: var(--cms-link-text-color);
}`, "",{"version":3,"sources":["webpack://./src/app/components/contacts-item/contacts-item.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;EACA,mBAAA;EACA,kBAAA;EACA,+CAAA;AAAJ;AAEI;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,+CAAA;EACA,kBAAA;EACA,gBAAA;AAAN;AAEM;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;AAAR;AAII;EACE,uBAAA;AAFN;AAGM;EACE,uBAAA;EACA,gBAAA;AADR;AAGM;;EAEE,8BAAA;EACA,WAAA;AADR;;AAOA;EACE,eAAA;AAJF;AAQQ;EACE,iCAAA;AANV","sourcesContent":[":host {\n  ion-item.item {\n    --padding-start: 1rem;\n    --padding-end: 1rem;\n    --min-height: 80px;\n    border-bottom: 1px solid var(--ion-color-light);\n\n    ion-thumbnail {\n      width: 50px;\n      height: 50px;\n      max-width: 50px;\n      max-height: 50px;\n      min-width: 50px;\n      min-height: 50px;\n      --background: var(--ion-color-light) !important;\n      border-radius: 50%;\n      overflow: hidden;\n\n      img {\n        width: 100%;\n        height: 100%;\n        object-fit: cover;\n        object-position: center;\n      }\n    }\n\n    ion-label {\n      margin: 5px 5px 5px 0px;\n      h3 {\n        text-overflow: ellipsis;\n        overflow: hidden;\n      }\n      p,\n      small {\n        color: var(--ion-color-medium);\n        margin: 0px;\n      }\n    }\n  }\n}\n\n:host-context(.plt-desktop) {\n  cursor: pointer;\n  ion-item.item {\n    &:hover {\n      ion-label {\n        h3 {\n          color: var(--cms-link-text-color);\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
