import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Filters } from 'src/app/models/filters.model';
import { InitConfig } from 'src/app/models/initConfig.model';
import { Link } from 'src/app/models/link.model';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { CheckComponentsService } from 'src/app/services/check-components.service';
import { LinksService } from 'src/app/services/links.service';

@Component({
  selector: 'links',
  templateUrl: 'links.html',
  styleUrls: ['links.scss']
})
export class LinksComponent {
  public linkInitialConfig: InitConfig;
  public links: Array<Link> = [];
  public isEmpty: boolean = false;
  public showHeaders: boolean;
  public showViewMoreBtn: boolean = false;

  constructor(
    public router: Router,
    private linkProvider: LinksService,
    private checkComponent: CheckComponentsService,
    private analyticsService: AnalyticsService
  ) {
    this.linkInitialConfig = this.linkProvider.initialConfigLink;
    this.showHeaders = this.linkInitialConfig.view.showHeader;
    this.getComponentLinks();
  }

  private getComponentLinks() {
    const filters: Filters = {
      searchKey: '',
      start: 0,
      orderBy: this.linkInitialConfig.view.orderBy,
      orderDir: this.linkInitialConfig.view.orderDir,
      length: this.linkInitialConfig.view.maxNumItems
    };

    this.linkProvider.getLinks(this.linkInitialConfig.id, filters).subscribe({
      next: (response: InitConfig) => {
        this.links = this.checkComponent.setItemsInComponent(response.items.itemList, response.items.itemsCount);
        this.showViewMoreBtn = response.items.itemsCount > response.items.itemsFiltered;
        this.linkInitialConfig.items = response.items;

        this.isEmpty = this.checkComponent.checkEmptyComponent(this.links);
      },
      error: (error) => {
        console.error('Error getting links', error);
      }
    });
  }

  public isButtonView() {
    return this.linkInitialConfig.view.mode === 'button';
  }

  public isListView() {
    return this.linkInitialConfig.view.mode === 'item-list';
  }

  public isGridCols3() {
    return this.linkInitialConfig.view.mode === 'grid-cols-3';
  }

  public isGridCols2() {
    return this.linkInitialConfig.view.mode === 'grid-cols-2';
  }

  public goToFullLinks() {
    this.router.navigate([`links/${this.linkInitialConfig.id}/list`]);
  }

  public openLink(link: Link) {
    const handlerLinkType = this.linkProvider.linkType[link.targetType];
    this.registerEventInAnalytics(link);
    handlerLinkType(link);
  }

  registerEventInAnalytics(link: Link) {
    const paramsToAnalytics = { actions: 1 };
    const name = link.textToDisplay;
    const id = parseInt(link.id);
    this.analyticsService.registerEvent(paramsToAnalytics, name, id);
  }
}
