// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-item.item {
  --border-width: 1px;
  --border-color: var(--ion-color-light);
  --border-radius: 5px;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/login-pages/login-rescue-password/login-rescue-password.scss"],"names":[],"mappings":"AAIQ;EACE,mBAAA;EACA,sCAAA;EACA,oBAAA;EACA,kBAAA;AAHV","sourcesContent":[":host{\n    ion-item{\n \n          \n        &.item{\n          --border-width: 1px;\n          --border-color: var(--ion-color-light);\n          --border-radius: 5px;\n          border-radius: 5px;\n        }\n  \n      }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
