import { Component, Input } from '@angular/core';

@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  @Input()
  public avatarUrl: string;

  @Input()
  public name: string;

  @Input()
  public surname: string;

  @Input()
  public groupType: string;
}
