import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
// Rxjs
import { pluck, tap } from 'rxjs/operators';

import { EMPTY } from 'rxjs';
import { Filters } from 'src/app/models/filters.model';
import { SearcherService } from 'src/app/services/searcher.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { PodcastService } from 'src/app/services/podcast.service';
import { IonInfiniteScroll } from '@ionic/angular';
import { BlogService } from 'src/app/services/blog.service';
import { InitConfig } from 'src/app/models/initConfig.model';

@Component({
  selector: 'item-searcher',
  templateUrl: 'item-searcher.html',
  styleUrls: ['item-searcher.scss']
})
export class ItemSearcherComponent implements OnInit, OnDestroy {
  event;

  @Input() title: string;
  @Input() itemType: string;
  @Input() viewMode: string;
  @Input() filters: Filters;
  @Input() idPage: number;
  @Input() trackByItem: any;


  @Output() eventDetail: EventEmitter<any> = new EventEmitter<any>();
  @Output() getAllItems: EventEmitter<any> = new EventEmitter<any>();
  @Output() reloadEmitter: EventEmitter<any> = new EventEmitter<string>();

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  public isLoaded: boolean = false;
  public itemList$;
  public loading$;
  public eventsList: any = [];
  public updating: boolean = false;
  public lastCallTime: number = 0;

  public episodes: Array<any> = [];
  public currentIndex: number = 0;
  public isPlaying: boolean = false;

  public initialConfigBlog: InitConfig;
  constructor(
    private searcherProvider: SearcherService, 
    private calendarProvider: CalendarService, 
    private blogProvider: BlogService,
    private podcastService: PodcastService) {
      this.initialConfigBlog = this.blogProvider.blogConfig;
    }

  ngOnInit() {
    this.itemList$ = this.searcherProvider[this.itemType + 'Subject$'].pipe(pluck('items')).pipe(
      tap((response: any) => {
        this.isLoaded = true;
        if (this.isCalendar()) {
          this.prepareCalendarView(response);
        }
        if (this.isPodcast()) {
          this.createPlayList(response);
        }
        this.completeEvent();
      })
    );
    this.loading$ = this.searcherProvider[this.itemType + 'Subject$'].pipe(pluck('loading'));

    
    
  }

  ngOnDestroy() {
    this.itemList$ = EMPTY;
    this.loading$ = EMPTY;
    this.filters.start = 0;
  }

  completeEvent() {
    if (!this.event || !this.event.complete) return;
    this.event.complete();
    this.event = null;
  }

  ionViewWillLeave() {
    this.itemList$ = EMPTY;
    this.isLoaded = false;
    this.filters.start = 0;
  }

  public getItems($event = null, more = false) {
    console.log('getItems: ', $event);

    const now = Date.now();

    if (now - this.lastCallTime < 1000) { // Por ejemplo, esperar al menos 1 segundo entre llamadas
      return; // Salir si no ha pasado suficiente tiempo
    }

    this.lastCallTime = now;

    this.updating = true;

    this.getAllItems.emit(
      { filters: more 
        ? { ...this.filters, start: this.filters.start + this.filters.length } 
        : this.filters, event: $event 
      });

     setTimeout(() => {
       $event.target.complete();
       this.updating = false;
     }, 2000);
  
  }

  prepareCalendarView(response: any) {
    this.eventsList = this.calendarProvider.orderEventsByMonth(response);
  }

  public search($event) {
    this.filters.start = 0;
    this.filters.searchKey = $event.target.value;
    this.event = $event;
    this.getItems($event);
  }

  public getImageClass(image) {
    image.class = 'thumbnail-image';

    if (image.type === 'video' || image.sourceType !== 'internal') {
      image.class += ' is-video';
    }

    return image.class;
  }

  public callEvent(item, items?: Array<any>, position?: number) {
    this.eventDetail.emit({ item: item, items: items, position: position });
  }

  public trackItem(item) {
    return item.id;
  }
  public isDocument() {
    return this.itemType === 'document';
  }

  public isPersonalDocument() {
    return this.itemType === 'personalDocument';
  }

  public isLink() {
    return this.itemType === 'link';
  }

  public isEmbedded() {
    return this.itemType === 'embedded';
  }

  public isImage() {
    return this.itemType === 'image';
  }

  public isContact() {
    return this.itemType === 'contacts';
  }

  public isLikes() {
    return this.itemType === 'likes';
  }

  public isBlog() {
    return this.itemType === 'blog';
  }

  public isWall() {
    return this.itemType === 'wall';
  }

  public isCalendar() {
    return this.itemType === 'calendar';
  }

  public updateLikedItem(liked, itemList, i) {
    itemList[i] = liked.newItem;
  }

  public updateCommentedItem(count, itemList, i) {
    itemList[i].social.comments.count = count.count;
  }

  public isPodcast() {
    return this.itemType === 'podcast';
  }

  private createPlayList(podcastListEpisodes) {
    this.episodes = podcastListEpisodes.map((episode) => ({
      ...episode,
      mediaObject: null
    }));
  }

  play($event) {
    this.currentIndex = this.episodes.findIndex((x) => x.id === $event.id);
    const episode = this.episodes.find((x) => x.id === $event.id);
    this.podcastService.play(episode);
  }

  pause($event) {
    this.currentIndex = this.episodes.findIndex((x) => x.id === $event.id);
    const episode = this.episodes.find((x) => x.id === $event.id);
    this.podcastService.pause(episode);
  }

  stop($event) {
    this.podcastService.destroyMusicControls();
  }

  rewind($event) {
    const episode = this.episodes.find((x) => x.id === $event.id);
    this.podcastService.rewind(episode, 10);
  }

  seekTo($event) {
    const episode = this.episodes.find((x) => x.id === $event.id);
    const duration = episode.mediaObject.getDuration();
    this.podcastService.seekTo(episode, (duration * $event.percentage) / 100);
  }

  refresh() {
    this.reloadEmitter.emit('reload');
  }
}
