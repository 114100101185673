import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Capacitor, Plugins } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';
import { concatMap, finalize } from 'rxjs/operators';
import { EventsService } from 'src/app/services/events.service';
import { GalleryService } from 'src/app/services/gallery.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PlatformService } from 'src/app/services/platform.service';
import { ToastService } from 'src/app/services/toast.service';
import { FileService } from '../../../services/file.service';
import { PromptCameraService } from '../../../services/prompt-camera.service';
import { Photo } from '@capacitor/camera';

const { Device } = Plugins;
@Component({
  selector: 'gallery-add-item',
  templateUrl: 'gallery-add-item.html',
  styleUrls: ['gallery-add-item.scss']
})
export class GalleryAddItemPage implements OnInit {
  @ViewChild('fileImage') fileImage: ElementRef;
  public componentId;

  public galleryItemForm = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
    likesAllowed: new UntypedFormControl(false),
    commentsAllowed: new UntypedFormControl(false)
  });
  public image$: BehaviorSubject<any> = new BehaviorSubject({});
  public webImage$;

  public image: Photo = null;
  public base64Image: any = null;

  constructor(
    private loading: LoadingService,
    private toast: ToastService,
    private galleryProvider: GalleryService,
    private sanitize: DomSanitizer,
    private route: ActivatedRoute,
    private events: EventsService,
    private platformService: PlatformService,
    private promptCameraService: PromptCameraService,
    private fileService: FileService
  ) {}

  ngOnInit() {
    this.componentId = +this.route.snapshot.paramMap.get('idPage');

    // this.webImage$ = this.image$.asObservable().pipe(
    //   concatMap(async (image: any) => {
    //     if (Object.keys(image).length === 0) {
    //       return;
    //     }
    //     if (this.platformService.isIos()) {
    //       return this.sanitize.bypassSecurityTrustResourceUrl(Capacitor.convertFileSrc(image.image));
    //     }

    //     return Capacitor.convertFileSrc(image.image);
    //   })
    // );
  }

  public async submit() {
    let imageBlob;

    if (!this.galleryItemForm.valid || !this.image) {
      this.toast.info('YOU-MUST-CHOOSE-AN-IMAGE-AND-ASSIGN-A-TITLE-TO-IT');
      return;
    }

    if (this.image !== null) {
      const base64 = this.base64Image;
      imageBlob = this.fileService.base64ToBlob(base64, `image/${this.image.format}`);
    }


    this.addMedia(imageBlob);
  }

  private async  addMedia(imageBlob){
    await this.loading.show();
    this.galleryProvider
      .newMedia({
        componentId: this.componentId,
        name: this.galleryItemForm.value.name,
        likesAllowed: this.galleryItemForm.value.likesAllowed,
        commentsAllowed: this.galleryItemForm.value.commentsAllowed,
        blob: imageBlob
      })
      .pipe(finalize(async () => await this.loading.hide()))
      .subscribe({
        next: () => {
          this.toast.info('ITEM-HAS-BEEN-CREATED-SUCCESSFULLY');
          this.events.nav.next({ route: `/gallery/${this.componentId}/list`, refreshHome: true });
        },
        error: (err) => {
          console.log(err);
          this.toast.info('ERROR-UPLOADING-THE-FILE');
          this.events.nav.next({ route: `/gallery/${this.componentId}/list`, refreshHome: true });
        }
      });
  }

  public async uploadPicture(image) {
    this.image$.next({ ...this.image$.value, ...image });
  }

  public async uploadCameraPictureWeb(event) {
    if (event.target.files && event.target.files.length) {
      let [file] = event.target.files;

      file = await this.fileService.generateFile(file);

      this.image$.next({ ...this.image$.value, ...file });
    }
    this.fileImage.nativeElement.value = '';
  }

  // public async selectPicture() {
  //   const result = await this.promptCameraService.show();

  //   console.log(result);

  //   switch (result.action) {
  //     case this.promptCameraService.OPEN_INPUT_FILE:
  //       this.fileImage.nativeElement.click();
  //       break;
  //     case this.promptCameraService.REMOVE_IMAGE:
  //       break;
  //     default:
  //       if (result.image && result.image.blob) {
  //         this.uploadPicture(result.image);
  //       }
  //       break;
  //   }
  // }

  public async selectPicture() {
    const result = await this.promptCameraService.ask();
    switch (result.action) {
      
      
      case this.promptCameraService.OPEN_INPUT_FILE:
        this.fileImage.nativeElement.click();
        break;
      case this.promptCameraService.REMOVE_IMAGE:
        break;
      default:
        if (result.image) {
          this.image = result.image;

          // if (this.platformService.isWeb()) {
          //   const base64String = await this.fileService.getImageBase64(result.image.webPath);
          //   let formattedBase64 = base64String.split(',')[1]
          //   this.base64Image = `data:image/${result.image.format};base64,${formattedBase64}`;
          //   return
          // }

          // const base64String = await this.fileService.convertFilePathToBase64(result.image.path || result.image.webPath);
          // this.base64Image = `data:image/${result.image.format};base64,${base64String}`;
          
          let base64String;

          if (this.platformService.isWeb()) {
            let base64Image = await this.fileService.getImageBase64(result.image.webPath);
            base64String = base64Image.split(',')[1];
          } else {
            base64String = await this.fileService.convertFilePathToBase64(result.image.path || result.image.webPath);
          }
          this.base64Image = `data:image/${result.image.format};base64,${base64String}`;
        
        }
    }
  }
}
