import { Injectable, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'lastMessagesFirst',
  pure: false
})
@Injectable({ providedIn: 'root' })
export class LastMessagesFirstPipe implements PipeTransform {
  transform(chats: any[]): any {
    return chats.sort((a, b) => {
      if (a.lastMessageDate && b.lastMessageDate) {
        const momentALastMessageDate = moment(a.lastMessageDate);
        const momentBLastMessageDate = moment(b.lastMessageDate);

        if (momentALastMessageDate.unix() > momentBLastMessageDate.unix()) {
          return -1;
        }

        if (momentALastMessageDate.unix() < momentBLastMessageDate.unix()) {
          return 1;
        }

        if (momentALastMessageDate.unix() === momentBLastMessageDate.unix()) {
          return 0;
        }
      }

      if (a.lastMessageDate && !b.lastMessageDate) {
        return -1;
      }

      if (!a.lastMessageDate && b.lastMessageDate) {
        return 1;
      }

      return 0;
    });
  }
}
