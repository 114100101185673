export abstract class Entity {
  uid: string;
  items: any[];

  static findById(entities, uid) {
    return entities.find(entity => entity.uid === uid);
  }

  constructor(data) {
    Object.assign(this, data);
  }
}
