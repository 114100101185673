import { Component, OnInit } from '@angular/core';

import { GlobalConstService } from 'src/app/services/global-const.service';
import { Filters } from 'src/app/models/filters.model';
import { InitConfig } from 'src/app/models/initConfig.model';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SearcherService } from 'src/app/services/searcher.service';
import { concatMap, tap, shareReplay, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { EventsService } from 'src/app/services/events.service';
import { Environment } from 'src/environments/environment';

@Component({
  selector: 'page-full-screen-blog',
  templateUrl: 'full-screen-blog.html',
  styleUrls: ['full-screen-blog.scss']
})
export class FullScreenBlogPage {
  public itemType: string = 'blog';
  public filters$: BehaviorSubject<Filters> = new BehaviorSubject({
    searchKey: '',
    start: 0,
    length: 20,
    orderBy: 'publishDate',
    orderDir: 'desc'
  });

  private pageId: number;
  public page$: Observable<any>;

  private $event;
  public forbidden: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private searcherService: SearcherService,
    private events: EventsService
  ) {}



  ionViewWillEnter() {
    console.log('ionViewWillEnter');

    this.pageId = +this.route.snapshot.paramMap.get('id');
    this.page$ = this.filters$.pipe(
      concatMap((filters) =>
        this.searcherService.allItems(
          `${Environment.API_BASE_URL_V2}/api/empatica/v1/components/blog/${this.pageId}/elements`,
          filters,
          this.itemType
        )
      ),
      tap(() => this.$event && this.$event.target.complete && this.$event.target.complete()),
      tap((response) => this.events.pages$$.next(response.title)),
      tap(() => (this.forbidden = false)),
      catchError((err: any) => {
        if (err.status === 403) {
          this.forbidden = true;
        }

        throw err;
      }),
      shareReplay()
    );

    this.registerAnalyticsInFirebase();
    
    this.getAllItems({ filters: { ...this.filters$.value, start: 0 } });
  }

  public goToPostDetail($event) {
    this.router.navigate([`/blog/${this.pageId}/detail/${$event.item.id}`]);
  }

  private registerAnalyticsInFirebase() {
    const paramsToAnalytics = { navigation: 2 };
    this.analyticsService.registerEvent(paramsToAnalytics, 'Detalle blog', this.pageId);
  }

  public goToAddItem(id) {
    this.router.navigate([`blog/${id}/add`]);
  }

  public getAllItems({ filters, event = null }) {
    if (event) this.$event = event;
    this.filters$.next(filters);
  }
}
