// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  background-color: var(--cms-intro-bg-color);
  background-image: var(--cms-intro-bg-image);
  background-size: cover;
  background-position: center;
  display: block;
}
:host ion-content {
  --background: var(--cms-intro-bg-color);
}
:host ion-content .centered-card-container {
  height: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
:host ion-content .card.login-card .logo {
  height: 9vh;
  max-width: 220px;
}
:host ion-content .card.login-card ion-item ion-button {
  font-size: 1rem;
  --color: var(--ion-color-primary) !important;
  --background: white!important;
}
:host ion-content .card.login-card ion-item.item {
  --border-width: 1px;
  --border-color: var(--ion-color-light);
  --border-radius: 5px;
  border-radius: 5px;
}
:host ion-content .card.login-card ion-button {
  --border-radius: 4px;
  height: 2.4rem;
  font-size: 1rem;
  margin: 0.4rem 0.2rem;
}
:host ion-content .card.login-card small {
  color: var(--ion-color-danger);
}
:host ion-content .card.login-card .terms-container {
  margin-top: 2rem;
}
:host ion-content .card.login-card .terms-container p {
  color: var(--ion-color-medium);
  font-size: 0.8rem !important;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/login-pages/login-step-one/login-step-one.scss"],"names":[],"mappings":"AAAA;EACE,2CAAA;EACE,2CAAA;EACA,sBAAA;EACA,2BAAA;EACA,cAAA;AACJ;AACE;EAEA,uCAAA;AAAF;AAEI;EACE,YAAA;EACA,mBAAA;EACA,aAAA;EACA,eAAA;AAAN;AAKQ;EACE,WAAA;EACA,gBAAA;AAHV;AAQU;EACE,eAAA;EACA,4CAAA;EACA,6BAAA;AANZ;AAUU;EACE,mBAAA;EACA,sCAAA;EACA,oBAAA;EACA,kBAAA;AARZ;AAaQ;EACE,oBAAA;EACA,cAAA;EACA,eAAA;EACA,qBAAA;AAXV;AAcQ;EACE,8BAAA;AAZV;AAeQ;EACE,gBAAA;AAbV;AAeU;EACE,8BAAA;EACA,4BAAA;AAbZ","sourcesContent":[":host {\n  background-color: var(--cms-intro-bg-color);\n    background-image: var(--cms-intro-bg-image);\n    background-size: cover;\n    background-position: center;\n    display: block;\n\n  ion-content {\n\n  --background: var(--cms-intro-bg-color);\n\n    .centered-card-container {\n      height: 100%;\n      align-items: center;\n      display: flex;\n      flex-wrap: wrap;\n    }\n\n    .card {\n      &.login-card {\n        .logo {\n          height: 9vh;\n          max-width: 220px;\n        }\n\n        ion-item{\n\n          ion-button {\n            font-size: 1rem;\n            --color: var(--ion-color-primary) !important;\n            --background: white!important;\n          }\n \n          \n          &.item{\n            --border-width: 1px;\n            --border-color: var(--ion-color-light);\n            --border-radius: 5px;\n            border-radius: 5px;\n          }\n    \n        }\n\n        ion-button {\n          --border-radius: 4px;\n          height: 2.4rem;\n          font-size: 1rem;\n          margin: 0.4rem 0.2rem;\n        }\n\n        small {\n          color: var(--ion-color-danger);\n        }\n\n        .terms-container{\n          margin-top: 2rem;\n\n          p{\n            color: var(--ion-color-medium);\n            font-size: 0.8rem !important;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
